import React, { useContext, useEffect, useState } from "react";
import "./TrakingDetails.scss";
import CloseIcon from "@mui/icons-material/Close";
import Delivered from "../../../../../assets/images/Delivered.png";
import RoundCircle from "../../../../../assets/images/circle-img.png";
// import itemImg from "../../../../assets/images/order-items-list.png";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import * as queries from "../../../../../operations/queries";
import * as mutations from "../../../../../operations/mutations";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { CountryContext } from "../../../MainComponent";
import { useTranslation } from "react-i18next";
import AddReviewModal from "./reviewmodal/AddReviewModal";
import Loader from "../../loader/Loader";
const TrakingDetails = () => {
  const [showAddReviewModal, setShowAddReviewModal] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const idFromUrl = Number(urlParams.get("id"));
  const [modalTitle, setModalTitle] = useState("");
  const { orderNumber } = useParams();
  const loggedInToken = localStorage.getItem("loggedInCust");
  const navigate = useNavigate();
  const { store } = useContext(CountryContext);
  const { t } = useTranslation();

  const { data: orderDetailsRes, loading } = useQuery(
    queries.customerQueries.GET_ORDER_DETAILS,
    {
      variables: {
        order_number: orderNumber,
        item_id: idFromUrl,
      },
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
        },
      },
    }
  );

  const productName = orderDetailsRes?.customerOrdersDetail?.[0]?.product_name;

  const urlKey = productName
    ? productName.toLowerCase().replace(/\s+/g, "-")
    : "";

  const { refetch: refetchProData } = useQuery(
    queries.detailsQueries.GET_CONFDETAILS,
    {
      variables: { url_key: urlKey },
      context: {
        headers: {
          store: store,
          Connection: "keep-alive",
        },
      },
    }
  );

  const { data: ratingsData, refetch: refetchratingsData } = useQuery(
    queries.ratingQueries.GET_RATINGS_METADATA,
    {
      variables: { url_key: urlKey && urlKey[0] },
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store,
        },
      },
    }
  );

  const [createProductReview] = useMutation(
    mutations.createProductReview.CREATE_PRODUCT_REVIEW,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store,
        },
      },
    }
  );

  useEffect(() => {
    refetchProData();
    refetchratingsData();
    //eslint-disable-next-line
  }, [orderDetailsRes]);

  const ratingMetaDataId =
    ratingsData?.productReviewRatingsMetadata?.items?.[0]?.id;

  const ratingMetadata = () => {
    if (ratingsData?.productReviewRatingsMetadata?.items?.[0]?.values) {
      return ratingsData?.productReviewRatingsMetadata?.items?.[0]?.values;
    } else {
      return [];
    }
  };

  const orderDetails = orderDetailsRes?.customerOrdersDetail?.[0] || [];
  console.log("orderDetails", orderDetails);
  // const { order_date, status, total, shipping_address[0] } = orderDetails;

  const closePage = () => {
    const currentPath = window.location.pathname;
    const basePath = currentPath.split("/tracking")[0];
    navigate(basePath);
  };

  const orderDetailsPage = () => {
    const currentPath = window.location.pathname;
    const basePath = currentPath.split("/tracking")[0];
    const orderLink = `${basePath}/order/${orderNumber}?id=${idFromUrl}`;
    navigate(orderLink);
  };

  const orderReturnPage = () => {
      const currentPath = window.location.pathname;
      const basePath = currentPath.split("/tracking")[0];
      const orderLink = `${basePath}/return-order/${orderNumber}?id=${idFromUrl}`;
      navigate(orderLink);
  }

  const handleAddReviewOpen = (title: string) => {
    setModalTitle(title);
    setShowAddReviewModal(true);
  };

  const handleAddReviewClose = () => {
    setShowAddReviewModal(false);
  };

  const handleSubmit = async (reviewData: any) => {
    // handleAddReviewClose();
    try {
      // eslint-disable-next-line
      const { data } = await createProductReview({
        variables: {
          input: {
            ...reviewData,
          },
        },
      });

      handleAddReviewClose();
    } catch (error) {
      console.error("Error creating product review:", error);
    }
  };

  return (
    <>
      <div className="tracking-details-main-box">
        <div className="tracking-heder">
          <div className="title">
            <h4>{t("buyer_dashboard.OrderTracking.title")} </h4>

            <p>{t("buyer_dashboard.OrderTracking.desc")}</p>
          </div>

          <div className="close-btn">
            <button onClick={() => closePage()}>
              <CloseIcon />
            </button>
          </div>
        </div>

        {loading ? (
          <Loader />
        ) : (
          <>
            <div className="tracking-body">
              <div className="tracking-details">
                <div className="delivered-box">
                  <img src={Delivered} alt="Icon" />
                  <h5>
                    <span>{orderDetails?.status}</span> on{" "}
                    {new Date(orderDetails?.date)?.toLocaleDateString()} at{" "}
                    {new Date(orderDetails?.date)?.toLocaleTimeString()}
                  </h5>
                </div>
                <div className="tracking-shopping-experience">
                  <div className="title">
                    <h6>{t("buyer_dashboard.OrderTracking.experience")}</h6>

                    <p>{orderDetails?.status}</p>
                  </div>

                  <div className="feedbacks-box-grp">
                    <div className="feedback-grp">
                      <img src={RoundCircle} alt="Icon" />
                      <p>
                        {t("buyer_dashboard.OrderTracking.seller_feedback")}
                      </p>
                      <button
                        onClick={() => handleAddReviewOpen("REVIEW SELLER")}
                      >
                        {t("buyer_dashboard.OrderTracking.seller_review")}
                      </button>
                    </div>

                    <div className="feedback-grp">
                      <img src={RoundCircle} alt="Icon" />
                      <p>
                        {t("buyer_dashboard.OrderTracking.product_feedback")}
                      </p>
                      <button
                        onClick={() => handleAddReviewOpen("REVIEW PRODUCT")}
                      >
                        {t("buyer_dashboard.OrderTracking.product_review")}
                      </button>
                    </div>

                    <div className="feedback-grp">
                      <img src={RoundCircle} alt="Icon" />
                      <p>
                        {t("buyer_dashboard.OrderTracking.delivery_feedback")}
                      </p>
                      <button
                        onClick={() => handleAddReviewOpen("REVIEW DELIVERY")}
                      >
                        {t("buyer_dashboard.OrderTracking.delivery_review")}
                      </button>
                    </div>

                    <AddReviewModal
                      open={showAddReviewModal}
                      onClose={handleAddReviewClose}
                      onSubmit={handleSubmit}
                      detailsData={orderDetails}
                      ratingMetadata={ratingMetadata()}
                      ratingMetaDataId={ratingMetaDataId}
                      title={modalTitle}
                    />
                  </div>
                </div>
                <div className="tracking-item-summary">
                  <div className="title">
                    <h5>{t("buyer_dashboard.OrderTracking.item_summary")}</h5>
                  </div>
                  <div className="details">
                    {/* eslint-disable-next-line */}
                    <img
                      src={orderDetails?.product_image}
                      alt="Image"
                      style={{ maxWidth: "15%" }}
                    />
                    <div>
                      <p>{orderDetails.product_name}</p>
                      <h4>
                        {orderDetails.currency} {orderDetails.price}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tracking-summary-details">
                <div className="tracking-delivery-address">
                  <h5>{t("buyer_dashboard.OrderTracking.Delivery_address")}</h5>
                  <p>
                    {orderDetails?.shipping_address?.[0]?.firstname}{" "}
                    {orderDetails?.shipping_address?.[0]?.lastname}
                    <br />
                    {orderDetails?.shipping_address?.[0]?.street},{" "}
                    {orderDetails?.shipping_address?.[0]?.city},{" "}
                    {orderDetails?.shipping_address?.[0]?.region}
                    <br />
                  </p>
                  <h6>
                    {" "}
                    {orderDetails?.shipping_address?.[0]?.telephone}{" "}
                    <span className="verified" style={{ color: "green" }}>
                      {t("buyer_dashboard.OrderTracking.Verified")}
                    </span>
                  </h6>
                </div>
                <div className="tracking-invoice-summary">
                  <div>
                    <h5>
                      {t("buyer_dashboard.OrderTracking.download_invoice")}
                    </h5>
                    <p>
                      {t("buyer_dashboard.OrderTracking.download_invoice_desc")}
                    </p>
                  </div>

                  <button onClick={() => orderDetailsPage()}>
                    <ArrowForwardIosIcon />
                  </button>
                </div>

                {orderDetails.is_returnable === true && (
                  <div className="tracking-invoice-summary">
                    <div>
                      <h5>
                        {" "}
                        {t("buyer_dashboard.OrderTracking.return_order")}
                      </h5>
                      <p>
                        {" "}
                        {t("buyer_dashboard.OrderTracking.return_order_desc")}
                      </p>
                    </div>

                    <button onClick={() => orderReturnPage()}>
                      <ArrowForwardIosIcon />
                    </button>
                  </div>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default TrakingDetails;
