import { gql } from "@apollo/client";

export const ADD_PRODUCTS_TO_WISHLIST = gql`
  mutation AddProductsToWishlist($wishlistId: ID!, $wishlistItems: [WishlistItemInput!]!) {
    addProductsToWishlist(wishlistId: $wishlistId, wishlistItems: $wishlistItems) {
      wishlist {
        id
        items_count
        items_v2(currentPage: 1, pageSize: 8) {
          items {
            id
            quantity
            ... on BundleWishlistItem {
              bundle_options {
                values {
                  id
                  label
                  quantity
                }
              }
            }
            product {
              uid
              name
              sku
              price_range {
                minimum_price {
                  regular_price {
                    currency
                    value
                  }
                }
                maximum_price {
                  regular_price {
                    currency
                    value
                  }
                }
              }
            }
          }
        }
      }
      user_errors {
        code
        message
      }
    }
  }
`;

export const ADD_CONFIGURABLE_PRODUCTS_TO_WISHLIST = gql`
  mutation AddProductsToWishlist($wishlistId: ID!, $wishlistItems: [WishlistItemInput!]!) {
    addProductsToWishlist(wishlistId: $wishlistId, wishlistItems: $wishlistItems) {
      wishlist {
        id
        items_count
        items_v2(currentPage: 1, pageSize: 8) {
          items {
            id
            quantity
            ... on BundleWishlistItem {
              bundle_options {
                values {
                  id
                  label
                  quantity
                }
              }
            }
            product {
              uid
              name
              sku
              price_range {
                minimum_price {
                  regular_price {
                    currency
                    value
                  }
                }
                maximum_price {
                  regular_price {
                    currency
                    value
                  }
                }
              }
            }
          }
        }
      }
      user_errors {
        code
        message
      }
    }
  }
`;

export const REMOVE_PRODUCTS_FROM_WISHLIST = gql`
  mutation RemoveProductsFromWishlist($wishlistId: ID!, $wishlistItemsIds: [ID!]!) {
    removeProductsFromWishlist(wishlistId: $wishlistId, wishlistItemsIds: $wishlistItemsIds) {
      wishlist {
        id
        items_count
        items_v2(currentPage: 1, pageSize: 8) {
          items {
            id
            quantity
            ... on BundleWishlistItem {
              bundle_options {
                values {
                  id
                  label
                  quantity
                }
              }
            }
            product {
              uid
              name
              sku
              price_range {
                minimum_price {
                  regular_price {
                    currency
                    value
                  }
                }
                maximum_price {
                  regular_price {
                    currency
                    value
                  }
                }
              }
            }
          }
        }
      }
      user_errors {
        code
        message
      }
    }
  }
`;

export const ADD_TO_CART_MUTATION = gql`
  mutation AddWishlistItemsToCart($wishlistId: ID!, $wishlistItemIds: [ID!]!) {
    addWishlistItemsToCart(wishlistId: $wishlistId, wishlistItemIds: $wishlistItemIds) {
      wishlist {
        id
        items_count
        items_v2(currentPage: 1, pageSize: 8) {
          items {
            quantity
            product {
              uid
              name
              sku
              price_range {
                minimum_price {
                  regular_price {
                    currency
                    value
                  }
                }
              }
            }
          }
        }
      }
      add_wishlist_items_to_cart_user_errors {
        code
        message
      }
    }
  }
`;
