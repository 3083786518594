import {
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Chip,
  Typography,
} from "@mui/material";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
import { CountryContext } from "../../container/buyer/MainComponent";
import cartImage from "../../assets/images/cart_img.png";
import { useTranslation } from "react-i18next";

interface ProductCardProps {
  productdata: any;
  setModalUrlKey: (urlKey: string) => void;
  setCartModalOpen: (open: boolean) => void;
  setProductDataIs : any;
}

const ProductCarouselCard: React.FC<ProductCardProps> = ({
  productdata,
  setModalUrlKey,
  setCartModalOpen,
  setProductDataIs
}) => {
  const navigate = useNavigate();
  const { store } = useContext(CountryContext);
  const { t } = useTranslation();

  return (
    <>
      <Card
        className="slider-product-card"
        sx={{
          maxWidth: "100%",
          boxShadow: 0,
          border: "1px solid #e1e1e1",
          margin: {
            xs: "0px 5px",
            md: "0px 6px",
            lg: "0px 8px",
            xl: "0px 10px",
          },
          position: "relative",
        }}
        onClick={() => navigate(`/${store}/${productdata?.url_key}.html`,
          {
            state: { productData: productdata }, 
          }
        )}
      >
        {productdata?.top_badge_left && (
          <div
            className="product-offer-tag"
            style={{
              position: "absolute",
              top: "0px",
              left: "0px",
              zIndex: 10,
            }}
          >
            {/* <img
              src={productdata?.top_badge_left || " "}
              alt={productdata?.top_badge_left_title || "Badge"}
              style={{
                width: "120px",
                height: "auto",
                marginTop: "-32px",
              }}
              loading="lazy"
            /> */}
          </div>
        )}

        <div key={productdata?.sku}>
          <div className="slider-img-box">
            <CardMedia
              component="img"
              alt={productdata?.name}
              height="100%"
              sx={{ m: "auto", width: "auto" }}
              image={
                productdata?.image?.small_url
                  ? productdata?.image?.small_url
                  : ""
              }
              // loading="lazy"
            />
          </div>
          <CardContent sx={{ textAlign: "start" }}>
            <Typography
              gutterBottom
              variant="h6"
              component="div"
              color="text.dark"
              className="top-sell-title"
            >
              {productdata?.name}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "1px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: {
                    lg: "7px",
                    md: "5px",
                    xs: "7px",
                    sm: "7px",
                  },
                  flexWrap: "wrap",
                }}
              >
                <Box>
                  <Typography
                    gutterBottom
                    variant="h5"
                    color="text.dark"
                    className="top-sell-amount"
                  >
                    {
                      productdata?.price_range?.minimum_price?.final_price
                        ?.currency
                    }
                    &nbsp;
                    {
                      productdata?.price_range?.minimum_price?.final_price
                        ?.value
                    }
                  </Typography>
                </Box>
                <Box>
                  {productdata?.price_range?.minimum_price?.discount
                    ?.amount_off > 0 && (
                    <Typography
                      gutterBottom
                      variant="h6"
                      color="text.secondary"
                      component="del"
                      className="top-actual_amount"
                    >
                      {
                        productdata?.price_range?.minimum_price?.regular_price
                          ?.currency
                      }{" "}
                      {
                        productdata?.price_range?.minimum_price?.regular_price
                          ?.value
                      }
                    </Typography>
                  )}
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "7px",
                  flexWrap: "wrap",
                  // mb:"5px"
                }}
              >
                {productdata?.finalAverageRating >= 0 && (
                  <>
                    <Box
                      sx={{ display: "flex", gap: "5px", alignItems: "center" }}
                    >
                      <Chip
                        label={
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              fontSize: "12px",
                            }}
                          >
                            {productdata?.finalAverageRating}
                            <StarOutlinedIcon
                              className="starIcon"
                              style={{
                                marginLeft: "4px",
                                width: "15px",
                              }}
                            />
                          </div>
                        }
                        color="success"
                        size="small"
                        sx={{
                          backgroundColor: "#1FAF38",
                        }}
                      />
                      <span
                        className="reviwe-text"
                        style={{
                          color: "#000000",
                          fontSize: "14px",
                          fontWeight: "300",
                          fontFamily: "poppins",
                        }}
                      >
                        {productdata?.review_count} {t("main.review")}
                      </span>
                    </Box>
                  </>
                )}
              </Box>
            </Box>
          </CardContent>
        </div>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            p: "10px",
            alignItems: "center",
            marginTop: "auto",
          }}
        >
          <Box>
            {productdata?.configurable_options && (
              <div className="color-switch-list">
                {productdata?.configurable_options[0]?.values?.map(
                  (item: any, index: any) => {
                    return (
                      <Button size="small" key={index}>
                        <div
                          className="color-switch"
                          style={{
                            backgroundColor: item?.swatch_data?.value,
                            border: "1px solid #e1e1e1",
                          }}
                        ></div>
                      </Button>
                    );
                  }
                )}
              </div>
            )}
          </Box>
          <Box>
            <img
              src={cartImage}
              alt="cart"
              style={{
                border: "1px solid #EFEDED",
                borderRadius: "4px",
                padding: "5px",
                backgroundColor: "white",
                height: "37px",
                cursor: "pointer",
                boxShadow: "0 4px 6px #EFEDED",
              }}
              onClick={(e) => {
                e.stopPropagation();
                setCartModalOpen(true);
                setModalUrlKey(productdata?.url_key);
                setProductDataIs(productdata)
              }}
              loading="lazy"
            />
          </Box>
        </Box>
      </Card>
    </>
  );
};

export default React.memo(ProductCarouselCard);
