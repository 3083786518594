import React, { useState } from "react";
import {
  Box,
  Typography,
  Checkbox,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Button,
  FormHelperText,
  CircularProgress,
} from "@mui/material";
import { useForm, Controller } from "react-hook-form"; 
import "./ReturnOrder.scss";
import { useNavigate, useParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { useMutation, useQuery } from "@apollo/client";
import * as queries from "../../../../../operations/queries";
import * as mutations from "../../../../../operations/mutations";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

interface FormData {
  reason: string;
  detailedReason: string;
  isProductOpened: boolean;
}

const ReturnOrderPage: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { orderNumber } = useParams();
  const loggedInToken = localStorage.getItem("loggedInCust");
  const {t} = useTranslation()
  const urlParams = new URLSearchParams(window.location.search);
  const idFromUrl = urlParams.get("id");

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      reason: "",
      detailedReason: "",
      isProductOpened: false,
    },
  });

  const { data: orderDetailsRes} = useQuery(
    queries.customerQueries.GET_ORDER_DETAILS,
    {
      variables: {
        order_number: orderNumber,
        item_id: idFromUrl,
      },
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
        },
      },
    }
  );

  const orderDetails = orderDetailsRes?.customerOrdersDetail?.[0] || [];

  const [returnRequest] = useMutation(mutations.orderMutation.RETURN_REQUEST, {
    context: {
      headers: {
        Authorization: loggedInToken
          ? `Bearer ${JSON.parse(loggedInToken)}`
          : "",
        Connection: "keep-alive",
      },
    },
  });

  const onSubmit = async (data: FormData) => {
    setLoading(true);
    try {
      // eslint-disable-next-line
      const response = await returnRequest({
        variables: {
          type: "add",
          order_number: orderNumber,
          item_id: idFromUrl,
          refund_type: data.reason, 
          refund_reason: data.detailedReason,
          is_product_opened: data.isProductOpened ? "1" : "0", 
        },
      });
     toast.success("request submitted successfully.")
    } catch (err) {
      console.error("Error:", err);
    }finally {
      setLoading(false); 
      reset()
    }
  };

  const closePage = () => {
    const currentPath = window.location.pathname;
    const basePath = currentPath.split("/return-order")[0];
    navigate(basePath);
  };

  return (
    <Box className="return-order-page">
      <Box className="return-order-header">
        <Box className="header">
          <Typography variant="h5" className="page-title">
            {t("buyer_dashboard.returnOrder.title")}
          </Typography>
          <Typography variant="body2" className="page-desc">
          {t("buyer_dashboard.returnOrder.desc")}
          </Typography>
        </Box>

        <Box className="close-btn">
          <button onClick={() => closePage()}>
            <CloseIcon />
          </button>
        </Box>
      </Box>

      {/* Product Details */}
      <Box
        className="return-content"
        component="form"
        onSubmit={handleSubmit(onSubmit)} 
      >
        <Box className="item-card">
          <Box className="item-layout">
            <Box className="item-info">
              <Checkbox />
              <img
                src={orderDetails?.product_image}
                alt="Product"
                className="item-image"
              />
              <Box className="item-details">
                <Typography variant="h6">
                  {orderDetails?.product_name}
                </Typography>
                <Typography className="body2">
                  <span>{t("buyer_dashboard.returnOrder.qty")}:</span>
                  {orderDetails?.qty}
                </Typography>
                <Typography className="body2">
                  <span>{t("buyer_dashboard.returnOrder.price")}:</span> {orderDetails?.currency}{" "}
                  {orderDetails?.price}
                </Typography>
              </Box>
            </Box>

            <Box className="return-reason">
              <Typography className="days-left">
                {" "}
                <span>7 {t("buyer_dashboard.returnOrder.days")}</span> {t("buyer_dashboard.returnOrder.left_days")}
              </Typography>
              <Typography className="reason-label">
              {t("buyer_dashboard.returnOrder.reason_lable")} *
              </Typography>

              {/* Reason Dropdown */}
              <FormControl fullWidth error={Boolean(errors.reason)}>
                <Controller
                  name="reason"
                  control={control}
                  rules={{
                    required: t("buyer_dashboard.returnOrder.reason_err"),
                  }}
                  render={({ field }) => (
                    <Select
                      {...field}
                      displayEmpty
                      className="reason-dropdown"
                      sx={{ fontSize: "13px" }}
                    >
                      <MenuItem value="" disabled sx={{ fontSize: "13px" }}>
                      {t("buyer_dashboard.returnOrder.reason_menu1")}
                      </MenuItem>
                      <MenuItem value="Too small" sx={{ fontSize: "13px" }}>
                      {t("buyer_dashboard.returnOrder.reason_menu2")}
                      </MenuItem>
                      <MenuItem value="Damaged" sx={{ fontSize: "13px" }}>
                      {t("buyer_dashboard.returnOrder.reason_menu3")}
                      </MenuItem>
                      <MenuItem value="Wrong item" sx={{ fontSize: "13px" }}>
                      {t("buyer_dashboard.returnOrder.reason_menu4")}
                      </MenuItem>
                      <MenuItem value="Other" sx={{ fontSize: "13px" }}>
                      {t("buyer_dashboard.returnOrder.reason_menu5")}
                      </MenuItem>
                    </Select>
                  )}
                />
                <FormHelperText>
                  {errors.reason ? errors.reason.message : ""}
                </FormHelperText>
              </FormControl>

              {/* Detailed Reason */}
              <Box className="detailed-reason">
                <Typography variant="body2" className="reason-label">
                {t("buyer_dashboard.returnOrder.reason_desc_lable")} *
                </Typography>
                <Controller
                  name="detailedReason"
                  control={control}
                  rules={{
                    required:t("buyer_dashboard.returnOrder.reason_desc_err"),
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder={t("buyer_dashboard.returnOrder.reason_desc_placeholder")}
                      variant="outlined"
                      multiline
                      rows={3}
                      fullWidth
                      error={Boolean(errors.detailedReason)}
                      className="reason-input"
                      helperText={errors.detailedReason?.message}
                      sx={{
                        "& .MuiInputBase-root": {
                          fontSize: "13px", 
                        },
                        "& .MuiInputBase-input": {
                          fontSize: "13px", 
                        },
                        "& .MuiInputLabel-root": {
                          fontSize: "13px",
                        },
                        "& .MuiFormHelperText-root": {
                          fontSize: "12px",
                        },
                      }}
                    />
                  )}
                />
              </Box>

              {/* Is Product Opened Checkbox */}
              <Box>
                <Controller
                  name="isProductOpened"
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      {...field}
                      checked={field.value}
                      onChange={(e) => field.onChange(e.target.checked)}
                    />
                  )}
                />
                <Typography variant="body2" component="span" className="productOpen">
                {t("buyer_dashboard.returnOrder.product_open_check")}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <hr />

        {/* Return Summary */}
        <Box className="return-summary">
          <Typography variant="h6"> {t("buyer_dashboard.returnOrder.return_summary")}</Typography>
          <Typography className="details">
            {orderDetails?.product_name}
          </Typography>
          {/* <Divider className="summary-divider" /> */}
          <Box className="summary-details">
            <Typography className="details">{t("buyer_dashboard.returnOrder.estimated_value")}:</Typography>
            <Typography className="details">
              {orderDetails?.currency} {orderDetails?.price}
            </Typography>
          </Box>

          {/* Submit Button */}
          <Button
            variant="contained"
            color="success"
            className="submit-summary-button"
             type="submit"
            disabled={loading} 
            startIcon={loading ? <CircularProgress size={20} color="inherit" /> : null} 
          >
            {loading ? t("buyer_dashboard.returnOrder.processing") : t("buyer_dashboard.returnOrder.submit")} 
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ReturnOrderPage;
