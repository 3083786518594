import React, { useContext, useState } from "react";
import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import {  Navigate } from "react-router-dom";
import { CountryContext } from "../../MainComponent";
import { useQuery } from "@apollo/client";
import * as queries from "../../../../operations/queries";
import { Grid, Typography } from "@mui/material";
import { t } from "i18next";
const storePath = window.location.pathname;


function PreHeader(props: any) {
    const [navigateTo, setNavigateTo] = useState<any>(null);
    const { store } = useContext(CountryContext);
    const customHeader = {
        store: store && store !== "" ? store : storePath?.split("/")?.[1],
        Connection: "keep-alive",
    };
    const { data: logoRes } = useQuery(queries.headerQueries.GET_LOGO, {
        context: {
            headers: customHeader,
        },
    });
    const logoData = logoRes?.storeConfig;
    const logoUrl = logoData?.secure_base_media_url + "logo/" + logoData?.header_logo_src;

    const handleLogoClick = () => {
        const path = window.location.pathname.split("/")[1]; 
        setNavigateTo(`/${path}`); 
      };

      if (navigateTo) {
        return <Navigate to={navigateTo} replace />; 
      }

    return (
        <>
            <div className="page-header">
                <div className="header-content">
                    <Box sx={{ flexGrow: 1, mb: { xs: "5px", md: "0px" } }}>
                        <AppBar position="static" sx={{paddingBottom: '0 !important'}}>
                            <Toolbar sx={{ display: { md: "flex", xs: "block" } }} style={{ minHeight: props.headerHeight }}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        marginBottom: { md: 0, xs: 2 },
                                        width: "100%",
                                        justifyContent: "center",
                                    }}
                                    className="checkout-header"
                                >
                                    <Grid container>
                                        <Grid item xs={12} sm={4} md={4}>
                                            <div className="logo-nav">
                                                <div className="logo-container">
                                                    <div
                                                        className="brand-link"
                                                        // to='/'
                                                        onClick={handleLogoClick}
                                                        style={{ height: "100%", display: "flex" }}
                                                    >
                                                        <img src={logoUrl} alt="Jebelz" className="logo" />
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={4} md={4}>
                                            <div className="checkout-title">
                                                <Typography
                                                    component="h3"
                                                    variant="h4"
                                                    sx={{ fontWeight: 700, color: "#000" }}
                                                >
                                                    {t("auth.checkout.checkout")}
                                                </Typography>
                                            </div>

                                        </Grid>
                                        <Grid item xs={12} sm={4} md={4}></Grid>
                                    </Grid>


                                </Box>
                            </Toolbar>
                        </AppBar>
                    </Box>
                </div>
            </div>
        </>
    )
}
export default PreHeader