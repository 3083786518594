import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Box,
} from '@mui/material';
import { Close } from '@mui/icons-material';

interface DeleteAccountModalProps {
  isOpen: boolean;
  handleClose: () => void;
}

const DeleteAccountModal: React.FC<DeleteAccountModalProps> = ({ isOpen, handleClose }) => {
  const [reason, setReason] = useState('');
  const [acceptedTerms, setAcceptedTerms] = useState(false);

  const handleReasonChange = (event: any) => {
    setReason(event.target.value as string);
  };

  const handleAcceptTerms = () => {
    setAcceptedTerms(!acceptedTerms);
  };

  const handleDeleteAccount = () => {
    // Add your delete account logic here
    console.log('Account deleted');
    handleClose();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="sm">
      <Box p={2}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <DialogTitle sx={{ p: 0, fontWeight: 'bold' }}>Delete Account</DialogTitle>
          <IconButton onClick={handleClose}>
            <Close />
          </IconButton>
        </Box>
        
        <DialogContent>
          <Typography  gutterBottom sx={{ fontWeight: 500, mb: 1,fontSize:'14px' }}>
            Reason for deleting account
          </Typography>
          <FormControl fullWidth>
            <Select
              value={reason}
              onChange={handleReasonChange}
              displayEmpty
              sx={{
                borderRadius: '4px',
                border: '1px solid #E7E4E4',
                p: '0px',
                '& .MuiSelect-select': {
                  fontSize: '14px', 
                },
              }}
            >
              <MenuItem value="" disabled sx={{fontWeight: 400, fontSize:'14px'}}>
                Select reason
              </MenuItem>
              <MenuItem value="I have another account" sx={{fontWeight: 400, fontSize:'14px'}}>I have another account</MenuItem>
              <MenuItem value="Privacy concerns" sx={{fontWeight: 400, fontSize:'14px'}}>Privacy concerns</MenuItem>
              <MenuItem value="Not using the service" sx={{fontWeight: 400, fontSize:'14px'}}>Not using the service</MenuItem>
              <MenuItem value="Other" sx={{fontWeight: 400, fontSize:'14px'}}>Other</MenuItem>
            </Select>
          </FormControl>

          <Box mt={2} mb={2}>
            <Typography variant="body2" sx={{ fontSize: '14px',fontWeight:'700' }} gutterBottom>
              Please read the terms and conditions carefully
            </Typography>
            <Typography variant="body2"  sx={{ fontSize: '12px',fontWeight:'400' }}>
              The terms and conditions set out herein are applicable to noon, noon Grocery, noon Food, noon Minutes, and other associated services. By checking the box "I have read the terms and conditions" and clicking the button "Delete Your Account," you hereby affirm your understanding and acceptance of the following:
            </Typography>
            <ul style={{ fontSize: '12px',fontWeight:'400' }}>
              <li>You will no longer receive any transactional or promotional communication from noon and its associated websites and mobile applications.</li>
              <li>You will not be able to register or sign in with the same email address used for the account to be deleted and you will lose access to all information related to such account, including but not limited to, orders, returns, warranty, bank transfer, account balance and noon credits.</li>
              <li>You must first cancel your noon Grocery subscriptions and auto-deliveries before deleting your account to avoid any further issues with delivery and future charges with noon Grocery.</li>
              <li>our privacy policy.</li>
              <li>For any other queries or clarifications, please reach out to customer support at noon.com.</li>
            </ul>
            <Typography variant="body2"  sx={{ fontSize: '12px',fontWeight:'500' }}>
              These changes may take 3-5 minutes to reflect on all devices.
            </Typography>
          </Box>

          <FormControlLabel
            control={
              <Checkbox
                checked={acceptedTerms}
                onChange={handleAcceptTerms}
                color="primary"
              />
            }
            label="I have read the terms and conditions"
          />
          
          <Button
            variant="contained"
            color="primary"
            onClick={handleDeleteAccount}
            fullWidth
            disabled={!acceptedTerms || !reason}
            sx={{
              mt: 2,
              backgroundColor: (!acceptedTerms || !reason) ? '#e0e0e0' : '#d32f2f',
              color: '#fff',
              '&:hover': {
                backgroundColor: (!acceptedTerms || !reason) ? '#e0e0e0' : '#c62828',
              },
              borderRadius: '4px',
            }}
          >
            Delete Your Account
          </Button>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default DeleteAccountModal;
