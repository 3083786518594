import React, { useContext, useEffect, useState } from "react";
import "./MyWishlist.scss";
import {
  Box,
  Button,
  Divider,
  Menu,
  // Modal,
  // Switch,
  // TextField,
  // Typography,
} from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import ShareIcon from "@mui/icons-material/Share";
// import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CloseIcon from "@mui/icons-material/Close";
// import StarIcon from "@mui/icons-material/Star";
// import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
// import NoteAltIcon from "@mui/icons-material/NoteAlt";
import CreateWishlistModal from "./createWishlistModal/CreateWishlistModal";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import * as queries from "../../../../operations/queries";
import * as mutations from "../../../../operations/mutations";
import { CountryContext, mainContext } from "../../MainComponent";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import Wishlist from "./emptyWishlist/Wishlist";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import Loader from "../loader/Loader";

const MyWishlist = () => {
  const { store, country } = useContext(CountryContext);
  const { setCartAdded, quest_cart_id , cartCount } = useContext(mainContext);
  const loggedInToken = localStorage.getItem("loggedInCust");
  const wishlistId = localStorage.getItem("wishlistId");
  const [fetch,refetch]=useState(false)
  const { t } = useTranslation();
  const navigate=useNavigate()

  // const style = {
  //   position: "absolute",
  //   top: "50%",
  //   left: "50%",
  //   transform: "translate(-50%, -50%)",
  //   width: 400,
  //   bgcolor: "background.paper",
  //   // border: '2px solid #000',
  //   boxShadow: 24,
  //   // p: 4,
  // };

  const { data: custDetailsDataRes } = useQuery(
    queries.customerQueries.GET_CUSTOMER_INFO,
    {
      skip:!loggedInToken,
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
        },
      },
    }
  );
  const custDetails = custDetailsDataRes?.customer;

  const {
    data: custWishlistData,
    refetch: refetchCustWishlistData,
    loading,
  } = useQuery(queries.wishlistQueries.GET_CUSTOMER_WISHLISTS, {
    context: {
      headers: {
        Authorization: loggedInToken
          ? `Bearer ${JSON.parse(loggedInToken)}`
          : "",
        Connection: "keep-alive",
        store: store,
      },
    },
  });

  const wishlistData =
    custWishlistData && custWishlistData?.customer?.wishlists[0];

  const products = wishlistData?.items_v2?.items || [];

  const updatedProductData = products?.map((product: any) => {
    const reviewsArr = product?.reviews?.items || [];

    const ratingsOutOf5 = reviewsArr.map(
      (review: any) => (review?.average_rating ?? 0) / 20
    );

    const averageRating =
      ratingsOutOf5.length > 0
        ? ratingsOutOf5.reduce((total: any, rating: any) => total + rating, 0) /
          ratingsOutOf5.length
        : 0;

    const finalAverageRating = Math.round(averageRating * 10) / 10;

    return {
      ...product,
      finalAverageRating,
    };
  });

  const [removeProductsToWishlist] = useMutation(
    mutations.addProductToWishlist.REMOVE_PRODUCTS_FROM_WISHLIST,
    {
      onCompleted: () => {
        toast.success(t("buyer_dashboard.wishlist.remove_product_toast_succ"));
        refetch(true)
      },
      onError: (err) => {
        toast.error(
          `${t("buyer_dashboard.wishlist.remove_product_toast_err")}: ${
            err.message
          }`
        );
      },
    }
  );

  const handleRemoveWishlistClick = (id: any) => {
    const parsedWishlistId = wishlistId ? parseInt(wishlistId, 10) : NaN;

    if (wishlistId) {
      removeProductsToWishlist({
        variables: {
          wishlistId: parsedWishlistId,
          wishlistItemsIds: [parseInt(id)],
        },
        context: {
          headers: {
            Authorization: loggedInToken
              ? `Bearer ${JSON.parse(loggedInToken)}`
              : "",
            Connection: "keep-alive",
            store: store,
          },
        },
      });
    } else {
      toast.error("No wishlist found. Please try again.");
    }
  };

  useEffect(() => {
    refetchCustWishlistData();
    //eslint-disable-next-line
  }, [removeProductsToWishlist,fetch]);

  const [addToCart] = useMutation(
    mutations.addProductToWishlist.ADD_TO_CART_MUTATION,
    {
      onCompleted: () => {
        refetchCartData();
        setCartAdded(true);
        toast.success(`product was added into cart.`);
      },
      onError: (err) => {
        toast.error(`product was not added into cart.`);
      },
    }
  );

  const handleAddToCart = async (id: any) => {
    try {
      const wishlistId = localStorage.getItem("wishlistId");
      const parsedWishlistId = wishlistId ? parseInt(wishlistId, 10) : NaN;

      if (isNaN(parsedWishlistId)) {
        throw new Error(t("buyer_dashboard.wishlist.add_to_cart_err"));
      }
      // eslint-disable-next-line
      const response = await addToCart({
        variables: {
          wishlistId: parsedWishlistId,
          wishlistItemIds: [id.toString()],
        },
        context: {
          headers: {
            Authorization: loggedInToken
              ? `Bearer ${JSON.parse(loggedInToken)}`
              : "",
            Connection: "keep-alive",
            store: store,
          },
        },
      });
    } catch (err) {
      console.error("Error adding to cart:", err);
    }
  };

  const [fetchCartDetails, { refetch: refetchCartData }] = useLazyQuery(
    queries.cartQueries.GET_CART_DETAILS,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          Store: store,
        },
      },
    }
  );

  useEffect(() => { 
    if (quest_cart_id && quest_cart_id !== "") {
      fetchCartDetails({
        variables: { cart_id: quest_cart_id },
      });
    } 
  }, [quest_cart_id, fetchCartDetails ,cartCount]);

  const [openModal, setOpenModal] = React.useState(false);
  //eslint-disable-next-line
  const [modalMode, setModalMode] = React.useState("create");

  // const handleOpenCreateModal = () => {
  //   setModalMode("create");
  //   setOpenModal(true);
  // };

  // const handleOpenEditModal = (wishlist: any) => {
  //   setModalMode("edit");
  //   setOpenModal(true);
  // };

  // const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  // const [openEditModal, setOpenEditModal] = React.useState(false);
  // const handleOpenEditModal = () => setOpenEditModal(true);
  // const handleCloseEditModal = () => setOpenEditModal(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  // const [anchorE2, setAnchorE2] = React.useState<null | HTMLElement>(null);

  // const label = { inputProps: { "aria-label": "Switch demo" } };

  const openShareMenu = Boolean(anchorEl);
  // const openMoreMenu = Boolean(anchorE2);

  const handleClickShare = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseShare = () => {
    setAnchorEl(null);
  };

  // const handleClickMore = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorE2(event.currentTarget);
  // };
  // const handleCloseMore = () => {
  //   setAnchorE2(null);
  // };

  const handleCopyLink = () => {
    const url = window.location.href;
    navigator.clipboard
      .writeText(url)
      .then(() => {
        toast.success(t("buyer_dashboard.wishlist.copy_msg"));
      })
      .catch((err) => {
        console.error(t("buyer_dashboard.wishlist.copy_err"), err);
      });
  };

  return (
    <>
      <Box className="Title-heading">
        <h3>{t("buyer_dashboard.wishlist.title")}</h3>
        {/* <button onClick={handleOpenCreateModal}>CREATE NEW WISHLIST</button> */}
      </Box>

      <Box className="Wishlist-main-cont">
        {/* <Box className="Wishlist-sidebar">
          <Box className="wislist-name-list">
            <Box className="name-status">
              <h4>Manish Bhatt</h4>
              <p className="default-cls">Default</p>
            </Box>

            <p className="item-count">
              {products.length} items <LockIcon />
            </p>
          </Box>
        </Box> */}

        <Box className="Wishlist-details-list">
          <Box className="name-head">
            <Box className="name-status">
              <h4>
                {custDetails?.firstname} {custDetails?.lastname}
              </h4>
              <p className="default-cls">
                {t("buyer_dashboard.wishlist.defualt")}
              </p>
            </Box>
            <Box className="share-more-btn">
              <Button onClick={handleClickShare}>
                <ShareIcon />
                {t("buyer_dashboard.wishlist.share")}
              </Button>
              {/* <Button onClick={handleClickMore}>
                <MoreHorizIcon />
                More
              </Button> */}

              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                className="Share-more-menu"
                open={openShareMenu}
                onClose={handleCloseShare}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <Box className="share-more-header">
                  <Box className="ttile">
                    <h4>{t("buyer_dashboard.wishlist.public_wish_title")}</h4>

                    <p>{t("buyer_dashboard.wishlist.public_wish_desc")}</p>
                  </Box>
                  <Box className="close-btn">
                    <Button onClick={handleCloseShare}>
                      <CloseIcon />
                    </Button>
                  </Box>
                </Box>
                <Divider />
                <Box className="share-more-profile">
                  <Box className="share-more-pro-det">
                    <Box className="names">
                      <h4>
                        {custDetails?.firstname} {custDetails?.lastname}
                      </h4>
                      <p className="default">
                        {t("buyer_dashboard.wishlist.defualt")}
                      </p>
                    </Box>

                    <p className="items">
                      {wishlistData?.items_count} <LockIcon />
                    </p>
                  </Box>
                </Box>
                <Divider />
                <Box className="share-more-copy">
                  <Button onClick={handleCopyLink}>
                    <InsertLinkIcon />
                    {t("buyer_dashboard.wishlist.copy_link")}
                  </Button>
                  <a
                    href="mailto:customer@jebelz.com"
                    style={{ textDecoration: "none" }}
                  >
                    <Button>
                      <MailOutlineIcon />
                      {t("buyer_dashboard.wishlist.email")}
                    </Button>
                  </a>
                </Box>
              </Menu>

              {/* <Menu
                anchorEl={anchorE2}
                id="account-menu"
                className="more-details-menu"
                open={openMoreMenu}
                onClose={handleCloseMore}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <Box className="more-share-edit-btn">
                  <Button onClick={handleOpenEditModal}>
                    <NoteAltIcon />
                    Edit
                  </Button>

                  <Modal
                    open={openEditModal}
                    onClose={handleCloseEditModal}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className="edit-name-modal"
                  >
                    <Box sx={style} className="box-size">
                      <Box className="Title-head">
                        <Typography
                          id="modal-modal-title"
                          variant="h6"
                          component="h2"
                          className="head-txt"
                          defaultValue="Manish Bhatt"
                        >
                          Create New Wishlist
                        </Typography>
                        <Button>
                          <CloseIcon onClick={handleCloseEditModal} />
                        </Button>
                      </Box>

                      <Divider />

                      <Box className="content-box">
                        <Box>
                          <TextField
                            autoComplete="given-name"
                            name="lastname"
                            required
                            fullWidth
                            id="lastname"
                          />
                        </Box>

                        <Box className="Upate-btn">
                          <button className="update-btn">Save</button>
                        </Box>
                      </Box>
                    </Box>
                  </Modal>
                </Box>
                <Divider />
                <Box className="more-share-enable-disable-share">
                  <p>
                    <ShareIcon /> Enable/Disable Public Sharing
                  </p>
                  <Switch {...label} />
                </Box>
              </Menu> */}
            </Box>
          </Box>
          {loading ? (
            <Loader />
          ) : (
            <>
              <Box className="Wishlist-card-box">
                {updatedProductData && updatedProductData.length > 0 ? (
                  updatedProductData.map((product: any) => {
                    return (
                      <Box>
                        <Box key={product.id} className="wishlist-box">
                          <Link
                            to={`/${store}/${product?.product.url_key}.html`}
                            style={{
                              textDecoration: "none",
                              color: "inherit",
                            }}
                          >
                            <Box className="img-box">
                              {product.bestSeller && (
                                <p className="w-l-b-best-seller">Best Seller</p>
                              )}
                              <img
                                src={product.product?.image?.url}
                                className="product-img"
                                alt={product.product?.image?.lable}
                              />
                            </Box>

                            <Box className="details-box">
                              <h5 className="w-l-b-name">
                                {product?.product.name}
                              </h5>
                              <p
                                className="w-l-b-price"
                                style={{ fontSize: "16px", fontWeight: "600" }}
                              >
                                {
                                  product?.product?.price_range?.minimum_price
                                    ?.regular_price?.currency
                                }{" "}
                                <span
                                  style={{
                                    textDecoration:
                                      product?.product?.price_range
                                        ?.minimum_price?.discount?.amount_off >
                                      0
                                        ? "line-through"
                                        : "none",
                                    color:
                                      product?.product?.price_range
                                        ?.minimum_price?.discount?.amount_off >
                                      0
                                        ? "gray"
                                        : "black",
                                  }}
                                >
                                  {
                                    product?.product?.price_range?.minimum_price
                                      ?.regular_price?.value
                                  }{" "}
                                </span>
                              </p>

                              {product?.product?.price_range?.minimum_price
                                ?.discount?.amount_off > 0 && (
                                <div style={{ marginTop: "10px" }}>
                                  <p
                                    className="w-l-b-price"
                                    style={{
                                      fontSize: "15px",
                                      fontWeight: "500",
                                    }}
                                  >
                                    {
                                      product?.product?.price_range
                                        ?.minimum_price?.regular_price?.currency
                                    }{" "}
                                    {
                                      product?.product?.price_range
                                        ?.minimum_price?.final_price?.value
                                    }{" "}
                                    &nbsp; &nbsp; &nbsp;
                                    <span
                                      style={{
                                        fontSize: "14px",
                                        fontWeight: "bold",
                                        color: "red",
                                      }}
                                    >
                                      {
                                        product?.product?.price_range
                                          ?.minimum_price?.discount?.percent_off
                                      }
                                      % {t("buyer_dashboard.wishlist.off")}{" "}
                                    </span>
                                  </p>
                                </div>
                              )}

                              <p className="w-l-b-price">
                                {store !== "in" || country !== "in"
                                  ? t(
                                      "buyer_dashboard.wishlist.inclusive_of_vat"
                                    )
                                  : t(
                                      "buyer_dashboard.wishlist.inclusive_of_gst"
                                    )}
                              </p>
                            </Box>
                          </Link>
                          <Box className="btn-grp">
                            <Button
                              className="add-to-card-btn"
                              onClick={() => handleAddToCart(product?.id)}
                              style={{
                                display: product?.product?.configurable_options
                                  ? "none"
                                  : "inline-block",
                              }}
                            >
                              {t("buyer_dashboard.wishlist.add_to_cart_btn")}
                            </Button>

                            {product?.product?.configurable_options && (
                              // <Link to={`/${store}/${product?.product.url_key}.html`}>
                                <Button className="view-card-btn"  onClick={() => navigate(`/${store}/${product?.product.url_key}.html`)}>
                                  {t("buyer_dashboard.wishlist.view_option_btn")}
                                </Button>
                              // </Link>
                            )}

                            <Button className="More-btn">
                              <DeleteIcon
                                onClick={() =>
                                  handleRemoveWishlistClick(product?.id)
                                }
                              />
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    );
                  })
                ) : (
                  <Box 
                   sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",  
                    width: "100%",  
                  }}>
                    <Wishlist />
                  </Box>
                )}
              </Box>
            </>
          )}
        </Box>
      </Box>

      <CreateWishlistModal
        open={openModal}
        handleClose={handleCloseModal}
        mode={modalMode}
      />
    </>
  );
};

export default MyWishlist;
