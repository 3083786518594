import React from 'react';
import './NoReturnCompleted.scss';
import noReturnImg from '../../../../../assets/images/no_return_complete.png'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const NoReturnCompleted = () => {
  const navigate=useNavigate()
  const { t } = useTranslation();

  const closePage = () => {
    const currentPath = window.location.pathname;
      const basePath = currentPath.split("/return-completed")[0];
      navigate(basePath);
  };

  return (
    <>
        <div className="no-return">
          {/* eslint-disable-next-line */}
            <img src={noReturnImg} alt="Image" />

            <h4>{t("buyer_dashboard.emptyReturn.complete_title")}</h4>

            <p>{t("buyer_dashboard.emptyReturn.complete_desc")}</p>

            <button onClick={() => closePage()}>{t("buyer_dashboard.emptyReturn.complete_req_btn")}</button>
        </div>
    </>
  )
}

export default NoReturnCompleted
