import { useContext, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { Container } from "@material-ui/core";
import * as mutations from "../../../operations/mutations";
import { useLazyQuery, useMutation } from "@apollo/client";
import CardHeader from "@mui/material/CardHeader";
import { Link, Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import AlertTitle from "@mui/material/AlertTitle";
import Alert from "@mui/material/Alert";
import { useTranslation } from "react-i18next";
import { CountryContext, mainContext } from "../MainComponent";
import * as queries from "../../../operations/queries";
import googleIcon from '../../../assets/images/google_icon.png';
import {
  useGoogleLogin,
} from "@react-oauth/google";
import { toast } from "react-toastify";

// function isAuthenticated() {
//   return !!localStorage.getItem("loggedInCust");
// }

function Login() {
  let loggedInToken = localStorage.getItem("loggedInCust");
  const userLoggedIn = localStorage.getItem("LoggedInUser");
  const storePath = window.location.pathname;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [islogin, setIslogin] = useState<any>(
    loggedInToken && JSON.parse(loggedInToken)
  );
  const navigate = useNavigate();
  const { store } = useContext(CountryContext);
  const selectedStores =
    store && store !== "" ? store : storePath?.split("/")?.[1];
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [resError, setResError] = useState("");
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const path = window.location.pathname.split("/")[1];
  const [newToken, setnewToken] = useState<any>();
  const [newResponse, setnewResponse] = useState<any>();
  // eslint-disable-next-line
  const [isDestinationId,setIsDestinationId] = useState<any>();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const {
    quest_cart_id,
    /*  setCartCount, set_quest_cart_id */ set_quest_cart_id,
  } = useContext(mainContext);
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  let [generateCustomerToken, { data: generateCustData }] = useMutation(
    mutations.loginMutation.LOGIN_MUTATION,
    {
      context: {
        headers: {
          Connection: "keep-alive",
          store: store,
        },
      },
    }
  );

  let [generateCustomerTokenAPI] = useMutation(
    mutations.loginMutation.SIGN_UP_WITH_LOGIN,
    {
      context: {
        headers: {
          Authorization: newToken ? `Bearer ${newToken}` : `Bearer ${newToken}`,
          Connection: "keep-alive",
          store: store && store !== "" ? store : storePath?.split("/")?.[1],
        },
      },
    }
  );

  const [mergeCartsMutation] = useMutation(
    mutations.addToCartMutation.MERGE_CARTS,
    {
      context: {
        headers: {
          Authorization: islogin ? `Bearer ${islogin}` : "",
          Connection: "keep-alive",
          store: store && store !== "" ? store : storePath?.split("/")?.[1],
        },
      },
    }
  );

  const handleGenerateToken = async (event: any) => {
    event.preventDefault();
    const data = formData;

    try {
      // Generate token
      const response = await generateCustomerToken({
        variables: data,
      });

      generateCustData = response?.data;

      setResError("");
      setShowErrorAlert(false);

      const isLogin = {
        isLogin: true,
        token: generateCustData?.generateCustomerToken?.token,
      };
      navigate(`/${store}`, { state: isLogin });
      const token = generateCustData?.generateCustomerToken?.token;

      if (token) {
        await localStorage.setItem("loggedInCust", JSON.stringify(token));

        await localStorage.setItem("LoggedInUser", "true");

        setIslogin(token);

        await handleMergeCarts(token);

        custCartQueryDataRefetch();
      }
    } catch (err: any) {
      setResError(err?.message);
      setShowErrorAlert(true);
    }
  };

  useEffect(() => {
    custCartQueryDataRefetch()
      .then((result: any) => {
        if (result?.data?.customerCart?.id) {
          // eslint-disable-next-line
          destinationCartId = result?.data?.customerCart?.id;
          handleMergeCarts(islogin);
        }
      })
      .catch((error) => {
        console.error("login page Refetch error", error);
      });
  }, [islogin, loggedInToken]);

  const [
    fetchQueryId,
    { data: custQueryDataRes , refetch: custCartQueryDataRefetch},
  ] = useLazyQuery(queries.customerQueries.GET_CUSTOMER_CART_QUERY, {
    context: {
      headers: {
        Authorization: loggedInToken
          ? `Bearer ${JSON.parse(loggedInToken)}`
          : "",
        Connection: "keep-alive",
        store: store,
      },
    },
  });

  useEffect(() => { 
    if (loggedInToken) {
      fetchQueryId();
    } 
  }, [loggedInToken, userLoggedIn,fetchQueryId]);
    // eslint-disable-next-line
  let destinationCartId = custQueryDataRes?.customerCart?.id;

  useEffect(() => {
    if (custQueryDataRes && loggedInToken) {
      set_quest_cart_id(custQueryDataRes.customerCart.id);
    }
    // eslint-disable-next-line
  }, [custQueryDataRes]);

  // useEffect(() => {
  //   if (custQueryDataRes && loggedInToken) {
  //     setIsDestinationId(custQueryDataRes?.customerCart?.id);
  //   }
  // }, [custQueryDataRes]);

  // useEffect(() => {
  //   if (loggedInToken && islogin) {
  //     custCartQueryDataRefetch();
  //   }
  // }, [loggedInToken, islogin]);

  const handleMergeCarts = async (token: string) => {
    // console.log("merge");
    try {
      await mergeCartsMutation({
        variables: {
          cartId: quest_cart_id,
          destinationCartId: isDestinationId,
        },
        context: {
          headers: {
            Authorization: token ? `Bearer ${token}` : "",
            Connection: "keep-alive",
            store: store && store !== "" ? store : storePath?.split("/")?.[1],
          },
        },
      });

      const sessionStoreData = sessionStorage.getItem("storeAndCart");
      const currentsessionStore = sessionStoreData
        ? JSON.parse(sessionStoreData)
        : [];
      const filteredArray =
        currentsessionStore &&
        currentsessionStore?.filter(
          (object: any) => object.genStore === selectedStores
        );
      sessionStorage.setItem("storeAndCart", JSON.stringify(filteredArray));
    } catch (error) {
      console.error("Login page Merge Carts Error:", error);
    }
  };

  useEffect(() => {
    if (quest_cart_id && isDestinationId) {
      handleMergeCarts(islogin);
    }
    // eslint-disable-next-line
  }, [loggedInToken, quest_cart_id,isDestinationId]);

  const login = useGoogleLogin({
    onSuccess: async (response) => {
      try {
        handleSuccess(response);
      } catch (error) {
        console.error("Google login failed:", error);
        toast.error("Google login failed.");
      }
    },
    onError: (error) => console.log("Login Failed:", error),
  });

  const handleSuccess = async (response: any) => {
    setnewResponse(response);
    try {
      const res = await fetch(
        "https://www.googleapis.com/oauth2/v1/userinfo?alt=json",
        {
          headers: {
            Authorization: `Bearer ${response.access_token}`,
          },
        }
      );
      const dataIs = await res.json();
      const data = {
        email: dataIs?.email,
        firstname: dataIs?.given_name,
        lastname: dataIs?.family_name,
        apitoken: response?.access_token,
      };

      if (newToken !== "undefined") {
        setnewToken(response?.access_token);
        const responseIs = await generateCustomerTokenAPI({
          variables: data,
        });
        const generateCustData = responseIs?.data;
        setResError("");
        setShowErrorAlert(false);

        const isLogin = {
          isLogin: true,
          token: generateCustData?.generateCustomerToken?.token,
        };

        const token = generateCustData?.generateCustomerTokenAPI?.token;

        navigate(`/${store}`, { state: isLogin });

        if (token) {
          await localStorage.setItem("loggedInCust", JSON.stringify(token));

          await localStorage.setItem("LoggedInUser", "true");

          setIslogin(token);

          await handleMergeCarts(token);

          await custCartQueryDataRefetch();
        }
      }
    } catch (err: any) {
      console.error(err?.message);
      console.error("Failed to fetch user info:", err);
    }
  };

  useEffect(() => {
    handleSuccess(newResponse);
    // handleMergeCarts(islogin);
    // eslint-disable-next-line
  }, [newToken]);

  if (loggedInToken) {
    return <Navigate to={`/${path}`} replace />;
  }

  return (
    <div className="login-wrapper directionrtl">
      <div className="reg-content-wrap">
        <Container component="main" maxWidth="md">
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Grid
              container
              spacing={{ xs: 0, md: 8, lg: 8 }}
              justifyContent="center"
            >
              <Grid item lg={10} md={10} xs={12}>
                <Card
                  sx={{
                    maxWidth: "100%",
                    boxShadow: "none",
                    border: "1px solid #E0DEDE",
                    mb: 3,
                    p: 2,
                  }}
                >
                  <CardHeader
                    sx={{
                      backgroundColor: "#eee",
                      padding: "12px",
                      "& .MuiTypography-root": {
                        fontSize: "24px",
                        fontWeight: "700",
                        color: "#000",
                      },
                    }}
                    title={t("auth.login.login_text")}
                  />
                  <CardContent>
                    {showErrorAlert && (
                      <Alert severity="error">
                        <AlertTitle>Error</AlertTitle>
                        {resError}
                      </Alert>
                    )}
                    <Box
                      component="form"
                      noValidate
                      onSubmit={handleGenerateToken}
                      autoComplete="off"
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={12} className="input-field-wrap">
                          <TextField
                            placeholder={t("auth.login.email")}
                            required
                            fullWidth
                            id="email"
                            name="email"
                            value={formData.email}
                            autoComplete="off"
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12} className="input-field-wrap">
                          <TextField
                            placeholder={t("auth.login.pwd")}
                            required
                            fullWidth
                            id="password"
                            name="password"
                            type={showPassword ? "text" : "password"}
                            value={formData.password}
                            autoComplete="off"
                            onChange={handleChange}
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={showPassword}
                                onChange={handleShowPassword}
                                name="showPassword"
                              />
                            }
                            label="Show Password"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            fullWidth
                            onClick={handleGenerateToken}
                            variant="contained"
                            disableElevation
                            sx={{
                              my: 2,
                              textTransform: "capitalize",
                              color: "#fff",
                              fontSize: "18px",
                              fontWeight: "500",
                              padding: "6px 34px",
                            }}
                          >
                            {t("auth.login.login")}
                          </Button>
                        </Grid>
                        <Grid item xs={12} sx={{ textAlign: "center", m: -2 }}>
                          <p>OR</p>
                        </Grid>
                        <Grid item xs={12} lg={12} md={12} sm={12}>
                          <div className="google-login-container">
                            <div className="social-button-group">
                              <Button
                              className="social_button"
                                sx={{ width: "100%" }}
                                onClick={() => login()}
                              >
                                {" "}
                                <span className="g_icon">
                                <img src={googleIcon} alt="gIcon" />
                                </span>
                                <span>{t("auth.checkout.sign_in_with_google")}</span> 
                              </Button>
                            </div>
                          </div>
                        </Grid>
                        {/* <Grid item xs={12}></Grid> */}
                        <Grid
                          item
                          xs={12}
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Link
                            className="form-sub-text"
                            to={`/${store}/forgotpassword`}
                            style={{ color: "#1082C2" }}
                          >
                            {t("auth.login.forgot_password")}
                          </Link>
                        </Grid>
                        <Grid item xs={12}>
                          <span className="form-sub-text">
                            {t("auth.login.dont_have_acc")}{" "}
                            <Link
                              to={`/${store}/registration`}
                              style={{ color: "#1082C2" }}
                            >
                              {t("auth.login.create_new_acc")}
                            </Link>
                          </span>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid item xs={12}>
                            <span className="form-sub-text">
                              {t("auth.login.continue_text")}{" "}
                              {/* <Link to="/" style={{ color: "#1082C2" }}> */}
                              {t("auth.login.cond_of_use")}
                              {/* </Link> */}
                              {t("auth.login.privacy_notice")}
                            </span>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </div>
    </div>
  );
}
export default Login;