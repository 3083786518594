import React, { useContext, useEffect } from "react";
import { CountryContext } from "./MainComponent";
import Loading from "./Layout/loader";
import { useNavigate } from "react-router-dom";

const SubdomainRedirect = () => {
  const { store } = useContext(CountryContext);
  const host = window.location.hostname;
  const navigate=useNavigate()

  useEffect(() => {
    const handleRedirect = (url: any) => {
      window.location.href = url;
    };

    if(window.location.pathname === `/${store}/ordersuccess`){
      return;
    }

    if (host.includes("localhost")) {
      handleRedirect(`/${store}`);
      return;
    }

    if (host.includes("reactstg") || host.includes("react")) {
      if (store === "in" && !host.includes("react-in")) {
        handleRedirect(`https://reactstg.jebelz.com/${store}`);
      } else if (store === "sa_ar" && !host.includes("react-sa")) {
        handleRedirect(`https://reactstg.jebelz.com/${store}`);
      } else if (store === "sa_en" && !host.includes("react-sa")) {
        handleRedirect(`https://reactstg.jebelz.com/${store}`);
      } else if (store === "ar" && !host.includes("react-om")) {
        handleRedirect(`https://reactstg.jebelz.com/${store}`);
      } else if (store === "en" && !host.includes("react-om")) {
        handleRedirect(`https://reactstg.jebelz.com/${store}`);
      }else if (store === "ae_er" && !host.includes("react.")) {
        handleRedirect(`https://reactstg.jebelz.com/${store}`);
      }else if (store === "ae_en" && !host.includes("react.")) {
        handleRedirect(`https://reactstg.jebelz.com/${store}`);
      } else {
        handleRedirect(`https://reactstg.jebelz.com/${store}`);
      }
    } else {
      if (store === "in" && !host.includes("in")) {
        handleRedirect(`https://in.jebelz.com/${store}`);
      } else if (store === "sa_ar" && !host.includes("sa")) {
        handleRedirect(`https://sa.jebelz.com/${store}`);
      } else if (store === "sa_en" && !host.includes("sa")) {
        handleRedirect(`https://sa.jebelz.com/${store}`);
      } else if (store === "en" && !host.includes("om")) {
        handleRedirect(`https://om.jebelz.com/${store}`);
      } else {
        handleRedirect(`https://jebelz.com/${store}`);
      }
    }
  }, [store, host,navigate]);

  return <><Loading /></>;
};

export default React.memo(SubdomainRedirect);
