import List from "@mui/material/List";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Registration from "../../container/buyer/auth/registration";
import ForgotPassword from "../../container/buyer/auth/ForgotPassword";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import "./modal.scss";
// import { useMutation } from "@apollo/client";
// import * as mutations from "../../operations/mutations";
// import { useContext } from "react";
// import { CountryContext } from "../../container/buyer/MainComponent";

function Modal(props: any) {
  const { onClose, selectedValue, open } = props;
  const { t } = useTranslation();
  // const loggedInToken = localStorage.getItem("loggedInCust");
  // const { store } = useContext(CountryContext);
  // const { quest_cart_id} = useContext(mainContext)

  //  const [assigncustGuestCart] = useMutation(
  //    mutations.addToCartMutation.ASSIGN_CUST_GUEST_CART,
  //    {
  //     context: {
  //       headers: {
  //         Authorization: loggedInToken
  //           ? `Bearer ${JSON.parse(loggedInToken)}`
  //           : "",
  //         Connection: "keep-alive",
  //         store: store,
  //       },
  //     },
  //   }
  // );

  const handleClose = async () => {
    onClose(false);
  };

  return (
    <Dialog onClose={handleClose} open={open} className="register-modal">
      <div className="modal-header">
        <DialogTitle>
          {selectedValue === "register"
            ? t("auth.registration.create_new")
            : t("auth.forgotPwd.frg_pwd_title")}
        </DialogTitle>
        <CloseIcon onClick={handleClose} />
      </div>
      <List sx={{ pt: 0 }}>
        {selectedValue === "register" ? (
          <Registration onClose={handleClose} />
        ) : (
          <ForgotPassword onClose={handleClose} />
        )}
      </List>
    </Dialog>
  );
}

export default Modal;
