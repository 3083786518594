// TabbyWidget.js

import React, { useContext, useEffect } from "react";
import "./postPayStip.scss"
import { mainContext } from "../../MainComponent";
// import { useTranslation } from "react-i18next";

const TabbyWidget = (props) => {
  //console.log("tabby here 111111 ........") 
  const { store } = useContext(mainContext);
  // const { t } = useTranslation();
  const storePath = window.location.pathname;
  const selectedStores =
    store && store !== "" ? store : storePath?.split("/")?.[1];
  var amt = parseInt((props.amt / 100) * 100);
  //console.log("props_page: ",props.page)
  //if(props.page=="checkout") amt = parseFloat((props.amt / 100) * 100);
  //if(props.page=="detail") amt = parseInt((props.amt / 100) * 100);
  const lang = props.store.split('_')[1]
  let merchantCode = "uae_AED";
  if (props.store === "sa_ar" || props.store === "sa_en") {
    //eslint-disable-next-line
    merchantCode = "ksa_SAR";
    // console.log(merchantCode); 
}

  const tabbyScript = document.createElement("script");
  tabbyScript.async = true;
  tabbyScript.src = "https://checkout.tabby.ai/tabby-card.js";
  document.head.appendChild(tabbyScript);

  // console.log(`https://checkout.tabby.ai/tabby-card.js`); 
  useEffect(() => {
    tabbyScript.addEventListener('load', (e) => {
      //console.log("amt: ",amt)      
      window.TabbyCard({
        selector: '#tabbyCard', // empty div for TabbyCard.
        currency: props.currency, // required, currency of your product. AED|SAR|KWD|BHD|QAR only supported, with no spaces or lowercase.
        lang: lang, // Optional, language of snippet and popups.
        price: amt, // required, total price or the cart. 2 decimals max for AED|SAR|QAR and 3 decimals max for KWD|BHD.
        size: 'wide', // required, can be also 'wide', depending on the width.
        theme: 'black', // required, can be also 'default'.
        header: false // if a Payment method name present already. 
      });
    });
    // eslint-disable-next-line
  }, []);
  const dataLocale = selectedStores === 'ae_en' || selectedStores === 'ae_er';
  
  return (
    <>
      {dataLocale && props.page === 'checkout' ?
        <>
          <div id="tabbyCard"></div>
        </>
        :
        <>
          <div id="tabbyCard"></div>
        </>
      }
    </>
  );
};

export default TabbyWidget;
