import React, { useContext, useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import * as mutations from "../../../operations/mutations";
import { useMutation } from "@apollo/client";
import CardHeader from "@mui/material/CardHeader";
import {
  Autocomplete,
  FormControl,
  FormHelperText,
  InputLabel,
} from "@mui/material";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import SvgIcon from "@mui/material/SvgIcon";
import * as queries from "../../../operations/queries";
import { useQuery } from "@apollo/client";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { CountryContext } from "../MainComponent";
import { useTranslation } from "react-i18next";
import axios from "axios";
// import { useTranslation } from "react-i18next";

function CustomSelectIcon(props: any) {
  return (
    <SvgIcon {...props}>
      <path
        d="M13.2825 7.28294C13.3528 7.21322 13.4086 7.13027 13.4467 7.03888C13.4848 6.94748 13.5044 6.84945 13.5044 6.75044C13.5044 6.65143 13.4848 6.5534 13.4467 6.46201C13.4086 6.37062 13.3528 6.28766 13.2825 6.21794L9.53255 2.46794C9.46282 2.39765 9.37987 2.34185 9.28848 2.30377C9.19708 2.2657 9.09905 2.24609 9.00005 2.24609C8.90104 2.24609 8.80301 2.2657 8.71161 2.30377C8.62022 2.34185 8.53727 2.39765 8.46755 2.46794L4.71755 6.21794C4.64725 6.28766 4.59145 6.37062 4.55338 6.46201C4.5153 6.5534 4.4957 6.65143 4.4957 6.75044C4.4957 6.84945 4.5153 6.94748 4.55338 7.03887C4.59145 7.13027 4.64725 7.21322 4.71755 7.28294C4.78727 7.35324 4.87022 7.40903 4.96161 7.44711C5.05301 7.48519 5.15104 7.50479 5.25005 7.50479C5.34905 7.50479 5.44708 7.48519 5.53848 7.44711C5.62987 7.40903 5.71282 7.35324 5.78254 7.28294L9.00005 4.05794L12.2175 7.28294C12.2873 7.35324 12.3702 7.40903 12.4616 7.44711C12.553 7.48519 12.651 7.50479 12.75 7.50479C12.8491 7.50479 12.9471 7.48519 13.0385 7.44711C13.1299 7.40903 13.2128 7.35324 13.2825 7.28294ZM9.53255 15.5329L13.2825 11.7829C13.3525 11.713 13.4079 11.63 13.4458 11.5386C13.4836 11.4473 13.5031 11.3493 13.5031 11.2504C13.5031 11.0507 13.4238 10.8592 13.2825 10.7179C13.2126 10.648 13.1296 10.5925 13.0382 10.5547C12.9469 10.5169 12.8489 10.4974 12.75 10.4974C12.5503 10.4974 12.3588 10.5767 12.2175 10.7179L9.00005 13.9429L5.78254 10.7179C5.71282 10.6476 5.62987 10.5919 5.53848 10.5538C5.44708 10.5157 5.34905 10.4961 5.25005 10.4961C5.15104 10.4961 5.05301 10.5157 4.96161 10.5538C4.87022 10.5919 4.78727 10.6476 4.71755 10.7179C4.64725 10.7877 4.59145 10.8706 4.55338 10.962C4.5153 11.0534 4.4957 11.1514 4.4957 11.2504C4.4957 11.3495 4.5153 11.4475 4.55338 11.5389C4.59145 11.6303 4.64725 11.7132 4.71755 11.7829L8.46755 15.5329C8.53727 15.6032 8.62022 15.659 8.71161 15.6971C8.80301 15.7352 8.90104 15.7548 9.00005 15.7548C9.09905 15.7548 9.19708 15.7352 9.28848 15.6971C9.37987 15.659 9.46282 15.6032 9.53255 15.5329Z"
        fill="#000"
        fillOpacity="0.51"
      />
    </SvgIcon>
  );
}

interface FormDataState {
  id: number | null;
  region: {
    region: string;
    region_code: string;
    region_id: number;
  };
  company: string;
  country_code: string;
  street: string[];
  telephone: string;
  postcode: string;
  city: string;
  firstname: string;
  lastname: string;
  default_shipping: boolean;
  default_billing: boolean;
}

function AddNewAddress(props: any) {
  const { t } = useTranslation();
  const { store } = useContext(CountryContext);
  const loggedInToken = localStorage.getItem("loggedInCust");
  const [custCountry, setCustCountry] = useState(String());
  const [selectedCountry, setSelectedCountry] = useState("");
  const [state, setState] = useState([]);
  const [custState, setCustState] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [cities, setCities] = useState([]);
  const [filteredCities, setFilteredCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState("");
  const store_code = localStorage.getItem("store_code");
  // const [selectedRegion, setSelectedRegion] = useState({
  //   bill_region: "",
  //   region_id: 0,
  // });

  const initialFormData = {
    id: null,
    region: {
      region: "",
      region_code: "",
      region_id: 0,
    },
    company: "",
    country_code: "AE",
    street: [""],
    telephone: "",
    postcode: store_code === ("ae_en" || "ae_ar") ? "0000" : "",
    city: "",
    firstname: "",
    lastname: "",
    default_shipping: true,
    default_billing: false,
  };
  const initialFormErr = {
    region: {
      region: "",
      region_code: "",
      region_id: "",
    },
    company: "",
    country_code: "",
    street: "",
    telephone: "",
    postcode: "",
    city: "",
    firstname: "",
    lastname: "",
    default_shipping: true,
    default_billing: false,
  };
  const [formData, setFormData] = useState<FormDataState>(initialFormData);
  const handleClose = () => {
    props.handleCloseModal();
    setFormData(initialFormData);
    setCustState("");
    setSelectedCity("");
    setFormErr(initialFormErr);
    setIsSubmitted(false);
  };
  const [formErr, setFormErr] = useState(initialFormErr);

  const handleInputChange = (event: any) => {
    const { name, value } = event.target;

    let sanitizedValue = value;

    if (name === "postcode") {
      sanitizedValue = value.trim().replace(/\D/g, "");
    }

    if (name === "telephone") {
      sanitizedValue = value.replace(/\D/g, "");
    }

    if (name === "street") {
      setFormData((prevData) => ({
        ...prevData,
        [name]: [sanitizedValue],
      }));
    } else {
      setFormData((prevData) => ({
        ...prevData,
        [name]: sanitizedValue,
      }));
    }

    if (isSubmitted) {
      setFormErr((prevErr) => {
        const newErrors = { ...prevErr };

        if (name === "firstname" && value !== "") {
          newErrors.firstname = "";
        }
        if (name === "lastname" && value !== "") {
          newErrors.lastname = "";
        }
        if (name === "telephone" && sanitizedValue.length === 10) {
          newErrors.telephone = "";
        }
        if (name === "street" && sanitizedValue.length > 0) {
          newErrors.street = "";
        }
        if (name === "city" && sanitizedValue !== "") {
          newErrors.city = "";
        }
        if (name === "state" && sanitizedValue !== "") {
          newErrors.region.region = "";
        }
        if (name === "postcode" && sanitizedValue !== "") {
          newErrors.postcode = "";
        }

        return newErrors;
      });
    }
  };

  const { data: custDetailsDataRes } = useQuery(
    queries.customerQueries.GET_CUSTOMER_INFO,
    {
      skip: !loggedInToken,
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
        },
      },
    }
  );
  const custDetails = custDetailsDataRes?.customer;

  useEffect(() => {
    if (custDetails) {
      const add = custDetails?.addresses?.filter(
        (item: any) => item?.id === props.isEdit
      );
      if (add && add.length > 0) {
        const data = {
          id: parseInt(add[0]?.id),
          region: {
            region: add[0]?.region?.region,
            region_code: add[0]?.region?.region_code,
            region_id: 0,
          },
          company: add[0]?.company,
          country_code: add[0]?.country_code,
          street: add[0]?.street,
          telephone: add[0]?.telephone,
          postcode: add[0]?.postcode,
          city: add[0]?.city,
          firstname: add[0]?.firstname || "",
          lastname: add[0]?.lastname || "",
          default_shipping: add[0]?.default_shipping,
          default_billing: add[0]?.default_billing,
        };
        setFormData(data);
        setCustCountry(add[0]?.country_code);
        setCustState(add[0]?.region?.region_code);
        setSelectedCity(add[0]?.city);
      }
    }
  }, [custDetails, props.isEdit]);

  const [createCustomerAddress] = useMutation(
    mutations.customerMutation.CREATE_ADDRESS,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store,
        },
      },
    }
  );

  const [updateCustomerAddress] = useMutation(
    mutations.customerMutation.UPDATE_ADDRESS,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
        },
      },
    }
  );

  useEffect(() => {
    if (store?.length > 0) {
      localStorage.setItem("store_code", store);
    }
    onCountryChange();
  }, [store]);

  const onCountryChange = async () => {
    const store_code = await localStorage.getItem("store_code");
    if (store_code === "ae_en" || store_code === "ae_ar") {
      setSelectedCountry("AE");
    } else if (store_code === "sa_en" || store_code === "sa_ar") {
      setSelectedCountry("SA");
      localStorage.setItem("getCountryCode", "SA");
    } else if (store_code === "en" || store_code === "ar") {
      setSelectedCountry("OM");
    } else if (store_code === "in") {
      setSelectedCountry("IN");
    }
  };

  const validateForm = () => {
    let isValid = true;

    if (formData.firstname === "") {
      formErr.firstname = t("auth.checkout.firstname_err");
    }
    if (formData.lastname === "") {
      formErr.lastname = t("auth.checkout.lastname_err");
    }
    formErr.telephone = "";
    if (formData.telephone === "") {
      formErr.telephone = t("auth.checkout.ph1_err");
      isValid = false;
    } else if (
      formData.telephone.length < 10 ||
      formData.telephone.length > 15
    ) {
      formErr.telephone = t("auth.checkout.ph3_err");
      isValid = false;
    } else if (!/^\d+$/.test(formData.telephone)) {
      formErr.telephone = t("auth.checkout.ph2_err");
      isValid = false;
    }
    formErr.street = "";
    if (formData.street[0].length === 0) {
      formErr.street = t("auth.checkout.street_err");
      isValid = false;
    }
    if (formData.city === "") {
      formErr.city = t("auth.checkout.city_err");
    }
    if (!formData.postcode.trim()) {
      formErr.postcode = t("auth.checkout.post1_err");
    } else if (!/^\d+$/.test(formData.postcode)) {
      formErr.postcode = t("auth.checkout.post2_err");
    }
    formErr.region.region = "";
    if (formData.region.region == null || formData.region.region === "") {
      formErr.region.region = t("auth.checkout.state_err");
      isValid = false;
    }

    return isValid;
  };

  const handledCustState = (event: any) => {
    // eslint-disable-next-line
    // const rCode: any = state?.filter(
    //   (item: any) => item?.code === event
    // );
    let region = {
      region: event,
      region_code: event,
      region_id: 0,
    };
    /*if (rCode && rCode.length > 0) {
      region = {
        region: rCode && rCode[0]?.name,
        region_code: rCode[0]?.code,
        region_id: rCode[0]?.id,
      };
    }*/
    setFormData({ ...formData, region: region });
    setCustState(event);

    if (isSubmitted) {
      if (store === "sa_ar" || store === "sa_en") {
        setFormErr((prevErr) => ({
          ...prevErr,
          city: t("auth.checkout.city_err"),
        }));
      }
    }
  };

  useEffect(() => {
    if (custState) {
      const rCode: any = state?.find((item: any) => item.code === custState);
      if (rCode) {
        const region = {
          region: rCode.name,
          region_code: rCode.code,
          region_id: rCode.id,
        };
        setFormData({ ...formData, region });
      }
    }
    // eslint-disable-next-line
  }, [custState, state]);

  const { data: countryRegRes } = useQuery(
    queries.configurationQueries.GET_COUNTRIES,
    {
      context: {
        headers: {
          Connection: "keep-alive",
          store: store,
        },
      },
    }
  );
  const countries = countryRegRes?.countries;
  const selectedCountryData = countryRegRes?.countries?.find(
    (data: any) => data?.two_letter_abbreviation === selectedCountry
  );

  useEffect(() => {
    if (countries && countries?.length > 0) {
      // eslint-disable-next-line array-callback-return
      const data = countries?.filter((item: any) => {
        if (item?.two_letter_abbreviation === custCountry) {
          return item?.available_regions;
        }
      });
      setState(data[0]?.available_regions);
    }
    // eslint-disable-next-line
  }, [custCountry]);

  const handleCreateAddress = async (event: any) => {
    event.preventDefault();

    setIsSubmitted(true);
    const isFormValid = validateForm();

    if (!isFormValid) {
      return;
    }

    const addressId = formData.id;

    try {
      const selectedRegion = selectedCountryData?.available_regions?.find(
        (region: any) => region.code === custState
      );
      let result;
      if (props.isEdit) {
        result = await updateCustomerAddress({
          variables: {
            id: addressId,
            input: {
              region: {
                region: formData.region.region,
                region_code: formData.region.region_code,
                region_id: formData.region.region_id,
              },
              company: "jebelz",
              country_code: formData.country_code,
              street: formData.street,
              telephone: formData.telephone,
              postcode: formData.postcode,
              city: formData.city,
              firstname: formData.firstname,
              lastname: formData.lastname,
              default_shipping: formData.default_shipping,
              default_billing: formData.default_billing,
            },
          },
        });
      } else {
        result = await createCustomerAddress({
          variables: {
            input: {
              region: {
                region: selectedRegion?.name || "",
                region_code: selectedRegion?.code || "",
                region_id: selectedRegion?.id || 0,
              },
              company: "",
              country_code: selectedCountry,
              street: formData.street,
              telephone: formData.telephone,
              postcode: formData.postcode,
              city: formData.city,
              firstname: formData.firstname,
              lastname: formData.lastname,
              default_shipping: formData.default_shipping,
              default_billing: formData.default_billing,
            },
          },
        });
      }

      if (result && result?.data) {
        handleClose();
        props.setSuccessAddress(true);
      }
    } catch (error) {
      // console.error('Address Operation Error:', error);
      props.setSuccessAddress(false);
    }
  };

  const title = props.isEdit
    ? t("auth.checkout.edit_address")
    : t("auth.checkout.add_address");

  useEffect(() => {
    if (store === "sa_en" || store === "sa_ar") {
      axios
        .get("https://access.jebelz.com/media/contents/ksa_city.json")
        .then((response) => {
          setCities(response?.data);
        })
        .catch((error) => {
          console.error("Error fetching cities:", error);
        });
    }
  }, [store]);

  useEffect(() => {
    if (custState) {
      setFilteredCities(
        cities.filter((city: any) => city?.region_code === custState)
      );
    }
  }, [custState, cities]);

  const handleCityChange = (event: any) => {
    const cityCode = event.target.value;
    setSelectedCity(cityCode);
    if (cityCode) {
      setFormData({ ...formData, city: cityCode });
    }

      setFormErr((prevErr) => ({
        ...prevErr,
        city: cityCode ? "" : t("auth.checkout.city_err"),
      }));
  };
 
  return (
    <div className="modal-wrap">
      <Modal
        className="add-address-modal"
        open={props?.isOpen}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Grid container spacing={8} justifyContent="center">
          <Grid
            item
            xs={11}
            md={7}
            lg={5}
            xl={5}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
            }}
          >
            <Card
              sx={{
                maxWidth: "100%",
                boxShadow: "none",
                border: "1px solid #E0DEDE",
                mb: 3,
                p: 2,
              }}
            >
              <CardHeader
                action={
                  <IconButton aria-label="settings" onClick={handleClose}>
                    <CloseIcon />
                  </IconButton>
                }
                sx={{
                  backgroundColor: "#eee",
                  padding: "12px",
                  "& .MuiTypography-root": {
                    fontSize: "24px",
                    fontWeight: "700",
                    color: "#000",
                  },
                }}
                title={title}
              />
              <CardContent>
                <Box
                  component="form"
                  noValidate
                  onSubmit={handleCreateAddress}
                  sx={{ mt: 3 }}
                  autoComplete="off"
                >
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <InputLabel htmlFor="input-field" sx={{ mb: 1 }}>
                        {t("auth.checkout.f_name")}
                        <span style={{ color: "#EA0C0C" }}>*</span>
                      </InputLabel>
                      <TextField
                        error={formErr.firstname !== ""}
                        autoComplete="off"
                        name="firstname"
                        required
                        fullWidth
                        id="firstname"
                        value={formData.firstname}
                        autoFocus
                        onChange={handleInputChange}
                        helperText={formErr.firstname}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <InputLabel htmlFor="input-field" sx={{ mb: 1 }}>
                        {t("auth.checkout.l_name")}
                        <span style={{ color: "#EA0C0C" }}>*</span>
                      </InputLabel>
                      <TextField
                        autoComplete="given-name"
                        name="lastname"
                        required
                        fullWidth
                        id="lastname"
                        value={formData.lastname}
                        autoFocus
                        onChange={handleInputChange}
                        error={formErr.lastname !== ""}
                        helperText={formErr.lastname}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <InputLabel htmlFor="input-field" sx={{ mb: 1 }}>
                        {t("auth.checkout.mob_no")}
                        <span style={{ color: "#EA0C0C" }}>*</span>
                      </InputLabel>
                      <TextField
                        autoComplete="given-telephone"
                        name="telephone"
                        required
                        fullWidth
                        id="telephone"
                        value={formData.telephone}
                        autoFocus
                        onChange={handleInputChange}
                        error={formErr.telephone !== ""}
                        helperText={formErr.telephone}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      {store_code === "in" ? (
                        <InputLabel htmlFor="input-field" sx={{ mb: 1 }}>
                          {t("auth.checkout.street_add")}
                          <span style={{ color: "#EA0C0C" }}>*</span>
                        </InputLabel>
                      ) : (
                        <InputLabel htmlFor="input-field" sx={{ mb: 1 }}>
                          {t("auth.checkout.street_add")}
                          <span style={{ color: "#EA0C0C" }}>*</span>
                        </InputLabel>
                      )}
                      <TextField
                        autoComplete="given-streetadd"
                        name="street"
                        required
                        fullWidth
                        id="street"
                        value={formData.street}
                        autoFocus
                        onChange={handleInputChange}
                        error={formErr.street !== ""}
                        helperText={formErr.street}
                      />
                    </Grid>
                    {store_code !== ("ae_en" || "ae_ar") && (
                      <Grid item xs={12}>
                        {store_code === "in" ? (
                          <InputLabel htmlFor="input-field" sx={{ mb: 1 }}>
                            {t("auth.checkout.Pincode")}
                            <span style={{ color: "#EA0C0C" }}>*</span>
                          </InputLabel>
                        ) : (
                          <InputLabel htmlFor="input-field" sx={{ mb: 1 }}>
                            {t("auth.checkout.Postcode")}
                            <span style={{ color: "#EA0C0C" }}>*</span>
                          </InputLabel>
                        )}
                        <TextField
                          autoComplete="given-postcode"
                          name="postcode"
                          required
                          fullWidth
                          id="postcode"
                          value={formData.postcode}
                          autoFocus
                          onChange={handleInputChange}
                          error={formErr.postcode !== ""}
                          helperText={formErr.postcode}
                        />
                      </Grid>
                    )}
                    <Grid item xs={6}>
                      <InputLabel htmlFor="input-field" sx={{ mb: 1 }}>
                        {t("auth.checkout.state")}
                        <span style={{ color: "#EA0C0C" }}>*</span>
                      </InputLabel>

                      <FormControl
                        fullWidth
                        sx={{
                          border: "1px solid #C6C3C3",
                          borderRadius: "2px",
                        }}
                      >
                        <Autocomplete
                          id="state-autocomplete"
                          options={selectedCountryData?.available_regions || []}
                          getOptionLabel={(option: any) => option?.name || ""}
                          isOptionEqualToValue={(option: any, value: any) =>
                            option?.code === value?.code
                          }
                          value={
                            selectedCountryData?.available_regions?.find(
                              (region: any) => region.code === custState
                            ) || null
                          }
                          onChange={(event, newValue: any) => {
                            if (newValue) {
                              handledCustState(newValue.code);
                              setFormErr((prevErr) => ({
                                ...prevErr,
                                region: { ...prevErr.region, region: "" },
                              }));
                            }
                          }}
                          popupIcon={<CustomSelectIcon />}
                          renderInput={(params: any) => (
                            <TextField
                              {...params}
                              required
                              error={formErr.region.region !== ""}
                              InputProps={{
                                ...params.InputProps,
                                sx: {
                                  padding: "10px",
                                },
                              }}
                            />
                          )}
                          ListboxProps={{
                            style: {
                              maxHeight: 300,
                              overflowY: "auto",
                            },
                          }}
                        />
                      </FormControl>
                      {formErr.region.region && (
                        <FormHelperText
                          sx={{ color: "error.main", marginLeft: "14px" }}
                        >
                          {formErr.region.region}
                        </FormHelperText>
                      )}
                    </Grid>

                    {store === "sa_ar" || store === "sa_en" ? (
                      <Grid item xs={6}>
                        <InputLabel htmlFor="input-field" sx={{ mb: 1 }}>
                          {t("auth.checkout.city")}
                          <span style={{ color: "#EA0C0C" }}>*</span>
                        </InputLabel>
                        <FormControl
                          fullWidth
                          sx={{
                            border: "1px solid #C6C3C3",
                            borderRadius: "2px",
                          }}
                        >
                          <Select
                            IconComponent={CustomSelectIcon}
                            labelId="city-simple-select-label"
                            id="city-simple-select"
                            required
                            value={selectedCity || ""}
                            onChange={handleCityChange}
                            error={formErr.region.region !== ""}
                            disabled={!custState}
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  maxHeight: 300,
                                  overflowY: "auto",
                                },
                              },
                            }}
                          >
                            {filteredCities?.map((city: any) => (
                              <MenuItem
                                key={city?.city_code}
                                value={city?.city_code}
                              >
                                {store === "sa_en"
                                  ? city?.city_name_en
                                  : city?.city_name_ar}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {formErr.city && (
                          <FormHelperText
                            sx={{ color: "error.main", marginLeft: "14px" }}
                          >
                            {formErr.city}
                          </FormHelperText>
                        )}
                      </Grid>
                    ) : (
                      <Grid item xs={6}>
                        {store_code === "in" ? (
                          <InputLabel htmlFor="input-field" sx={{ mb: 1 }}>
                            {t("auth.checkout.town")}
                            <span style={{ color: "#EA0C0C" }}>*</span>
                          </InputLabel>
                        ) : (
                          <InputLabel htmlFor="input-field" sx={{ mb: 1 }}>
                            {t("auth.checkout.city")}
                            <span style={{ color: "#EA0C0C" }}>*</span>
                          </InputLabel>
                        )}
                        <span>
                          <TextField
                            autoComplete="given-city"
                            name="city"
                            required
                            fullWidth
                            id="city"
                            value={formData.city}
                            autoFocus
                            onChange={handleInputChange}
                            error={formErr.city !== ""}
                            helperText={formErr.city}
                            disabled={!custState}
                            sx={{
                              "& input": {
                                cursor: !custState ? "not-allowed" : "auto",
                              },
                            }}
                          />
                        </span>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <InputLabel htmlFor="input-field" sx={{ mb: 1 }}>
                        {t("auth.checkout.country")}
                        <span style={{ color: "#EA0C0C" }}>*</span>
                      </InputLabel>
                      <FormControl
                        fullWidth
                        sx={{
                          border: "1px solid #C6C3C3",
                          borderRadius: "2px",
                        }}
                      >
                        <Select
                          IconComponent={CustomSelectIcon}
                          labelId="simple-select-label"
                          id="simple-select"
                          className="qty-select"
                          disabled={true}
                          value={selectedCountry}
                        >
                          {countryRegRes?.countries &&
                            countryRegRes?.countries.map((option: any) => {
                              return (
                                <MenuItem
                                  key={option?.two_letter_abbreviation}
                                  value={option?.two_letter_abbreviation}
                                >
                                  {option?.full_name_english}
                                </MenuItem>
                              );
                            })}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{
                          my: 2,
                          textTransform: "capitalize",
                          color: "#fff",
                          fontSize: "18px",
                          fontWeight: "500",
                          padding: "6px 34px",
                        }}
                      >
                        {t("auth.checkout.use_address")}
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Modal>
    </div>
  );
}
export default AddNewAddress;
