import React, {
  useEffect,
  useState,
  useContext,
  lazy,
  Suspense,
  useCallback,
  useMemo,
} from "react";
import Box from "@mui/material/Box";
import Carousel from "react-multi-carousel";
import Grid from "@material-ui/core/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import "react-multi-carousel/lib/styles.css";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "./home.scss";
import { Skeleton, useMediaQuery } from "@mui/material";
import theme from "../../../theme";
import { CountryContext } from "../MainComponent";
import axios from "axios";
// import GlobalLoader from "../Layout/loader";
import { Helmet } from "react-helmet";
import CarouselSlide from "../../../components/CarouselSlider/CarouselSlide";

const OurClientsSection = lazy(() => import("./OurClientsSection"));
const ShopNowCarousel = lazy(() => import("./ShopNowCarousel"));
// const CarouselSlide = lazy(
//   () => import("../../../components/CarouselSlider/CarouselSlide")
// );
const Brands = lazy(() => import("./Brands"));
const MasonarySection = lazy(() => import("./MasonarySection"));
// const TopCarouselSection = lazy(() => import("./TopCarouselSection"));
interface HomeProps {
  store: string;
  isCountry: string;
  onLoad: boolean;
}

const responsive: any = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1536 },
    items: 10,
  },
  desktop: {
    breakpoint: { max: 1536, min: 1200 },
    items: 10,
    slidesToSlide: 3,
  },
  laptop: {
    breakpoint: { max: 1200, min: 900 },
    items: 6,
    slidesToSlide: 3,
  },
  tablet: {
    breakpoint: { max: 900, min: 600 },
    items: 3,
    slidesToSlide: 2,
  },
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 4,
    slidesToSlide: 1,
  },
};

const shop_now_responsive: any = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

const responsive_one = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 1,
    slidesToSlide: 1,
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

function Home({ onLoad, isCountry }: HomeProps) {
  const { t } = useTranslation();
  //eslint-disable-next-line
  const { country, store } = useContext(CountryContext);
  const [showAllBrands, setShowAllBrands] = useState(false);
  const [homeDataIs, setHomeDataIs] = useState<any>(null);
  const storePath = window.location.pathname;
  const baseStore = useMemo(
    () => (store !== "" ? store : storePath?.split("/")?.[1]),
    [store, storePath]
  );

  const fetchHomeData = useCallback(async () => {
    const url = `https://access.jebelz.com/media/contents/homeContents-${store}.json`;
    try {
      const response = await axios.get(url, {
        headers: {
          'Cache-Control': 'max-age=1800',
        },
      });
      setHomeDataIs(response?.data);
    } catch (err) {
      console.log("error", err);
    } 
    //eslint-disable-next-line
  }, [store]);

  useEffect(() => {
    if(store){
      fetchHomeData();
    }
  }, [fetchHomeData,store]);

  const combinedData = [
    {
      title: "main.coffee-title",
      productData: homeDataIs?.coffeeCorner,
    },
    {
      title: "capsulePodCoffeeMachines",
      productData: homeDataIs?.capsulePodCoffeeMachines,
    },
    {
      title: "main.newar-title",
      productData: homeDataIs?.newArrivals,
    },
    {
      title: "main.berista-title",
      productData: homeDataIs?.baristaPick,
    },
    {
    title: "main.aceesories-title",
      productData: homeDataIs?.accessories,
    },
    {
      title: "main.cp-title",
      productData: homeDataIs?.cleaningProducts,
    },
    {
      title: "main.staff-title",
      productData: homeDataIs?.staffFavorites,
    },
    {
      title: "main.officePick",
      productData: homeDataIs?.officePick,
    },
  ];

  const sliderData = homeDataIs?.homeBanners?.data?.getData;
  const catData = homeDataIs?.homeSubCategories?.data?.getSubCategoriesData;
  const tPromoData = homeDataIs?.homePromotionsTop?.data?.getData;
  const btPromoRes = homeDataIs?.homePromotionsBottom?.data?.getData;
  const brandData = homeDataIs?.brands?.data?.getData ?? [];
  const ourClientsData = homeDataIs?.ourClients?.data?.getData ?? [];
  const metaData = useMemo(
    () => homeDataIs?.homeMeta?.data?.cmsPage,
    [homeDataIs]
  );

  const initialColumnsToShow = 36;
  const columnsToShow = showAllBrands ? brandData.length : initialColumnsToShow;

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  let btPromoData: any;

  if (btPromoRes) {
    let bPromoData = JSON.parse(JSON.stringify(btPromoRes));
    btPromoData = bPromoData;
    if (btPromoData) {
      const imgData = btPromoData.filter((i: any) => i.image !== "");
      const n = 4;
      const result = imgData.slice(0, n);
      btPromoData = result;
      btPromoData.forEach((element: any, index: any) => {
        if (index === 2) {
          element.rows = 2;
          element.cols = 1;
        } else if (index === 3) {
          element.cols = 2;
          element.rows = 1;
        }
      });
    }
  }

  return (
    <>
      {/* {homeDataIs === null ? (
        <div className="home-wrapper">
          <div style={{ backgroundColor: "#fff" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                minHeight: "700px",
              }}
            >
              <GlobalLoader type="isSmallLoader" />
            </Box>
          </div>
        </div>
      ) : ( */}
      <div className="home-wrapper">
        <Helmet>
          <title>{metaData?.meta_title}</title>
          <meta name="description" content={metaData?.meta_description} />
          <meta name="keywords" content={metaData?.meta_keyword} />
          <meta name="DC.title" content={metaData?.meta_title} />
          <meta
            name="geo.region"
            content={baseStore === "in" ? "IN-UP" : "AE-DU"}
          />
          <meta
            name="geo.placename"
            content={baseStore === "in" ? "Noida" : "Dubai"}
          />
          <meta
            name="geo.position"
            content={
              baseStore === "in" ? "28.58508;77.315262" : "25.121614;55.377031"
            }
          />
          <meta
            name="ICBM"
            content={
              baseStore === "in"
                ? "28.58508, 77.315262"
                : "25.121614, 55.377031"
            }
          />
        </Helmet>
        {sliderData?.length > 0  ? (
          <Box>
          <div className="top-slider -hero-slider">
            <Carousel
              className="top-offer-slider"
              responsive={responsive_one}
              swipeable={true}
              autoPlay={true}
              transitionDuration={4000}
              autoPlaySpeed={6000}
              customTransition="all 3s linear"
              arrows={true}
              infinite={true}
              centerMode={false}
              ssr={true}
              showDots={true}
              removeArrowOnDeviceType={["tablet", "mobile"]}
              slidesToSlide={1}
            >
              {sliderData?.length > 0 &&
                sliderData?.map((data: any, index: any) => {
                  return (
                    <>
                      <Link to={`/${store}/${data?.link}`}>
                        <img
                          src={
                            isMobileScreen ? data?.image_mobile : data?.image
                          }
                          alt={data?.name}
                        ></img>
                      </Link>
                    </>
                  );
                })}
            </Carousel>
          </div>
        </Box>
          
        ) : (
          <Box>
            <div className="top-slider -hero-slider">
              <>
                <Carousel
                  // className="top-offer-slider"
                  responsive={responsive_one}
                  swipeable={true}
                  autoPlay={true}
                  transitionDuration={4000}
                  autoPlaySpeed={6000}
                  customTransition="all 3s linear"
                  arrows={false}
                  infinite={true}
                  centerMode={false}
                  ssr={true}
                  showDots={false}
                  slidesToSlide={1}
                >
                  {Array.from({ length: 3 })?.map((_: any, index: any) => (
                    <Box
                      key={index}
                      sx={{
                        width: "100%",
                        height: { xs: "200px", md: "200px" },
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        overflow: "hidden",
                      }}
                    >
                      <Skeleton
                        variant="rectangular"
                        sx={{
                          width: "100%",
                          height: "100%",
                        }}
                      />
                    </Box>
                  ))}
                </Carousel>
              </>
            </div>
          </Box>
        )}

        {/* <Brand /> */}
        {/* <BrandCategory /> */}
        <Box sx={{ pt: { lg: 5, md: 4, xs: 3 } }}>
          <Grid container>
            <Grid item xs={12} md={12}>
              <Card
                sx={{ maxWidth: "100%", boxShadow: 0 }}
                className="category-home-grid"
              >
                <CardHeader
                  className="section-title"
                  style={{ paddingTop: 0 }}
                  titleTypographyProps={{ variant: "h5" }}
                  title={t("main.category-title")}
                />
                <CardContent sx={{ py: 0 }}>
                  {catData ? (
                    <Carousel
                      className="category-home-grid-slider"
                      responsive={responsive}
                      swipeable={true}
                      autoPlay={true}
                      transitionDuration={3000}
                      autoPlaySpeed={5000}
                      customTransition="all 3s linear"
                      centerMode={false}
                      arrows={false}
                      infinite={true}
                      ssr={true}
                      showDots={false}
                      slidesToSlide={1}
                      deviceType={""}
                      removeArrowOnDeviceType={["tablet", "mobile"]}
                    >
                      {catData?.map((data: any, index: any) => {
                        return (
                          <div className="category-box">
                            <Link
                              to={`/${store}/category/${data?.url_key}`}
                              rel="preload"
                            >
                              <div className="category-box-img">
                                <img
                                  className="img-cover"
                                  draggable={false}
                                  style={{ position: "relative" }}
                                  src={data.image ? data.image : ""}
                                  alt={data?.name}
                                  // loading="lazy"
                                />
                              </div>
                              <div className="category-box-title">
                                <span>
                                  {data.name}{" "}
                                  {isSmallScreen
                                    ? data.image_mobile
                                    : data.image_mobile}
                                </span>
                              </div>
                            </Link>
                          </div>
                        );
                      })}
                    </Carousel>
                  ) : (
                    <Box sx={{ pt: { lg: 5, md: 4, xs: 3 } }}>
                      <Carousel
                        responsive={responsive}
                        swipeable={true}
                        autoPlay={true}
                        transitionDuration={3000}
                        autoPlaySpeed={5000}
                        customTransition="all 3s linear"
                        centerMode={false}
                        arrows={false}
                        infinite={true}
                        ssr={true}
                        showDots={false}
                        slidesToSlide={1}
                        deviceType={""}
                        removeArrowOnDeviceType={["tablet", "mobile"]}
                      >
                        {Array.from({ length: 10 }).map((_, index) => (
                          <div className="category-box" key={index}>
                          <div className="category-box-img">
                            <Skeleton
                              variant="circular"
                              width={80}
                              height={80}
                              sx={{ mx: "auto", mb: 2 }}
                            />
                          </div>
                          <div className="category-box-title">
                            <Skeleton
                              variant="text"
                              width="60%"
                              height={20}
                              sx={{ mx: "auto" }}
                            />
                          </div>
                        </div>
                        ))}
                      </Carousel>
                    </Box>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>

        <Box>
          {homeDataIs?.topSellers && (
            <CarouselSlide
              title={t("main.top-sell-title")}
              productData={homeDataIs?.topSellers}
              iscountry={country !== undefined ? country : ""}
              // isLoadingCarousel={handleCarouselLoading}
              // isLoadingCarousel={(loading)=>setCarouselLoading(loading)}
            />
          )}
        </Box>

        {/* shop now banner section slider */}
        <Box>
          <Suspense>
            <ShopNowCarousel
              tPromoData={tPromoData}
              store={store}
              isSmallScreen={isSmallScreen}
              shop_now_responsive={shop_now_responsive}
            />
          </Suspense>
        </Box>

        <Box>
          {homeDataIs?.brewers && (
            <CarouselSlide
              title={t("main.brewers-title")}
              productData={homeDataIs?.brewers}
              iscountry={country !== undefined ? country : ""}
              // isLoadingCarousel={handleCarouselLoading}
            />
          )}
        </Box>

        {/* masonary section slider */}
        <Box>
          <Suspense>
            <MasonarySection btPromoData={btPromoData} />
          </Suspense>
        </Box>

        <Box>
          {combinedData &&
            combinedData?.length > 0 &&
            combinedData?.map((ele: any, i: any) => {
              return (
                <>
                  <CarouselSlide
                    title={t(ele?.title)}
                    productData={ele?.productData}
                    iscountry={country !== undefined ? country : ""}
                    // isLoadingCarousel={handleCarouselLoading}
                  />
                </>
              );
            })}
        </Box>

        {/* brand section slider */}
        <Box>
          <Suspense>
            {brandData && (
              <Brands
                brandData={brandData}
                showAllBrands={showAllBrands}
                setShowAllBrands={setShowAllBrands}
              />
            )}
          </Suspense>
        </Box>

        {/* our clients section slider */}
        <Box>
          {ourClientsData && (
            <Suspense>
              <OurClientsSection
                ourClientsData={ourClientsData}
                columnsToShow={columnsToShow}
                isSmallScreen={isSmallScreen}
              />
            </Suspense>
          )}
        </Box>
      </div>
      {/* )} */}
    </>
  );
}

export default React.memo(Home);
