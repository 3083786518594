import React from "react";
import "./EmptyCards.scss";
import noOrderImg from "../../../../../assets/images/empty_cards.png";
import { useTranslation } from "react-i18next";

const EmptyCards = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="no-order">
        {/* eslint-disable-next-line */}
        <img src={noOrderImg} alt="Image" />

        <h4>{t("buyer_dashboard.empty_payment.title")}</h4>

        <p>{t("buyer_dashboard.empty_payment.desc")}</p>
      </div>
    </>
  );
};

export default EmptyCards;
