import { gql } from "@apollo/client";
export const CHANGE_PASSWORD_MUTATION = gql`
  mutation ChangePassword($currentPassword: String!, $newPassword: String!) {
    changeCustomerPassword(
      currentPassword: $currentPassword
      newPassword: $newPassword
    ) {
      id
      email
    }
  }
`;

export  const UPDATE_CUSTOMER = gql`
mutation UpdateCustomer($input: CustomerInput!) {
  updateCustomer(input: $input) {
    customer {
      firstname
      lastname
      email
    }
  }
}
`;

export const UPDATE_ADDRESS_MUTATION = gql`
  mutation UpdateCustomerAddress($id: Int!, $input: AddressInput!) {
    updateCustomerAddress(id: $id, input: $input) {
      id
      city
      postcode
    }
  }
`;
export const SUBSCRIBE_EMAIL_TO_NEWSLETTER = gql`
  mutation SubscribeEmailToNewsletter($email: String!) {
    subscribeEmailToNewsletter(email: $email) {
      status
    }
  }
`;
export const UPDATE_CUSTOMER_SUBSCRIBE_MUTATION = gql`
  mutation UpdateCustomerSubscribe($input: CustomerInput!) {
    updateCustomer(input: $input) {
      customer {
        firstname
        lastname
        email
        is_subscribed
      }
    }
  }
`;
export const UPDATE_ADDRESS = gql`
  mutation UpdateCustomerAddress($id: Int!, $input: CustomerAddressInput!) {
    updateCustomerAddress(id: $id, input: $input) {
      id
      region {
        region
        region_code
        region_id
      }
      country_code
      street
      telephone
      postcode
      city
      firstname
      lastname
      default_shipping
      default_billing
    }
  }
`;

export const CREATE_ADDRESS = gql`
  mutation CreateCustomerAddress($input: CustomerAddressInput!) {
    createCustomerAddress(input: $input) {
      id
      region {
        region
        region_code
      }
      country_code
      street
      telephone
      postcode
      city
      firstname
      lastname
      default_shipping
      default_billing
    }
  }
`;

export const REQUEST_PASS_RESET_EMAIL = gql`
  mutation requestPasswordResetEmail($email: String!) {
    requestPasswordResetEmail(email: $email)
  }
`;

export const RESET_PASSWORD_MUTATION = gql`
    mutation resetPassword($email: String!, $resetPasswordToken: String!, $newPassword: String!) {
        resetPassword(
            email: $email,
            resetPasswordToken: $resetPasswordToken,
            newPassword: $newPassword
        )
    }
`;


