import { gql } from "@apollo/client";

export const CREATE_PRODUCT_REVIEW = gql`
  mutation CreateProductReview($input: CreateProductReviewInput!) {
    createProductReview(input: $input) {
      review {
        nickname
        summary
        text
        average_rating
        ratings_breakdown {
          name
          value
        }
      }
    }
  }
`;

export const ADD_CUSTOM_RATING = gql`
  mutation AddCustomRating(
    $types: String!
    $rating: Int!
    $description: String!
    $title: String!
    $name: String!
    $order_id: String!
    $order_item_id: String!
  ) {
    addCustomRating(
      types: $types
      rating: $rating
      description: $description
      title: $title
      name: $name
      order_id: $order_id
      order_item_id: $order_item_id
    ) {
      entity_id
      customer_id
      types
      rating
      description
      title
      name
      order_id
      order_item_id
      created_at
    }
  }
`;
