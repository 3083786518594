import { gql } from "@apollo/client";

export const GET_CONFDETAILS = gql`
  query getConfDetails($url_key: String!) {
    products(filter: { url_key: { eq: $url_key } }) {
      items {
        name
        delivery_estimation
        top_badge_left
        top_badge_left_title
        top_badge_right
        top_badge_right_title
        wishlist_item_id
        is_in_wishlist
        sellerData {
          qty
          price
          currency
          seller_id
          seller_name
          delivery_note
          warranty
        }
        files {
          name
          type
          url
        }
        sku
        manufacturer
        brand_logo
        brand_logo_img
        brand_logo_link
        url_key
        meta_title
        meta_keyword
        meta_description
        stock_status
        options_container
        short_description {
          html
        }
        attributes {
          label
          value
          code
        }
        breadcrumb
        review_count
        rating_summary
        reviews {
          items {
            average_rating
            summary
            text
            created_at
            nickname
            ratings_breakdown {
              value
              name
            }
          }
        }
        small_image {
          disabled
          label
          position
          url
        }
        description {
          html
        }
        ... on ConfigurableProduct {
          media_gallery {
            url
            thumbnail_url
            label
            position
          }
          variants {
            attributes {
              uid
              label
              code
            }
            product {
              sku
              name
              price_range {
                minimum_price {
                  regular_price {
                    value
                    currency
                  }
                  discount {
                    amount_off
                    percent_off
                  }
                  final_price {
                    value
                    currency
                  }
                }
              }
              media_gallery {
                thumbnail_url
                url
                position
              }
              small_image {
                label
                url
              }
              image {
                label
                url
              }
            }
          }
          configurable_options {
            values {
              label
              swatch_data {
                value
              }
            }
          }
        }
        price_range {
          minimum_price {
            regular_price {
              value
              currency
            }
            discount {
              amount_off
              percent_off
            }
            final_price {
              value
              currency
            }
          }
        }
        image {
          url
          label
        }
        small_image {
          url
          label
        }
        media_gallery {
          url
          thumbnail_url
          label
          ... on ProductVideo {
            video_content {
              media_type
              video_provider
              video_url
              video_title
              video_description
              video_metadata
            }
          }
        }
        related_products {
          uid
          name
          sku
          url_key
          top_badge_left
          top_badge_left_title
          top_badge_right
          top_badge_right_title
          small_image {
            url
            small_url
            label
          }
          price_range {
            minimum_price {
              final_price {
                value
                currency
              }
            }
          }
          small_image {
            label
            url
          }
        }
        upsell_products {
          uid
          name
        }
        crosssell_products {
          uid
          name
        }
      }
    }
  }
`;

export const GET_RELATED_PRODUCT_DETAILS = gql`
  query($url_key: String!) {
    products(filter: { url_key: { eq: $url_key } }) {
      items {
        related_products {
          name
          sku
          url_key
          small_image {
            url
            small_url
            thumbnail_url
          }
          price_range {
            minimum_price {
              final_price {
                value
                currency
              }
            }
          }
        }
        upsell_products {
          uid
          name
        }
        crosssell_products {
          uid
          name
        }
      }
    }
  }
`;
