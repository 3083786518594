import { gql } from "@apollo/client";

export const ADD_CLAIM = gql`
  mutation addClaim(
    $order_id: String!
    $issue_type: String!
    $description: String!
    $file: String
  ) {
    addClaim(
      order_id: $order_id
      issue_type: $issue_type
      description: $description
      file: $file
    ) {
      entity_id
      customer_id
      email
      name
      order_id
      item_id
      product_name
      price
      qty
      total_price
      sku
      issue_type
      description
      seller_id
      seller_name
      created_at
      updated_at
      claim_status
    }
  }
`;
