import React, { useState, useContext } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { experimentalStyled as styled } from "@mui/material/styles";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListSubheader from "@mui/material/ListSubheader";
import FormControl from "@mui/material/FormControl";
import InputBase from "@mui/material/InputBase";
import Grid from "@mui/material/Grid";
import { useLazyQuery, useMutation } from "@apollo/client";
import EastIcon from "@mui/icons-material/East";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import SvgIcon from "@mui/material/SvgIcon";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import List from "@mui/material/List";
import { useMediaQuery } from "@mui/material";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import * as queries from "../../../operations/queries";
import { useEffect } from "react";
import { IconButton } from "@mui/material";
import "./footer.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CountryContext, mainContext } from "../MainComponent";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import * as mutations from "../../../operations/mutations";
import theme from "../../../theme";
import axios from "axios";

// declare module '@tawk.to/tawk-messenger-react';
const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "transparent",
  ...theme.typography.body2,
  boxShadow: "none",
  color: theme.palette.text.secondary,
}));
const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
    margin: "6px",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));
function CustomSelectIcon(props: any) {
  return (
    <SvgIcon {...props}>
      <path
        d="M13.2825 7.28294C13.3528 7.21322 13.4086 7.13027 13.4467 7.03888C13.4848 6.94748 13.5044 6.84945 13.5044 6.75044C13.5044 6.65143 13.4848 6.5534 13.4467 6.46201C13.4086 6.37062 13.3528 6.28766 13.2825 6.21794L9.53255 2.46794C9.46282 2.39765 9.37987 2.34185 9.28848 2.30377C9.19708 2.2657 9.09905 2.24609 9.00005 2.24609C8.90104 2.24609 8.80301 2.2657 8.71161 2.30377C8.62022 2.34185 8.53727 2.39765 8.46755 2.46794L4.71755 6.21794C4.64725 6.28766 4.59145 6.37062 4.55338 6.46201C4.5153 6.5534 4.4957 6.65143 4.4957 6.75044C4.4957 6.84945 4.5153 6.94748 4.55338 7.03887C4.59145 7.13027 4.64725 7.21322 4.71755 7.28294C4.78727 7.35324 4.87022 7.40903 4.96161 7.44711C5.05301 7.48519 5.15104 7.50479 5.25005 7.50479C5.34905 7.50479 5.44708 7.48519 5.53848 7.44711C5.62987 7.40903 5.71282 7.35324 5.78254 7.28294L9.00005 4.05794L12.2175 7.28294C12.2873 7.35324 12.3702 7.40903 12.4616 7.44711C12.553 7.48519 12.651 7.50479 12.75 7.50479C12.8491 7.50479 12.9471 7.48519 13.0385 7.44711C13.1299 7.40903 13.2128 7.35324 13.2825 7.28294ZM9.53255 15.5329L13.2825 11.7829C13.3525 11.713 13.4079 11.63 13.4458 11.5386C13.4836 11.4473 13.5031 11.3493 13.5031 11.2504C13.5031 11.0507 13.4238 10.8592 13.2825 10.7179C13.2126 10.648 13.1296 10.5925 13.0382 10.5547C12.9469 10.5169 12.8489 10.4974 12.75 10.4974C12.5503 10.4974 12.3588 10.5767 12.2175 10.7179L9.00005 13.9429L5.78254 10.7179C5.71282 10.6476 5.62987 10.5919 5.53848 10.5538C5.44708 10.5157 5.34905 10.4961 5.25005 10.4961C5.15104 10.4961 5.05301 10.5157 4.96161 10.5538C4.87022 10.5919 4.78727 10.6476 4.71755 10.7179C4.64725 10.7877 4.59145 10.8706 4.55338 10.962C4.5153 11.0534 4.4957 11.1514 4.4957 11.2504C4.4957 11.3495 4.5153 11.4475 4.55338 11.5389C4.59145 11.6303 4.64725 11.7132 4.71755 11.7829L8.46755 15.5329C8.53727 15.6032 8.62022 15.659 8.71161 15.6971C8.80301 15.7352 8.90104 15.7548 9.00005 15.7548C9.09905 15.7548 9.19708 15.7352 9.28848 15.6971C9.37987 15.659 9.46282 15.6032 9.53255 15.5329Z"
        fill="#FEFEFE"
        fillOpacity="0.51"
      />
    </SvgIcon>
  );
}

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "& .MuiInputBase-input": {
    borderRadius: 4,
    position: "relative",
    backgroundColor: theme.palette.mode === "light" ? "#F3F6F9" : "#1A2027",
    border: "1px solid",
    borderColor: theme.palette.mode === "light" ? "#E0E3E7" : "#2D3843",
    fontSize: 16,
    width: "auto",
    padding: "10px 12px",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "&:focus": {
      borderColor: theme.palette.primary.main,
    },
  },
}));

const MailIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
    >
      <path
        d="M29.7 32H3.3C2.42479 32 1.58542 31.5786 0.966547 30.8284C0.347677 30.0783 0 29.0609 0 28V3.826C0.0370139 2.79558 0.400965 1.82232 1.01587 1.1094C1.63077 0.396486 2.4491 -0.000974478 3.3 1.79424e-06H29.7C30.5752 1.79424e-06 31.4146 0.421429 32.0335 1.17157C32.6523 1.92172 33 2.93914 33 4V28C33 29.0609 32.6523 30.0783 32.0335 30.8284C31.4146 31.5786 30.5752 32 29.7 32ZM3.3 7.736V28H29.7V7.736L16.5 18.4L3.3 7.736ZM4.62 4L16.5 13.6L28.38 4H4.62Z"
        fill="white"
      />
    </svg>
  );
};
const WhatsIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clipPath="url(#clip0_639_297)">
        <path
          d="M0.341475 7.90452C0.3411 9.24883 0.6951 10.5615 1.36822 11.7184L0.2771 15.6714L4.3541 14.6107C5.48174 15.2198 6.74519 15.539 8.0291 15.5391H8.03247C12.2709 15.5391 15.7211 12.1169 15.7229 7.91054C15.7237 5.87227 14.9245 3.95562 13.4724 2.51364C12.0205 1.07178 10.0895 0.277297 8.03216 0.276367C3.79323 0.276367 0.343287 3.69838 0.341537 7.90452"
          fill="url(#paint0_linear_639_297)"
        />
        <path
          d="M0.066875 7.90177C0.0664375 9.29445 0.433125 10.654 1.13025 11.8524L0 15.9471L4.22319 14.8484C5.38681 15.4779 6.69694 15.8098 8.03006 15.8103H8.0335C12.424 15.8103 15.9981 12.265 16 7.90809C16.0007 5.79659 15.1727 3.81104 13.6687 2.3174C12.1646 0.823938 10.1646 0.000868217 8.0335 0C3.64225 0 0.068625 3.54481 0.066875 7.90177ZM2.58188 11.646L2.42419 11.3976C1.76131 10.3518 1.41144 9.14326 1.41194 7.90226C1.41338 4.28087 4.38363 1.33457 8.036 1.33457C9.80475 1.33532 11.467 2.01947 12.7172 3.26078C13.9674 4.5022 14.6554 6.15243 14.6549 7.9076C14.6533 11.529 11.683 14.4757 8.0335 14.4757H8.03088C6.84256 14.475 5.67712 14.1584 4.66075 13.56L4.41888 13.4177L1.91275 14.0696L2.58188 11.646V11.646Z"
          fill="url(#paint1_linear_639_297)"
        />
        <path
          d="M6.04247 4.5988C5.89335 4.26993 5.73641 4.26329 5.5946 4.25753C5.47847 4.25256 5.34572 4.25294 5.2131 4.25294C5.08035 4.25294 4.86466 4.30249 4.68235 4.50001C4.49985 4.69771 3.9856 5.17548 3.9856 6.1472C3.9856 7.11898 4.69891 8.05808 4.79835 8.18999C4.89791 8.32165 6.17541 10.3796 8.19866 11.1713C9.88016 11.8292 10.2223 11.6983 10.5873 11.6653C10.9523 11.6325 11.765 11.1877 11.9308 10.7265C12.0968 10.2653 12.0968 9.87005 12.047 9.78745C11.9973 9.70515 11.8645 9.65573 11.6655 9.557C11.4663 9.45821 10.4877 8.98038 10.3053 8.91446C10.1228 8.8486 9.9901 8.81573 9.85735 9.01349C9.7246 9.21095 9.34341 9.65573 9.22722 9.78745C9.11116 9.91948 8.99497 9.93591 8.79597 9.83712C8.59678 9.73802 7.95572 9.52971 7.19516 8.8569C6.60341 8.33337 6.20391 7.68686 6.08778 7.48909C5.97166 7.29163 6.07535 7.1846 6.17516 7.08618C6.2646 6.99768 6.37428 6.85554 6.47391 6.74025C6.57316 6.62491 6.60628 6.54261 6.67266 6.41089C6.7391 6.27904 6.70585 6.1637 6.65616 6.06491C6.60628 5.96611 6.21947 4.98931 6.04247 4.5988Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_639_297"
          x1="772.568"
          y1="1539.78"
          x2="772.568"
          y2="0.276367"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1FAF38" />
          <stop offset="1" stopColor="#60D669" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_639_297"
          x1="800"
          y1="1594.71"
          x2="800"
          y2="0"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9F9F9" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <clipPath id="clip0_639_297">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

function Footer(props: any) {
  const { setCartCount, cartCount } = useContext(mainContext);
  const navigate = useNavigate();
  const location = useLocation();
  const path = location.pathname.split("/");
  const { t, i18n } = useTranslation();
  const { country, setCountry, store, setStore } = useContext(CountryContext);
  const { set_quest_cart_id } = useContext(mainContext);
  const [selectedStore, setSelectedStore] = useState("");
  const [expanded, setExpanded] = React.useState<string | false>("panel1");
  const [selectFLang, setSelectFLanguage] = React.useState(props.store);
  const [newsletter, setNewslatter] = useState("");
  const [newsletterSuccess, setNewsletterSuccess] = useState("");
  const [newsletterError, setNewsletterError] = useState("");
  const [footerDataIs, setFooterDataIs] = useState<any>(null);
  const quest_cart_id: any = localStorage.getItem("quest_cart_id");
  const loggedInToken = localStorage.getItem("loggedInCust");
  const storePath = window.location.pathname;
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const fetchFooterData = async () => {
    const url = `https://access.jebelz.com/media/contents/footer-${store}.json`;
    try {
      const response = await axios.get(url, {
        headers: {
          "Cache-Control": "max-age=1800",
        },
      });
      setFooterDataIs(response?.data);
    } catch (err) {
      console.log("error", err);
    }
  };

  useEffect(() => {
    if (store) {
      fetchFooterData();
    }
    //eslint-disable-next-line
  }, [store]);

  const abFooterRes = footerDataIs?.absoluteFooter?.data || [];
  const footerStripRes = footerDataIs?.footerStrip?.data || [];
  const footerRes = footerDataIs?.footer?.data || [];
  const storeRes = footerDataIs?.stores?.data || [];

  const [fetchCartDetails, { data: cartDetailsRes }] = useLazyQuery(
    queries.cartQueries.GET_CART_DETAILS,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store && store !== "" ? store : storePath?.split("/")?.[1],
        },
      },
    }
  );

  useEffect(() => {
    if (quest_cart_id && quest_cart_id !== "") {
      fetchCartDetails({
        variables: { cart_id: quest_cart_id },
      });
    }
  }, [quest_cart_id, fetchCartDetails, cartCount]);

  const cartDetails = cartDetailsRes?.cart;

  const [removeItemFromCart] = useMutation(
    mutations.addToCartMutation.REMOVECART_MUTATION,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store && store !== "" ? store : storePath?.split("/")?.[1],
        },
      },
    }
  );

  useEffect(() => {
    if (store !== "") {
      // navigate(`/${store}`);
      setSelectedStore(country);
    }
  }, [country, store]);

  useEffect(() => {
    if (selectFLang) {
      props.getCountry(selectedStore, selectFLang, false);
      props.callback(selectFLang);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectFLang, selectedStore]);

  const footerData = footerRes?.getData;
  const abFooterData = abFooterRes?.getData;
  const footerStripData = footerStripRes?.getData;
  const storeData = storeRes?.getStores;

  const handleChangeCollapse =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  const isXSmallScreen = useMediaQuery("(max-width: 900px)");
  let groupArrayObject: any = [];
  let FooterList: any = [];
  let footerAppData: any = [];

  if (footerData) {
    let fData = footerData.filter((i: any) => i.section_name !== null);
    footerAppData = footerData.filter((i: any) => i.section_name === null);
    groupArrayObject = fData.reduce((group: any, arr: any) => {
      const { section_name } = arr;

      group[section_name] = group[section_name] ?? [];
      group[section_name].push(arr);
      return group;
    }, {});
    let footerKey = Object.keys(groupArrayObject);
    footerKey.forEach((key) => {
      FooterList.push({
        title: key,
        items: groupArrayObject[key],
      });
    });
  }

  const handleChangeLang = (event: { target: { value: string } }) => {
    setSelectFLanguage(event.target.value);
    setCountry(event.target.value);
    setStore(event.target.value);
    setSelectedStore(event.target.value);
    set_quest_cart_id("");
    localStorage.removeItem("quest_cart_id");
    // remove cart
    // eslint-disable-next-line array-callback-return
    cartDetails?.items?.map((ele: any) => {
      removeItemFromCart({
        variables: {
          // cartId: props?.isCartId,
          cartId: quest_cart_id,
          cartItemId: ele?.id,
        },
        context: {
          headers: {
            Authorization: loggedInToken
              ? `Bearer ${JSON.parse(loggedInToken)}`
              : "",
            Connection: "keep-alive",
            store: store && store !== "" ? store : storePath?.split("/")?.[1],
          },
        },
      })
        .then((result) => {
          setCartCount(result?.data?.removeItemFromCart?.cart?.items?.length);
        })
        .catch((error) => {
          console.log("Error removing item from cart:", error);
        });
    });
    //end remove cart
    const tempStore = storeData.filter(
      (item: any) => item.store_code === event.target.value
    );
    let storeLang = event.target.value;
    if (tempStore && tempStore[0]?.countryCode === "OMN") {
      setStore("ar");
      storeLang = "ar";
    } else if (tempStore && tempStore[0]?.countryCode === "SAU") {
      setStore("sa_ar");
      storeLang = "sa_ar";
    } else if (tempStore && tempStore[0]?.countryCode === "UAE") {
      setStore("ae_en");
      storeLang = "ae_en";
    }
    const path = location.pathname.split("/");
    const route = location.pathname.replace(path[1], storeLang);
    navigate(`${route}`);
    if (
      storeLang === "en" ||
      storeLang.split("_")[1] === "en" ||
      storeLang === "in"
    ) {
      i18n.changeLanguage("en");
    } else {
      i18n.changeLanguage("ar");
    }
    if (
      storeLang === "en" ||
      storeLang.split("_")[1] === "en" ||
      storeLang === "in"
    ) {
      document.documentElement.setAttribute("lang", "en");
      document.body.classList.add("en");
      document.body.classList.remove("ar");
    } else {
      document.documentElement.setAttribute("lang", "ar");
      document.body.classList.add("ar");
      document.body.classList.remove("en");
    }
  };

  const [subscribeEmailToNewsletter] = useMutation(
    mutations.customerMutation.SUBSCRIBE_EMAIL_TO_NEWSLETTER,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
        },
      },
    }
  );
  const hanldeSetNewSlatter = () => {
    subscribeEmailToNewsletter({
      variables: {
        email: newsletter,
      },
    })
      .then((res: any) => {
        setNewsletterSuccess(
          res?.data?.subscribeEmailToNewsletter?.status.toLowerCase()
        );
        setNewsletterError("");
        setTimeout(() => {
          setNewsletterSuccess("");
          setNewslatter("");
        }, 5000);
      })
      .catch((error: any) => {
        setNewsletterError("This email address is already subscribed.");
        setNewsletterSuccess("");
        setTimeout(() => {
          setNewsletterError("");
        }, 5000);
      });
  };

  const whatUpLink = footerStripData?.filter((item: any) =>
    item?.link?.includes("whatsapp")
  );

  return (
    <>
      {(footerDataIs !== undefined || footerDataIs !== null) && (
        <div className="footer directionrtl">
          <Box>
            <Card
              sx={{ maxWidth: "100%", boxShadow: 0, borderRadius: 0 }}
              className="contact-grid"
            >
              <CardContent sx={{ paddingBottom: "16px !important" }}>
                {footerStripData ? (
                  <Grid container>
                    <Grid
                      lg={4}
                      md={6}
                      xs={12}
                      sx={{ marginBottom: { xs: 3, md: 0 } }}
                    >
                      <Typography
                        noWrap
                        variant="h5"
                        component="div"
                        className="cont-title"
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: footerStripData[0]?.description
                              ? footerStripData[0]?.description
                              : "",
                          }}
                          style={{ whiteSpace: "initial" }}
                        />
                      </Typography>
                    </Grid>
                    <Grid
                      lg={4}
                      md={6}
                      xs={12}
                      sx={{ marginBottom: { xs: 3, md: 0 } }}
                    >
                      <Typography
                        gutterBottom
                        noWrap
                        variant="h5"
                        component="div"
                        className="cont-title"
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: footerStripData[1]?.description
                              ? footerStripData[1]?.description
                              : "",
                          }}
                        />
                      </Typography>
                      <a
                        href={`${whatUpLink && whatUpLink[0]?.link}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <Button
                          sx={{ boxShadow: 0 }}
                          variant="contained"
                          startIcon={<WhatsIcon />}
                          className="whats-btn"
                        >
                          {t("footer.message-watsapp")}
                        </Button>
                      </a>
                    </Grid>
                    <Grid
                      lg={2}
                      md={6}
                      xs={12}
                      sx={{ marginBottom: { xs: 3, md: 0 } }}
                    >
                      <Typography
                        sx={{ textTransform: "capitalize" }}
                        noWrap
                        variant="h5"
                        component="div"
                        className="cont-title "
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: footerStripData[2]?.description
                              ? footerStripData[2]?.description
                              : "",
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid lg={2} md={6} xs={12}>
                      <Typography
                        noWrap
                        variant="h5"
                        component="div"
                        className="cont-title"
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: footerStripData[3]?.description
                              ? footerStripData[3]?.description
                              : "",
                          }}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                ) : (
                  // ))
                  ""
                )}
              </CardContent>
            </Card>
          </Box>

          <Box sx={{ pt: 0 }}>
            <Card
              sx={{ maxWidth: "100%", borderRadius: 0, boxShadow: 0 }}
              className="footer-grid"
            >
              <CardContent sx={{ paddingBottom: "16px !important" }}>
                <Grid
                  container
                  spacing={1}
                  sx={{
                    justifyContent: { xl: "center", xs: "left" },
                    flexWrap: { xl: "nowrap", xs: "wrap" },
                  }}
                >
                  {[...Array(FooterList.length)]?.map((_, index) =>
                    FooterList[index].title !== "Newsletter Signup" &&
                    index < 5 ? (
                      <Grid
                        key={FooterList[index]}
                        {...{ xs: 12, xl: 1.8, lg: 1.8, md: 3, sm: 12 }}
                      >
                        <Accordion
                          expanded={
                            isXSmallScreen
                              ? expanded === FooterList[index].title
                              : true
                          }
                          onChange={handleChangeCollapse(
                            FooterList[index].title
                          )}
                          sx={{
                            backgroundColor: "transparent !important",
                            color: "#fff",
                          }}
                        >
                          <AccordionSummary
                            sx={{ minHeight: "28px" }}
                            aria-controls="panel1d-content"
                            id="panel1d-header"
                            expandIcon={
                              isXSmallScreen ? (
                                <KeyboardArrowRightIcon
                                  sx={{ color: "#fff" }}
                                />
                              ) : (
                                ""
                              )
                            }
                          >
                            <Typography
                              component="h4"
                              className="footer-list-title"
                            >
                              {FooterList[index].title}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails sx={{ py: { lg: 1, xs: 1 } }}>
                            <List
                              aria-label="main mailbox folders"
                              disablePadding
                            >
                              {FooterList[index].items
                                .filter(
                                  (item: any) =>
                                    !(
                                      FooterList[index].title === "Jebelz" &&
                                      item.name === "Blog"
                                    )
                                )
                                ?.map((item: any, i: any) => {
                                  const link = FooterList[index].items[i].link;
                                  const redirectLink = link.split("/");
                                  return (
                                    <div
                                      className={
                                        item.name === "Facebook" ||
                                        item.name === "Instagram" ||
                                        item.name === "Twitter"
                                          ? "social-link"
                                          : ""
                                      }
                                    >
                                      <>
                                        {item.name !== "@description" && (
                                          <ListItem
                                            key={item.name}
                                            disablePadding
                                          >
                                            {item.name === "Affiliates" ||
                                            item.name === "Sell on Jebelz" ||
                                            item.name === "الشركات التابعة" ||
                                            item.name === "بيع على جبلز" ? (
                                              <ListItemButton
                                                sx={{ p: 0 }}
                                                component={Link}
                                                to={item.link}
                                                target="_blank"
                                              >
                                                <ListItemText
                                                  primary={item.name}
                                                  className="footer-list-text"
                                                  style={{
                                                    direction:
                                                      store === "ae_ar" ||
                                                      store === "sa_ar" ||
                                                      store === "ar"
                                                        ? "rtl"
                                                        : "ltr",
                                                    textAlign:
                                                      store === "ae_ar" ||
                                                      store === "sa_ar" ||
                                                      store === "ar"
                                                        ? "right"
                                                        : "left",
                                                  }}
                                                />
                                              </ListItemButton>
                                            ) : item.name === "Facebook" ||
                                              item.name === "Instagram" ||
                                              item.name === "Twitter" ? (
                                              <ListItemButton
                                                sx={{ marginTop: "20px" }}
                                                component={Link}
                                                to={item.link}
                                                target="_blank"
                                              >
                                                <div>
                                                  <img
                                                    src={
                                                      item.image
                                                        ? item.image
                                                        : ""
                                                    }
                                                    alt={item.name}
                                                  />
                                                </div>
                                              </ListItemButton>
                                            ) : (
                                              <ListItemButton
                                                sx={{ p: 0 }}
                                                component={Link}
                                                to={
                                                  redirectLink[
                                                    redirectLink.length - 1
                                                  ] === "brands.html"
                                                    ? `/${store}/${
                                                        redirectLink[
                                                          redirectLink.length -
                                                            1
                                                        ]
                                                      }`
                                                    : `/${store}/cms/${
                                                        redirectLink[
                                                          redirectLink.length -
                                                            1
                                                        ]
                                                      }`
                                                }
                                              >
                                                <ListItemText
                                                  primary={item.name}
                                                  className="footer-list-text"
                                                  style={{
                                                    direction:
                                                      store === "ae_ar" ||
                                                      store === "sa_ar" ||
                                                      store === "ar"
                                                        ? "rtl"
                                                        : "ltr",
                                                    textAlign:
                                                      store === "ae_ar" ||
                                                      store === "sa_ar" ||
                                                      store === "ar"
                                                        ? "right"
                                                        : "left",
                                                  }}
                                                />
                                              </ListItemButton>
                                            )}
                                          </ListItem>
                                        )}
                                      </>
                                    </div>
                                  );
                                })}
                            </List>
                          </AccordionDetails>
                        </Accordion>
                      </Grid>
                    ) : (
                      ""
                    )
                  )}
                  {footerAppData
                    ? [...Array(footerAppData.length)]?.map((_, index) =>
                        footerAppData[index].section === "jebelz-apps" ? (
                          <Grid
                            key={footerAppData[index]}
                            {...{ xs: 12, xl: 1.8, lg: 1.8, md: 4, sm: 12 }}
                            // sx={{ display: { xs: "none", sm: "block" } }}
                          >
                            <Typography
                              component="h4"
                              className="footer-list-title"
                              padding={1}
                            >
                              <div className="Blog">
                                <Link
                                  style={{ color: "white" }}
                                  to={`/${store}/blog.html`}
                                >
                                  {t("footer.blog")}
                                </Link>
                                <br />
                              </div>
                              {footerAppData[index]?.name}
                            </Typography>
                            {/* </AccordionSummary> */}
                            <List
                              aria-label="main mailbox folders"
                              disablePadding
                            >
                              <ListItem
                                key={footerAppData[index]?.name}
                                disablePadding
                              >
                                <ListItemButton
                                  sx={{ p: 0 }}
                                  component={Link}
                                  to="#"
                                >
                                  <img
                                    src={
                                      footerAppData[index]?.image
                                        ? footerAppData[index]?.image
                                        : ""
                                    }
                                    alt={footerAppData[index]?.name}
                                  />
                                </ListItemButton>
                              </ListItem>
                            </List>
                            {/* </AccordionDetails> */}
                            {/* </Accordion> */}
                          </Grid>
                        ) : (
                          ""
                        )
                      )
                    : ""}
                  {[...Array(FooterList.length)].map((_, index) =>
                    FooterList[index].title === "Newsletter Signup" ? (
                      <Grid
                        key={footerAppData[index]}
                        {...{ xs: 12, xl: 2.8, lg: 2.8, md: 4, sm: 12 }}
                        // sx={{ display: { xs: "none", sm: "block" } }}
                        className="newsletter"
                      >
                        <List component="ul" aria-label="main mailbox folders">
                          <ListSubheader
                            sx={{ bgcolor: "transparent" }}
                            className="footer-list-title"
                          >
                            {FooterList[index].title}
                          </ListSubheader>

                          <ListItem sx={{ paddingBottom: "0px" }}>
                            <FormControl variant="standard">
                              <BootstrapInput
                                placeholder="Enter Your Email Address"
                                id="bootstrap-input"
                                className="f-input-box"
                                value={newsletter}
                                onChange={(event: any) =>
                                  setNewslatter(event.target.value)
                                }
                              />
                              <IconButton
                                aria-label="delete"
                                className="mail-box"
                                onClick={hanldeSetNewSlatter}
                              >
                                <MailIcon />
                              </IconButton>
                            </FormControl>
                          </ListItem>
                          {newsletterSuccess !== "" ? (
                            <div className="newsletter-success">
                              {newsletterSuccess}
                            </div>
                          ) : newsletterError !== "" ? (
                            <div className="newsletter-error">
                              {newsletterError}
                            </div>
                          ) : null}
                          <div className="list-icon">
                            {FooterList[index]?.items?.map(
                              (item: any, i: any) => {
                                const link = FooterList[index].items[i].link;
                                const redirectLink = link.split("/");
                                return (
                                  <div
                                    className={
                                      item.name === "Facebook" ||
                                      item.name === "Instagram" ||
                                      item.name === "Twitter"
                                        ? "social-link"
                                        : ""
                                    }
                                  >
                                    <>
                                      {item.name !== "@description" && (
                                        <ListItem
                                          key={item.name}
                                          disablePadding
                                        >
                                          {item.name === "Affiliates" ||
                                          item.name === "Sell on Jebelz" ||
                                          item.name === "الشركات التابعة" ||
                                          item.name === "بيع على جبلز" ? (
                                            <ListItemButton
                                              sx={{ p: 0 }}
                                              component={Link}
                                              to={item.link}
                                              target="_blank"
                                            >
                                              <ListItemText
                                                primary={item.name}
                                                className="footer-list-text"
                                                style={{
                                                  direction:
                                                    store === "ae_ar" ||
                                                    store === "sa_ar" ||
                                                    store === "ar"
                                                      ? "rtl"
                                                      : "ltr",
                                                  textAlign:
                                                    store === "ae_ar" ||
                                                    store === "sa_ar" ||
                                                    store === "ar"
                                                      ? "right"
                                                      : "left",
                                                }}
                                              />
                                            </ListItemButton>
                                          ) : item.name === "Facebook" ||
                                            item.name === "Instagram" ||
                                            item.name === "Twitter" ? (
                                            <div className="list-item-button">
                                              <ListItemButton
                                                sx={{ mt: 2 }}
                                                component={Link}
                                                to={item.link}
                                                target="_blank"
                                              >
                                                <img
                                                  src={
                                                    item.image ? item.image : ""
                                                  }
                                                  alt={item.name}
                                                  style={{
                                                    height: "100%",
                                                    // width: "100%",
                                                    objectFit: "contain",
                                                  }}
                                                  loading="lazy"
                                                />
                                              </ListItemButton>
                                            </div>
                                          ) : (
                                            <ListItemButton
                                              sx={{ p: 0 }}
                                              component={Link}
                                              to={
                                                redirectLink[
                                                  redirectLink.length - 1
                                                ] === "brands.html"
                                                  ? `/${store}/${
                                                      redirectLink[
                                                        redirectLink.length - 1
                                                      ]
                                                    }`
                                                  : `/${store}/cms/${
                                                      redirectLink[
                                                        redirectLink.length - 1
                                                      ]
                                                    }`
                                              }
                                            >
                                              <ListItemText
                                                primary={item.name}
                                                className="footer-list-text"
                                                style={{
                                                  direction:
                                                    store === "ae_ar" ||
                                                    store === "sa_ar" ||
                                                    store === "ar"
                                                      ? "rtl"
                                                      : "ltr",
                                                  textAlign:
                                                    store === "ae_ar" ||
                                                    store === "sa_ar" ||
                                                    store === "ar"
                                                      ? "right"
                                                      : "left",
                                                }}
                                              />
                                            </ListItemButton>
                                          )}
                                        </ListItem>
                                      )}
                                    </>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </List>
                      </Grid>
                    ) : (
                      ""
                    )
                  )}
                </Grid>
              </CardContent>
            </Card>
          </Box>
          <Box>
            <Box>
              <Grid container>
                <Grid item xs={12}>
                  <Card
                    className="country-fbox"
                    sx={{
                      minWidth: "100%",
                      boxShadow: 0,
                      backgroundColor: "#1082C2 !important",
                      color: "#fff",
                    }}
                  >
                    <CardContent sx={{ paddingBottom: "16px !important" }}>
                      <div>
                        {storeData ? (
                          <Stack
                            direction="row"
                            spacing={2}
                            className="bottom-footer"
                            sx={{
                              display: { md: "flex", xs: "block" },
                              alignItems: "stretch",
                              justifyContent: "center",
                              height: "100%",
                              textAlign: { md: "left", xs: "center" },
                            }}
                          >
                            <Item
                              sx={{ backgroundColor: "transparent !important" }}
                            >
                              <Button
                                variant="contained"
                                disabled
                                endIcon={<EastIcon />}
                                sx={{
                                  backgroundColor: "#000 !important",
                                  color: "#fff !important",
                                  height: "100%",
                                }}
                              >
                                {t("footer.country-change")}
                              </Button>
                            </Item>
                            <Item
                              sx={{ backgroundColor: "transparent !important" }}
                            >
                              <div className="country-sel-box">
                                <FormControl variant="standard">
                                  <Select
                                    className="lang-control"
                                    IconComponent={CustomSelectIcon}
                                    labelId="customized-select-label"
                                    id="customized-select"
                                    value={selectedStore}
                                    onChange={(e) => handleChangeLang(e)}
                                    style={{
                                      minWidth: "200px",
                                      textAlign: "left",
                                    }}
                                    input={<BootstrapInput />}
                                  >
                                    {storeData?.map(
                                      (locale: any, index: any) => (
                                        <MenuItem
                                          key={locale?.store_code}
                                          value={locale?.store_code}
                                          className="lang-items"
                                        >
                                          <ListItemButton
                                            sx={{ p: 0 }}
                                            component={Link}
                                            to={`${
                                              locale.countryCode === "OMN"
                                                ? "https://om.jebelz.com/ar"
                                                : locale.countryCode === "SAU"
                                                ? "https://sa.jebelz.com/sa_ar"
                                                : locale.countryCode === "UAE"
                                                ? "https://jebelz.com/ae_en"
                                                : "https://in.jebelz.com/in"
                                            }`}
                                            target="_blank"
                                          >
                                            {" "}
                                            <div className="flag-img">
                                              <img
                                                src={
                                                  locale?.flag
                                                    ? locale?.flag
                                                    : ""
                                                }
                                                alt="flag"
                                                className="img-cover"
                                              />
                                            </div>
                                            <ListItemText
                                              primary={locale?.countryName}
                                              className="footer-list-text"
                                              style={{
                                                direction:
                                                  store === "ae_ar" ||
                                                  store === "sa_ar" ||
                                                  store === "ar"
                                                    ? "rtl"
                                                    : "ltr",
                                                textAlign:
                                                  store === "ae_ar" ||
                                                  store === "sa_ar" ||
                                                  store === "ar"
                                                    ? "right"
                                                    : "left",
                                              }}
                                            />
                                          </ListItemButton>
                                        </MenuItem>
                                      )
                                    )}
                                  </Select>
                                </FormControl>
                              </div>
                            </Item>
                          </Stack>
                        ) : (
                          ""
                        )}
                      </div>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box sx={{ pt: 0 }}>
            <Card
              sx={{
                maxWidth: "100%",
                borderRadius: 0,
                boxShadow: 0,
                bgcolor: "primary.main",
                
              }}
              className="sub-f-grid"
            >
              <CardContent  className="card-content-padding">
                <Grid container spacing={1}>
                  {abFooterData
                    ? [...Array(abFooterData.length)]?.map((_, index) => (
                        <Grid item xs={12} md={6}>
                          {abFooterData[index].section === "copyright" ? (
                            <div className="sub-f-content" key={index}>
                              <Typography
                                noWrap
                                variant="h5"
                                component="div"
                                className="sub-f-title"
                              >
                                {abFooterData[index].description}
                              </Typography>
                            </div>
                          ) : (
                            ""
                          )}
                          {abFooterData[index]?.section === "payment-icons" ? (
                            <div className="payment-f-list">
                              <Typography
                                sx={{ textTransform: "capitalize", mr: 2 }}
                                noWrap
                                variant="h5"
                                component="div"
                                className="sub-f-title "
                              >
                                {abFooterData[index]?.name}
                              </Typography>

                              <img
                                src={abFooterData[index]?.image || ""}
                                alt={abFooterData[index]?.name}
                                height="100%"
                              ></img>
                            </div>
                          ) : (
                            ""
                          )}
                        </Grid>
                      ))
                    : ""}
                </Grid>
              </CardContent>
            </Card>
          </Box>
          <Box>
            {path[2] === "login" ? (
              ""
            ) : (
              <>
                <div className="whatsapp-icon">
                  <a
                    href={`${whatUpLink && whatUpLink[0]?.link}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <WhatsAppIcon />
                    {!isMobileScreen && "whatsapp chat"}
                  </a>
                </div>
              </>
            )}
          </Box>
        </div>
      )}
    </>
  );
}

export default React.memo(Footer);
