import React, { ReactNode, useState, useContext, useCallback } from "react";
import Header from "./Layout/header";
import { makeStyles } from "@material-ui/core/styles";
import Footer from "./Layout/footer";
import "./buyer-gen.scss";
import { useEffect } from "react";
import { CountryContext } from "./MainComponent";
interface MainContentProps {
  children: ReactNode;
  countryCall: (store: any, val: any, load: any) => void;
  isCartId: string;
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiCardContent-root": {
      "& .MuiPaper-root": {
        backgroundColor: "#ffffff",
      },
      "& .MuiCardContent-root:last-child": {
        paddingBottom: "0px",
      },
    },
  },
}));

const Layout = React.memo(
  ({ isCartId, children, countryCall }: MainContentProps, count: any) => {
    const classes = useStyles();
    const { country, setCountry } = useContext(CountryContext);
    const [store, setStore] = useState("");
    const [onLoad, setOnLoad] = useState("");

    const handleCountry = useCallback(
      (store: any, val: any, load: any) => {
        setStore(store);
        setOnLoad(load);
        setCountry(val);
      },
      [setCountry]
    );

    useEffect(() => {
      countryCall(store, country, onLoad);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store, country, onLoad, countryCall]);

    const handlefcountry = (val: any) => {
      setCountry(val);
      setStore(val);
    };

    return (
      <div className={classes.root}>
        <div className="app">
          <header className="header">
            <Header
              isFCountry={country}
              store={store}
              count={count}
              isCartId={isCartId}
            />
          </header>
          <div className="content-wrapper">
            <main className="main-content">{children}</main>
          </div>
          <footer className="footer">
            <Footer
              getCountry={handleCountry}
              callback={handlefcountry}
              store={store}
            />
          </footer>
        </div>
      </div>
    );
  }
);

export default React.memo(Layout);
