import React, { useState, useContext, useEffect, useMemo } from "react";
import Layout from "./layout";
import { Route, Routes, useLocation } from "react-router-dom";
import { useQuery } from "@apollo/client";
import * as queries from "../../operations/queries";
import { CountryContext, mainContext } from "./MainComponent";
import BuyDashboard from "./Buyer-Dashboard/BuyDashboardNew";
import GlobalLoader from "./Layout/loader";
import Home from "./Home/home";
import DomesticMachine from "./Innerpages/Domestic-machine";
import DetailsPage from "./Innerpages/DeatilsPage";
import SubdomainRedirect from "./SubDomain";
import SearchResult from "./Innerpages/SearchResult/SearchResult";
import Login from "./auth/login";
import Register from "./Innerpages/Registration";
import ForgotPwd from "./Innerpages/forgotPwd";
import UpdatePassword from "./Innerpages/UpdatePassword";
import TelsTest from "../../components/telr/telrTest";
import OrderSuccess from "./auth/OrderSuccess";
import Payment from "../../components/CMSPages/payment";
import ServicePage from "../../components/servicePage/servicePage";
import BottomNavbarAllcategories from "./Layout/BottomNavbarAllcategories";
import Blog from "./Layout/Blog";
import BlogDetail from "./Layout/BlogDetail";
import BrandList from "./Innerpages/BrandList";
import BrandPage from "./Innerpages/BrandPage";
import NotFoundPage from "../../components/404page/NotFoundPage";

function Dashboard() {
  const location = useLocation();
  useEffect(() => {
    const headElement = document.querySelector('head');
    const canonicalLinkTag = headElement?.querySelector('link[rel="canonical"]');
    if(canonicalLinkTag) {
      canonicalLinkTag?.setAttribute('href', window.location.href);
    }
    else {
      document.getElementsByTagName('link')
      var link = document.createElement('link');
      link.rel = 'canonical';
      link.href = window.location.href;
      document.head.appendChild(link);
    }
  },[location])

  /*useEffect(() => {
    console.log("useLocation 1: ",location)
    console.log("useLocation 2: location ",window.location)
    console.log("useLocation 3: title ",document.head.title)
  },[document.head.title])*/
  const { setCountry, store, currentStore } =
    useContext(CountryContext);
  // const [store, setStore] = useState("");
  const [onLoad, setOnLoad] = useState(true);
  const { cartId , setCartId } =
    useContext(mainContext);
  const loggedInToken = localStorage.getItem("loggedInCust");
  // const [currentStore, setCurrentStore,] = useState("");
  const handleCountryChange = useMemo(() => (store:any, val:any, load:any) => {
    setCountry(val);
    setOnLoad(load);
  }, [setCountry]);

  const handleCartId = useMemo(() => (cartId:any) => {
    setCartId(cartId);
  }, [setCartId]);

  const {
    data: custWishlistData,
  } = useQuery(queries.wishlistQueries.GET_CUSTOMER_WISHLISTS, {
    skip: !loggedInToken,
    context: {
      headers: {
        Authorization: loggedInToken
          ? `Bearer ${JSON.parse(loggedInToken)}`
          : "",
        Connection: "keep-alive",
        store: store,
      },
    },
  });

  if(custWishlistData && custWishlistData?.customer?.wishlists[0]?.id !== "undefined"){
    const wishlistId = custWishlistData?.customer?.wishlists[0]?.id;
      localStorage.setItem("wishlistId", wishlistId);
  }

  
  useEffect(() => {
    if (currentStore !== store) {
      const host = window.location.hostname;
      if(window.location.pathname === `/${store}/ordersuccess`){
        return;
      }

      if (host.includes("localhost")) {
        window.location.href = `/${store}`;
        return;
      }
         
      if (host.includes("reactstg") || host.includes("react")) {
        if (store === "in" && !host.includes("react-in")) {
          window.location.href = `https://reactstg.jebelz.com/${store}`;
          // window.location.href = `/${store}`;
        } else if (
          (store === "sa_ar" && !host.includes("react-sa")) ||
          (store === "sa_en" && host.includes("react-sa"))
        ) {
          window.location.href = `https://reactstg.jebelz.com/${store}`;
        } else if (
          (store === "ar" && !host.includes("react-om")) ||
          (store === "en" && host.includes("react-om"))
        ) {
          window.location.href = `https://reactstg.jebelz.com/${store}`;
        } else {
          window.location.href = `https://reactstg.jebelz.com/${store}`;
        }
      } else {
        if (store === "in" && !host.includes("in")) {
          window.location.href = ` https://in.jebelz.com/${store}`;

          // window.location.href = `/${store}`;
        } else if (
          (store === "sa_ar" && !host.includes("sa")) ||
          (store === "sa_en" && host.includes("sa"))
        ) {
          window.location.href = `https://sa.jebelz.com/${store}`;
        } else if (
          (store === "ar" && !host.includes("om.")) ||
          (store === "en" && host.includes("om."))
        ) {
          window.location.href = `https://om.jebelz.com/${store}`;
        } else {
          window.location.href = `https://jebelz.com/${store}`;
        }
      }
    }
  }, [store, currentStore]);

  return (
    <Layout countryCall={handleCountryChange} isCartId={cartId}>
      {store ? (
      <>
        <Routes>
          <Route path="/" element={<SubdomainRedirect />} />
          {new RegExp(`(^|/)${store}(/|$)`).test(window.location.pathname) ? 
          <>
              <Route
                path={`/:country`}
                element={
                  <Home isCountry={store} store={store} onLoad={onLoad} />
                }
              />
              <Route
                path="/:country/:urlKey"
                element={<DetailsPage getCartId={handleCartId} />}
              />
              <Route
                path={`/:country/category/:categoryName`}
                element={<DomesticMachine />}
              />
              <Route
                path={`/:country/category/brands/:categoryName`}
                element={<DomesticMachine />}
              />
              <Route
                path={`/:country/category/:categoryName/:subcategory`}
                element={<DomesticMachine />}
              />
              <Route
                path={`/:country/search/:searchParam`}
                element={<SearchResult />}
              />
              <Route path="/:country/login" element={<Login />} />
              <Route path="/:country/registration" element={<Register />} />
              <Route path="/:country/forgotpassword" element={<ForgotPwd />} />
              <Route path="/:country/customer/account/createPassword" element={<UpdatePassword />} />
              <Route
                path="/:country/buyerdashboard/*"
                element={<BuyDashboard />}
              />
              <Route path="/:country/telrPayment" element={<TelsTest />} />
              <Route path="/:country/ordersuccess" element={<OrderSuccess />} />
              <Route path="/:country/cms/:payments" element={<Payment />} />
              <Route path="/:country/cms/service" element={<ServicePage />} />
              <Route path="/:country/all-categories" element={<BottomNavbarAllcategories />} />
              <Route path="/:country/blog.html" element={<Blog />} />
              <Route path="/:country/blog/:blogDetail" element={<BlogDetail />} />
              <Route path="/:country/brands.html" element={<BrandList />} />
              <Route path="/:country/brand/:brandname" element={<BrandPage />} />
              <Route path="/:country/*" element={<NotFoundPage />} />
          </> : 
          <Route path="*" element={<NotFoundPage />} />
          }
        </Routes>
      </>
       ) : (
        <>
          <GlobalLoader />
        </>
      )}
    </Layout>
  );
}
export default React.memo(Dashboard);
