import { useEffect, useState, useContext } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { Container } from "@material-ui/core";
import * as mutations from "../../../operations/mutations";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { CountryContext } from "../MainComponent";
import "./forgotPwd.scss";
import { CircularProgress } from "@mui/material";

const generateRandomCaptcha = (): string => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let captcha = "";
  for (let i = 0; i < 6; i++) {
    captcha += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return captcha;
};

function ForgotPwd() {
  const { t } = useTranslation();
  const { store } = useContext(CountryContext);
  const [email, setEmail] = useState("");
  const [successFull, setSuccessFull] = useState(false);
  const [userInput, setUserInput] = useState("");
  const [captcha, setCaptcha] = useState(generateRandomCaptcha());
  const [errors, setErrors] = useState({ email: "", captcha: "" });
  const loggedInToken = localStorage.getItem("loggedInCust");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window?.scrollTo(0, 0);
  }, []);

  const [requestPasswordResetEmail] = useMutation(
    mutations.customerMutation.REQUEST_PASS_RESET_EMAIL,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store,
        },
      },
    }
  );

  const handleRefresh = () => {
    setCaptcha(generateRandomCaptcha());
    setUserInput("");
  };

  const validateForm = () => {
    let formValid = true;
    const newErrors = { email: "", captcha: "" };

    if (!email || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      newErrors.email = "Please enter a valid email address.";
      formValid = false;
    }

    if (userInput !== captcha) {
      newErrors.captcha = "Captcha does not match.";
      formValid = false;
    }

    setErrors(newErrors);
    return formValid;
  };

  const handleResetPwd = () => {
    if (!validateForm()) return;

    setLoading(true);
    requestPasswordResetEmail({
      variables: {
        email: email,
      },
    })
      .then((res: any) => {
        setSuccessFull(res?.data?.requestPasswordResetEmail);
        setEmail(""); 
        setUserInput("");
        handleRefresh();
      })
      .catch((error: any) => {
        console.error(error);
      }).finally(() => setLoading(false)); 
  };

  useEffect(() => {
    let timer:any;
    if (successFull) {
      timer = setTimeout(() => {
        setSuccessFull(false);
      }, 5000); // 10 seconds
    }
    return () => clearTimeout(timer); 
  }, [successFull]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserInput(e.target.value);
  };

  return (
    <div className="login-wrapper">
      <div className="reg-content-wrap">
        <Container component="main" maxWidth="md">
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Grid
              container
              spacing={{ xs: 0, md: 8, lg: 8 }}
              justifyContent="center"
            >
              <Grid item lg={10} md={10} xs={12}>
                <Card
                  sx={{
                    maxWidth: "100%",
                    boxShadow: "none",
                    border: "1px solid #E0DEDE",
                    mb: 3,
                    p: 2,
                  }}
                >
                  <CardContent>
                    <Typography
                      gutterBottom
                      component="h6"
                      variant="h5"
                      sx={{ fontWeight: 700, color: "#000" }}
                    >
                      {t("auth.forgotPwd.enter_email")}
                    </Typography>

                    <Box
                      component="form"
                      noValidate
                      autoComplete="off"
                    >
                      <Grid container spacing={2}>
                        {/* Email field */}
                        <Grid item xs={12}>
                          <label>
                          {t("auth.updatePassword.email")} * 
                          </label>
                          <TextField
                            placeholder={t("auth.updatePassword.email")}
                            required
                            fullWidth
                            id="email"
                            name="email"
                            value={email}
                            error={!!errors.email}
                            helperText={errors.email}
                            autoComplete="off"
                            onChange={(e: any) => {
                              setEmail(e.target.value);
                            }}
                            sx={{mt:"10px"}}
                          />
                        </Grid>

                        {/* Captcha */}
                        <Grid item xs={12}>
                          <label>
                          {t("auth.updatePassword.captcha_txt")} * 
                          </label>
                          <TextField
                          placeholder={t("auth.updatePassword.captcha")}
                            name="letters"
                            variant="outlined"
                            className="input-field"
                            value={userInput}
                            onChange={handleChange}
                            error={!!errors.captcha}
                            helperText={errors.captcha}
                            required
                            fullWidth
                            sx={{mt:"10px"}}
                          />
                        </Grid>

                        {/* Captcha Image and Reload */}
                        <Grid item xs={12}>
                          <Box className="capture-block">
                            <Box className="capture-img">{captcha}</Box>
                            <Box
                              className="create-ac-btn"
                              onClick={handleRefresh}
                            >
                              {t("auth.updatePassword.reload_captcha")}
                            </Box>
                          </Box>
                        </Grid>

                        {/* Submit Button */}
                        <Grid item xs={12}>
                          <Button
                            fullWidth
                            onClick={handleResetPwd}
                            variant="contained"
                            disableElevation
                            sx={{
                              my: 2,
                              textTransform: "capitalize",
                              color: "#fff",
                              fontSize: "18px",
                              fontWeight: "500",
                              padding: "6px 34px",
                            }}
                            disabled={loading}
                          >
                            {loading ? (
                              <CircularProgress size={30} sx={{ color: "#fff" }} />
                            ) : (
                              t("auth.forgotPwd.reset_my_password")
                            )}
                          </Button>
                        </Grid>

                        {/* Success message */}
                        <Typography color="green">
                          {successFull && t("auth.forgotPwd.success-msg")}
                        </Typography>
                      </Grid>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </div>
    </div>
  );
}

export default ForgotPwd;
