import React from 'react';
import './NoReturn.scss';
import noReturnImg from '../../../../../assets/images/no_returns.png'
// import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const NoReturn = () => {
  const { t } = useTranslation();
  
  return (
    <>
      <div className="no-return">
         {/* eslint-disable-next-line */}
        <img src={noReturnImg} alt="Image" />

        <h4>{t("buyer_dashboard.emptyReturn.pending_title")}</h4>

        <p>{t("buyer_dashboard.emptyReturn.pending_desc")}</p>
        <Link to={`return-completed`}>
          <button>{t("buyer_dashboard.emptyReturn.pending_req_btn")}</button>
        </Link>
      </div>
    </>
  );
}

export default NoReturn
