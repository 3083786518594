import { createTheme } from '@mui/material/styles';

// Define your custom color variables here
const customColors = {
  primary: '#10CDF2', // Replace with your primary color
  secondary: '#3F3E3E', // Replace with your secondary color
  // Add more custom colors as needed
};

// Create a custom theme
const theme = createTheme({
  palette: {
    primary: {
      main: customColors.primary,
    },
    secondary: {
      main: customColors.secondary,
    },
  },
  typography: {
    fontFamily: [
      'Poppins',
      'sans-serif',
    ].join(','),
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1280,
      xl: 1920,
    },
  },
  components: {
    MuiSkeleton: {
      defaultProps: {
        animation: 'wave', 
      },
      styleOverrides: {
        root: {
          // position: 'relative',
          '&::after': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'linear-gradient(90deg, transparent, rgba(128, 128, 128, 0.3), transparent)',
            zIndex: 1,
          },
        },
      },
    },
  },
});

export default theme;