import {
  Avatar,
  Box,
  Breadcrumbs,
  Card,
  Container,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Skeleton,
  styled,
  TableContainer,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useContext } from "react";
import { CountryContext } from "../../container/buyer/MainComponent";
import theme from "../../theme";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

interface DetailsPageSkeltonProps {
  propsDetails: String;
}

const DetailsPageSkelton: React.FC<DetailsPageSkeltonProps> = ({
  propsDetails,
}) => {
  const { store } = useContext(CountryContext);
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <div style={{ backgroundColor: "#fff", marginBottom: "20px" }}>
        <Container maxWidth={false}>
          {propsDetails === "detailsPage" && (
            <Box sx={{ pt: 2 }}>
              <Grid container>
                <Grid item xs={12} md={12}>
                  <div className="breadcrumb-section">
                    <div
                      style={{
                        overflowX: "auto",
                        whiteSpace: "nowrap",
                        direction:
                          store === "ae_ar" ||
                          store === "sa_ar" ||
                          store === "ar"
                            ? "rtl"
                            : "ltr",
                        textAlign:
                          store === "ae_ar" ||
                          store === "sa_ar" ||
                          store === "ar"
                            ? "right"
                            : "left",
                      }}
                    >
                      <Breadcrumbs
                        separator={<>/</>}
                        aria-label="breadcrumb"
                        className="breadcrumb-data"
                      >
                        {/* Skeleton for Breadcrumb Items */}
                        <Skeleton width="150px" height={20} />
                        <Skeleton width="150px" height={20} />
                        <Skeleton width="150px" height={20} />
                      </Breadcrumbs>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Box>
          )}
          <Box sx={{ pt: 2, pb: 3 }}>
            <Grid container className="directionrtl" spacing={2}>
              <Grid
                item
                xl={propsDetails === "detailsPage" ? 4 : 6}
                lg={propsDetails === "detailsPage" ? 4 : 6}
                md={propsDetails === "detailsPage" ? 7 : 6}
                sm={12}
                xs={12}
              >
                <Item sx={{ boxShadow: 0, height: "100%" }}>
                  <div className="right-img-tab cust-tabs-list">
                    {!isMobileScreen ? (
                      <Box
                        className="cust-tabs-view-img directionrtl"
                        sx={{
                          flexGrow: 1,
                          bgcolor: "background.paper",
                          height: 350,
                          display: "flex",
                          gap: 2,
                        }}
                      >
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 1.5,
                              width: "15%",
                            }}
                          >
                            {[...Array(3)].map((_, index) => (
                              <Skeleton
                                key={index}
                                variant="rectangular"
                                width="100%"
                                height={60}
                                sx={{ borderRadius: 2 }}
                              />
                            ))}
                          </Box>

                        <Box
                          sx={{
                            flexGrow: 1,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <Skeleton
                            variant="rectangular"
                            width="100%"
                            height="80%"
                            sx={{ borderRadius: 2 }}
                          />
                          {propsDetails === "detailsModalPage" && (
                            <>
                              <Skeleton
                                variant="text"
                                width="60%"
                                height={40}
                                sx={{ mt: 2 }}
                              />
                              <Skeleton
                                variant="text"
                                width="60%"
                                height={40}
                                sx={{ mt: 1 }}
                              />
                            </>
                          )}
                        </Box>
                      </Box>
                    ) : (
                      <Box>
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height="250px"
                        />
                        <Skeleton
                          variant="text"
                          width="60%"
                          height={30}
                          sx={{ margin: "10px 0" }}
                        />
                      </Box>
                    )}
                  </div>
                </Item>
              </Grid>
              <Grid
                item
                xl={propsDetails === "detailsPage" ? 5 : 6}
                lg={propsDetails === "detailsPage" ? 5 : 6}
                md={propsDetails === "detailsPage" ? 5 : 6}
                sm={12}
                xs={12}
              >
                <Item
                  className="center-content-wrapper"
                  sx={{
                    boxShadow: 0,
                    paddingRight: 0,
                  }}
                >
                  <Box
                    sx={{ textAlign: "start" }}
                    className="details-center-content"
                  >
                    <div className="dtop-img" style={{ maxHeight: "70px" }}>
                      <Skeleton width="100px" height="20px" />
                      <Skeleton
                        width="80px"
                        height="20px"
                        sx={{ marginTop: "5px" }}
                      />
                    </div>

                    <div className="amount-now">
                      <label>
                        <Skeleton width="60%" height="15px" />
                      </label>
                      <Typography variant="h4" component="h4">
                        <Skeleton width="50%" height="30px" />
                      </Typography>
                      <Skeleton
                        width="50%"
                        height="20px"
                        sx={{ marginTop: "10px" }}
                      />
                    </div>

                    <label>
                      <Skeleton width="80px" height="15px" />
                    </label>

                    <Skeleton
                      variant="rectangular"
                      width="100%"
                      height="50px"
                      sx={{
                        marginTop: "20px",
                        borderRadius: "4px",
                      }}
                    />

                    <Skeleton
                      width="40px"
                      height="40px"
                      sx={{ marginTop: "10px" }}
                    />

                    <div className="color-name-block">
                      <label>
                        <Skeleton width="100px" height="15px" />
                      </label>
                      <Skeleton width="40%" height="15px" />
                    </div>

                    <Typography
                      variant="body2"
                      component="ul"
                      className="prod-description"
                      sx={{ px: 0 }}
                    >
                      <Skeleton width="100%" height="20px" />
                      <Skeleton
                        width="100%"
                        height="20px"
                        sx={{ marginTop: "5px" }}
                      />
                    </Typography>
                  </Box>
                </Item>
              </Grid>
              {propsDetails === "detailsPage" && (
                <Grid item xl={3} lg={3} md={12} sm={12} xs={12}>
                  <Item sx={{ boxShadow: 0, mb: 1 }}>
                    <Card variant="outlined" sx={{ borderRadius: "3px" }}>
                      <div className="details-right-content">
                        <List
                          sx={{
                            width: "100%",
                            maxWidth: "100%",
                            bgcolor: "background.paper",
                          }}
                        >
                          <ListItem
                            style={{
                              direction:
                                store === "ae_ar" ||
                                store === "sa_ar" ||
                                store === "ar"
                                  ? "rtl"
                                  : "ltr",
                              textAlign:
                                store === "ae_ar" ||
                                store === "sa_ar" ||
                                store === "ar"
                                  ? "right"
                                  : "left",
                            }}
                          >
                            <ListItemAvatar>
                              <Avatar sx={{ bgcolor: "#fff" }}>
                                <Skeleton
                                  variant="circular"
                                  width={38}
                                  height={38}
                                />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                              className="list-content-title"
                              primary={<Skeleton width="60%" />}
                              secondary={<Skeleton width="80%" />}
                            />
                          </ListItem>
                        </List>
                      </div>
                    </Card>
                  </Item>
                  <Item sx={{ boxShadow: 0 }}>
                    <Card variant="outlined" sx={{ borderRadius: "3px" }}>
                      <div className="details-soldby-content">
                        <List
                          component="nav"
                          aria-label="soldby-list"
                          sx={{ pb: 0 }}
                        >
                          <ListItem sx={{ display: "flex", gap: "10px" }}>
                            <ListItemAvatar>
                              <Skeleton
                                variant="circular"
                                width={38}
                                height={38}
                              />
                            </ListItemAvatar>
                            <ListItemText
                              primary={<Skeleton width="50%" />}
                              secondary={<Skeleton width="70%" />}
                            />
                          </ListItem>
                          <Divider />
                          <ListItem
                            alignItems="center"
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              py: 0,
                            }}
                          >
                            <ListItem
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                textAlign: "center",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <ListItemAvatar>
                                <Skeleton
                                  variant="rectangular"
                                  width="30%"
                                  height={50}
                                />
                              </ListItemAvatar>
                              <ListItemText
                                primary={<Skeleton width="60%" />}
                              />
                            </ListItem>
                            <Divider orientation="vertical" flexItem />
                            <ListItem
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                textAlign: "center",
                                alignItems: "center",
                                justifyContent: "center",
                              }}
                            >
                              <ListItemAvatar>
                                <Skeleton
                                  variant="rectangular"
                                  width="30%"
                                  height={50}
                                />
                              </ListItemAvatar>
                              <ListItemText
                                primary={<Skeleton width="60%" />}
                              />
                            </ListItem>
                          </ListItem>
                        </List>
                      </div>
                    </Card>
                  </Item>
                </Grid>
              )}
            </Grid>
          </Box>
        </Container>
      </div>
      {propsDetails === "detailsPage" && (
        <div style={{ backgroundColor: "#fff", marginBottom: "20px" }}>
          <Container maxWidth={false}>
            <Box sx={{ pt: 5, pb: 5 }}>
              <Grid
                container
                style={{
                  direction:
                    store === "ae_ar" || store === "sa_ar" || store === "ar"
                      ? "rtl"
                      : "ltr",
                  textAlign:
                    store === "ae_ar" || store === "sa_ar" || store === "ar"
                      ? "right"
                      : "left",
                }}
              >
                <Grid
                  item
                  xl={7}
                  lg={7}
                  md={6}
                  sm={12}
                  xs={12}
                  className="dsc-block"
                >
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="h5"
                    sx={{ px: 1, fontWeight: "700" }}
                  >
                    <Skeleton width="40%" />
                  </Typography>
                  <Divider />
                  <Box sx={{ mt: 2, px: 1 }}>
                    <div className="overview-box">
                      <Skeleton variant="rectangular" height={30} />
                    </div>
                  </Box>
                  <Box sx={{ mt: 2, px: 1 }}>
                    <div className="overview-box">
                      <Skeleton variant="rectangular" height={30} />
                    </div>
                  </Box>
                  <Box sx={{ mt: 2, px: 1 }}>
                    <div className="overview-box">
                      <Skeleton variant="rectangular" height={30} />
                    </div>
                  </Box>
                  <Box sx={{ mt: 2, px: 1 }}>
                    <div className="overview-box">
                      <Skeleton variant="rectangular" height={30} />
                    </div>
                  </Box>
                </Grid>

                <Grid
                  item
                  xl={5}
                  lg={5}
                  md={6}
                  sm={12}
                  xs={12}
                  className="dsc-block"
                >
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="h5"
                    sx={{ px: 1, fontWeight: "700" }}
                  >
                    <Skeleton width="40%" />
                  </Typography>
                  <Divider />
                  <Box sx={{ mt: 2, px: 1 }}>
                    <TableContainer>
                      <Skeleton variant="rectangular" height={170} />
                    </TableContainer>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </div>
      )}
    </>
  );
};

export default DetailsPageSkelton;
