import React, { useContext, useState } from "react"
import { Link } from "react-router-dom"
import { Box, Grid } from "@material-ui/core"
// import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
// import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import LayersIcon from '@mui/icons-material/Layers';
// import Zoom from '@mui/material/Zoom';
import { CountryContext } from "../MainComponent";
import AllCategoriesMenu from "./AllCategoriesMenu";

// const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
//   <Tooltip {...props} classes={{ popper: className }} />
// ))(({ theme }) => ({
//   [`& .${tooltipClasses.tooltip}`]: {
//     backgroundColor: '#fff',
//     color: '#000',
//     maxWidth: '100%',
//     fontSize: theme.typography.pxToRem(12),
//     borderRadius: 0,
//     marginTop: '0 !important',
//     display: 'flex',
//     alignItems: 'center',
//     justifyContent: 'center',
//     padding: '20px'
//   },
// }));

const SubMenu = (props: any) => {
  const { store } = useContext(CountryContext)
  const [activeCategory, setActiveCategory] = useState(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [activeSubCategory, setActiveSubCategory] = useState(null);
  const handleCategoryClick = (category: any, option: any) => {
    setActiveCategory(category);
    setActiveSubCategory(option);
  };
  
  return (
    <Box sx={{
      width: '100%',
      bgcolor: 'black',
      display: { xs: 'none', md: 'block' }
    }}>
      <div className="sub-header-wrap">
        <Grid container>
          <Grid item xs={12}>
            {props.menuData ?
              <ul className='category-wrap list-unstyled'>
                <AllCategoriesMenu menuData={props.menuData} activeCategory={activeCategory} setActiveCategory={setActiveCategory} />  
                {props.menuData && props.menuData?.map((item: any, index: any) => (
                  (item?.is_visible_top) ?
                  <li key={index} className={item === activeCategory ? "index-"+index + " activeCategory " : "index-"+index}>
                    {index === 0 && (
                      <LayersIcon style={{ fontSize: 24, color: '#00BCD4', marginRight: '8px' }} />
                    )}
                    <Link to={`/${store}/category/${item?.url_path}.html`} className="sub-head-btn" onClick={() => handleCategoryClick(item, null)}>
                      {item?.name}
                    </Link>
                  </li>
                  : ''
                ))}
              </ul>
              : ''}
          </Grid>
        </Grid>
      </div>
    </Box>
  )
}

export default React.memo(SubMenu)
