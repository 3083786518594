import {
  Divider,
  Typography,
  Grid,
  ListItem,
  TextField,
  Button,
  Box,
  List,
  AlertTitle,
  Alert,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { ChangeEvent, useContext, useState } from "react";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { CountryContext } from "../MainComponent";
import { RESET_PASSWORD_MUTATION } from "../../../operations/mutations/customer";
import "./UpdatePassword.scss";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import { Visibility, VisibilityOff } from "@mui/icons-material";

interface FormDataInterface {
  loginInformation: [
    {
      email: string;
      password: string;
      validated: boolean;
    }
  ];
}

const UpdatePassword = () => {
  const { store } = useContext(CountryContext);
  // eslint-disable-next-line
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [showMainPassword, setShowMainPassword] = useState(false);
  const [showConfPassword, setShowConfPassword] = useState(false);
  const [resError, setResError] = useState("");
  const [loading, setLoading] = useState(false); // Loader state
  const { t } = useTranslation();
  const navigate = useNavigate();
  const storePath = window.location.pathname;
  const selectedStores = storePath?.split("/")?.[1];

  const initialFormData: FormDataInterface = {
    loginInformation: [
      {
        email: "",
        password: "",
        validated: false,
      },
    ],
  };
  const [formData, setFormData] = useState<FormDataInterface>(initialFormData);

  let [passwordResetRequest] = useMutation(RESET_PASSWORD_MUTATION, {
    context: {
      headers: {
        Connection: "keep-alive",
        store: store,
      },
    },
    onCompleted: (response) => {
      setShowErrorAlert(false);
      setLoading(false);
      toast.success(t("auth.updatePassword.pwd_update_toast"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setFormData(initialFormData);
      navigate(`/${selectedStores}/login`);
    },
    onError: (error) => {
      setShowErrorAlert(true);
      setResError(t("auth.updatePassword.pwd_update_err_toast"));
      setLoading(false);
    },
  });

  const isStrongPassword = (password: any) => {
    const strongPasswordRegex =
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=]).{8,}$/;
    const response = strongPasswordRegex.test(password);
    formData.loginInformation[0].validated = response;
    return response;
  };

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    section: keyof FormDataInterface
  ) => {
    const { name, value } = e.target;
    setFormData((prevState: FormDataInterface) => ({
      ...prevState,
      [section]: [
        {
          ...prevState[section][0],
          [name]: value,
        },
      ],
    }));
    if (name === "password") {
      if (value.length >= 4) {
        if (!isStrongPassword(value)) {
          setPasswordError(t("auth.updatePassword.pwd_length_err"));
        } else {
          setPasswordError("");
        }
      } else {
        setPasswordError("");
      }
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowMainPassword((prev) => !prev);
  };

  const handleToggleConfPasswordVisibility = () => {
    setShowConfPassword((prev) => !prev);
  };

  const matchPassword = (e: any) => {
    if (formData.loginInformation[0].password === e.target.value) {
      setPasswordMatch(true);
    } else {
      setPasswordMatch(false);
      formData.loginInformation[0].validated = false;
    }
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (formData.loginInformation[0].validated) {
        const token = window.location.search.split("?token=");
        const response = await passwordResetRequest({
          variables: {
            email: formData.loginInformation[0].email,
            resetPasswordToken: token[1],
            newPassword: formData.loginInformation[0].password,
          },
        });
        if (response?.data) {
          setShowErrorAlert(false);
        }
      } else {
        setShowErrorAlert(true);
        setResError("Invalid credentials");
        toast.error("Invalid credentials", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      setShowErrorAlert(true);
      setResError("Invalid credentials");
      toast.error("Invalid credentials", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <>
      <div className="customer-acc-main directionrtl">
        <Typography variant="h1">{t("auth.updatePassword.title")}</Typography>
        {/* {loading && <div className="loader">Loading...</div>}  */}
        <form onSubmit={handleSubmit}>
          <Box
            sx={{
              border: "1px solid #DEDCDC",
              backgroundColor: "#FFFFFF",
              borderRadius: "5px",
            }}
            className="main-box"
          >
            <List>
              <ListItem>
                <Typography className="title">
                  {t("auth.updatePassword.title")}
                </Typography>
              </ListItem>
              <Divider />

              {showErrorAlert && (
                <Alert severity="error" sx={{ m: "10px 10px" }}>
                  <AlertTitle>Error</AlertTitle>
                  {resError || "An error occurred. Please try again."}
                </Alert>
              )}

              <Grid
                className="company-upper"
                container
                spacing={2}
                sx={{ padding: "30px" }}
              >
                <Grid item xs={12} md={12} lg={12}>
                  <label>{t("auth.updatePassword.email")}</label>
                  <TextField
                    placeholder={t("auth.updatePassword.email")}
                    name="email"
                    variant="outlined"
                    className="input-field"
                    type="text"
                    onChange={(e) => handleChange(e, "loginInformation")}
                    value={formData.loginInformation[0].email} // Set value from state
                  />
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <label>{t("auth.updatePassword.pwd")}</label>
                  <TextField
                    placeholder={t("auth.updatePassword.pwd")}
                    name="password"
                    variant="outlined"
                    className="input-field"
                    type={showMainPassword ? "text" : "password"}
                    onChange={(e) => handleChange(e, "loginInformation")}
                    value={formData.loginInformation[0].password}
                    InputProps={{
                      endAdornment: (
                        <IconButton onClick={handleTogglePasswordVisibility}>
                          {showMainPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      ),
                    }}
                  />
                  {passwordError && (
                    <Typography
                      variant="body2"
                      color="error"
                      sx={{ mt: 1, textAlign: "start", fontSize: "14px" }}
                    >
                      {passwordError}
                    </Typography>
                  )}
                  {/* <Typography className="password-msg">
                    {t("auth.updatePassword.strength")}:{" "}
                    {formData.loginInformation[0].password.length === 0 ? (
                      <span>{t("auth.registration.no_pwd")}</span>
                    ) : isStrongPassword(
                        formData.loginInformation[0].password
                      ) ? (
                      <span style={{ color: "green" }}>
                        {t("auth.registration.strong_pwd")}
                      </span>
                    ) : (
                      <span style={{ color: "red" }}>
                        {t("auth.registration.weak_pwd")}
                      </span>
                    )}
                  </Typography> */}
                </Grid>
                <Grid item xs={12} md={12} lg={12}>
                  <label>{t("auth.updatePassword.cpwd")}</label>
                  <TextField
                    placeholder={t("auth.updatePassword.cpwd")}
                    name="confirm_password"
                    variant="outlined"
                    className="input-field"
                    type={showConfPassword ? "text" : "password"}
                    onChange={matchPassword}
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          onClick={handleToggleConfPasswordVisibility}
                        >
                          {showConfPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      ),
                    }}
                  />
                  <Typography className="password-msg">
                    {!isStrongPassword(
                      formData.loginInformation[0].password
                    ) ? (
                      <span style={{ color: "red" }}>
                        {t("auth.registration.weak_pwd")}
                      </span>
                    ) : isStrongPassword(
                        formData.loginInformation[0].password
                      ) && passwordMatch ? (
                      <span style={{ color: "green" }}>
                        {t("auth.updatePassword.pwd_match")}
                      </span>
                    ) : (
                      <span style={{ color: "red" }}>
                        {t("auth.updatePassword.pwd_not_match")}
                      </span>
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ maxWidth: "100% !important" }}>
                  <Box sx={{ width: "100%", marginTop: "30px" }}>
                    <Button
                      className="create-btn"
                      variant="contained"
                      type="submit"
                      disabled={loading}
                      sx={{
                        my: 2,
                        textTransform: "capitalize",
                        color: "#fff",
                        fontSize: "18px",
                        fontWeight: "500",
                        padding: "6px 34px",
                      }}
                    >
                      {loading ? (
                        <CircularProgress size={32} sx={{ color: "#fff" }} />
                      ) : (
                        t("auth.updatePassword.update_pwd_btn")
                      )}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </List>
          </Box>
        </form>
      </div>
    </>
  );
};

export default UpdatePassword;
