import "./loadingContext.css"

const GlobalLoader = (props:any) => {
  return (
    <>
      {props.type === "isSmallLoader" ? (
        <div>
          <video
            src={require('../../../NewAssets/image/small-loader-img.mp4')}
            autoPlay
            loop
            muted
            className="small-loader-video"
            width="100%"
            height="100%"
          />
        </div>
      ) : (
        <div className='overlay' style={{ height: props.type === "Home" ? "100vh" : "8vh" }}>
          <video
            src={require('../../../NewAssets/image/loader-img.mp4')}
            autoPlay
            loop
            muted
            className="custom_loader"
          />
        </div>
      )}
    </>
  );
};

export default GlobalLoader;