import React, { useContext } from 'react';
import { Container, Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import logo from "../../assets/images/logo.png";
import notFound from "../../assets/images/notFound.png";
import logoArabic from "../../assets/images/logoArabic.png";
import { useTranslation } from 'react-i18next';
import { CountryContext } from '../../container/buyer/MainComponent';

const NotFoundPage: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {store} =
    useContext(CountryContext);

  const handleBackToHome = () => {
    navigate('/');
  };

  return (
    <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
    >
    <Container
      maxWidth="md"
      sx={{
        border: '1.13px solid #DBD7D7',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        // minHeight: '100vh',
        textAlign: 'center',
        backgroundColor:'#fbfdfe',
        padding: { xs: 2, md: 4 },
        margin: { xs: '30px 20px', md: '50px 20px' },
      }}
    >
      <Box mb={2}>
        <img src={(store === "ae_ar" || store === "ar" || store === "sa_ar") ? logoArabic : logo} alt="Logo"/> {/* Update path to your logo */}
      </Box>
      <Box mb={1}>
        <img src={notFound} alt="Logo"/> {/* Update path to your logo */}
      </Box>
      <Typography
        variant="h5"
        sx={{
          marginBottom:'13px',
          fontWeight: 'bold',
          fontSize: { xs: '18px', md: '41px' },
        }}
      >
        {t("main.404")}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          color:'#434242',
          fontWeight: '500',
          fontSize: { xs: '14px', md: '21px' },
          maxWidth: { xs: '90%', md: '80%' },
          mb: 5,
        }}
      >
        {t("main.not_found_disc")}
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={handleBackToHome}
        sx={{
          color:'#000000',
          fontWeight: '500',
          padding: { xs: '6px 14px', md: '6px 20px' },
          fontSize: { xs: '14px', md: '18px' },
        }}
      >
        {t("main.back_to_home")}
      </Button>
    </Container>
    </Box>
  );
};

export default NotFoundPage;
