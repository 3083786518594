import React from 'react';
import './MyOrder.scss';
import noOrderImg from '../../../../../assets/images/no_orders.png'
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const MyOrder = () => {
  const navigate=useNavigate()
  const { t } = useTranslation();
  const storePath = window.location.pathname;
  const selectedStores = storePath?.split("/")?.[1];

  const closePage = () => {
    navigate(`/${selectedStores}`)
  };
  return (
    <>
        <div className="no-order">
          {/* eslint-disable-next-line */}
            <img src={noOrderImg} alt="Image" />

            <h4>{t("buyer_dashboard.myOrderList.no_order_title")}</h4>

            <p>{t("buyer_dashboard.myOrderList.no_order_desc")}</p>

            <button onClick={() => closePage()}>{t("buyer_dashboard.myOrderList.continue_shopping")}</button>
        </div>
    </>
  )
}

export default MyOrder
