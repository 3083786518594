import React, { useEffect, useState, useContext } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, TextField, Typography } from '@mui/material';
import { Grid } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import Rating from '@mui/material/Rating';
import { useTranslation } from "react-i18next";
// import { CountryContext } from "../../MainComponent";
// import './AddReviewModal.scss';
import { CountryContext } from '../../../../MainComponent';
import * as mutations from "../../../../../../operations/mutations";
import { useMutation } from "@apollo/client";
import { useParams } from 'react-router-dom';

interface AddReviewModalProps {
    open: boolean;
    onClose: () => void;
    onSubmit: (reviewData: any) => void;
    detailsData: any;
    ratingMetadata: { value: string; value_id: string }[];
    // avgRating: number;
    ratingMetaDataId: string;
    title: string,
}

function getLabelText(value: number) {
    return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
}


const labels: { [index: string]: string } = {
    1: 'Useless+',
    2: 'Poor+',
    3: 'Ok+',
    4: 'Good+',
    5: 'Excellent+',
};

interface ReviewDataInterface {
    description: string;
    title: string;
    name: string;
}

const initialFormValues: ReviewDataInterface = {
    description: '',
    title: '',
    name: ''
};

const AddReviewModal: React.FC<AddReviewModalProps> = ({ open, onClose, onSubmit, detailsData, ratingMetadata, ratingMetaDataId ,title}) => {
    const { t } = useTranslation();
    const { store } = useContext(CountryContext);
    const [value, setValue] = useState<number | null>(3);
    const [hover, setHover] = useState(-1);
    const [ratingValue, setRatingValue] = useState<string>("")
    const loggedInToken = localStorage.getItem("loggedInCust");
    const urlParams = new URLSearchParams(window.location.search);
  const idFromUrl = urlParams.get("id")
  const { orderNumber } = useParams();
    const [formData, setFormData] = useState<ReviewDataInterface>(initialFormValues);
    const handleCLose = () => {
        onClose && onClose()
    }

    const [addRatingMutation] = useMutation(
        mutations.createProductReview.ADD_CUSTOM_RATING,
        {
          context: {
            headers: {
              Authorization: loggedInToken
                ? `Bearer ${JSON.parse(loggedInToken)}`
                : "",
              Connection: "keep-alive",
              store: store,
            },
          },
        },
      );
    

      const handleSubmit = async () => {
      
        if (title === 'REVIEW PRODUCT') {
          const reviewData = {
            sku: detailsData?.product_name,
            nickname: formData?.name,
            summary: formData?.description,
            text: formData?.title,
            ratings: [
              {
                id: ratingMetaDataId,
                value_id: ratingValue,
              },
            ],
          };
          setFormData(initialFormValues);
          onSubmit(reviewData);
        } else {
            
          try {
            //eslint-disable-next-line
            const response = await addRatingMutation({
              variables: {
                types: title, 
                rating: value,
                description: formData?.description,
                title: formData?.title,
                name: formData?.name,
                order_id:orderNumber, 
                order_item_id:idFromUrl,
              },
            });
            setFormData(initialFormValues);
            handleCLose(); 
          } catch (err) {
            console.error('Error submitting review:', err);
          }
        }
      };
      ;
    useEffect(() => {
        if (value !== null) {

            const selectedRatingMetadata = ratingMetadata.find(metadata => metadata.value === String(value));
            if (selectedRatingMetadata) {
                setRatingValue(selectedRatingMetadata.value_id)
            }
        }
    }, [value, ratingMetadata]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

   

    return (
      <React.Fragment>
        <Dialog
          open={open}
          onClose={handleCLose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          className="reviews-modal"
          style={{
            direction:
              store === "ae_ar" || store === "sa_ar" || store === "ar"
                ? "rtl"
                : "ltr",
            textAlign:
              store === "ae_ar" || store === "sa_ar" || store === "ar"
                ? "right"
                : "left",
          }}
        >
          <DialogTitle
            id="alert-dialog-title"
            className="review-title"
            sx={{
              textAlign: "center",
              fontSize: "24px",
              fontWeight: 600,
            }}
          >
            {title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <Box
                sx={{ borderBottom: "1px solid #EEEBEB", marginBottom: "20px" }}
              >
                {title === "REVIEW PRODUCT" && (
                  <Box
                    sx={{
                      display: "flex",
                      "@media (max-width:320px)": {
                        flexWrap: "wrap",
                      },
                    }}
                  >
                    <div className="product-image">
                      <img
                        src={detailsData?.product_image}
                        alt={detailsData?.product_name}
                      />
                    </div>
                    <div className="product-details">
                      <Typography variant="h5">
                        {detailsData?.product_name}
                      </Typography>
                      {/* <div className="rating-star-tag">
                                        <span className={`user-rating-star ${ratingClassName}`}>
                                            {detailsData?.reviews?.items.length ? avgRating : 0}
                                            <StarIcon />
                                        </span>
                                        <span className="rating-tag">({detailsData.rating_summary})</span>
                                    </div> */}
                    </div>
                  </Box>
                )}
                <Box
                  sx={{
                    width: 200,
                    display: "flex",
                    alignItems: "center",
                    marginTop: "20px",
                    marginBottom: "30px",
                    "@media (max-width:600px)": {
                      marginBottom: "15px",
                    },
                  }}
                >
                  <Rating
                    name="hover-feedback"
                    value={value}
                    precision={1}
                    getLabelText={getLabelText}
                    onChange={(event, newValue) => {
                      setValue(newValue);
                    }}
                    onChangeActive={(event, newHover) => {
                      setHover(newHover);
                    }}
                    style={{ color: "#439840" }}
                    emptyIcon={
                      <StarIcon
                        style={{ color: "#DDDADA" }}
                        fontSize="inherit"
                      />
                    }
                  />
                  {value !== null && (
                    <Box
                      sx={{
                        ml: 2,
                        color: "#439840",
                        fontSize: "14px",
                        fontWeight: "600",
                        fontFamily: "poppins",
                        lineHeight: "21px",
                      }}
                    >
                      {labels[hover !== -1 ? hover : value]}
                    </Box>
                  )}
                </Box>
              </Box>
              <Box>
                <Grid container spacing={2}>
                {title === "REVIEW PRODUCT" && <Grid item xs={12} className="form-title">
                    <Typography variant="h5">
                      {t("inner_pages.detail_page.review-this-product")}
                    </Typography>
                  </Grid> }
                  <Grid item xs={12} className="input-field-wrap">
                    <label>{t("inner_pages.detail_page.description")}</label>
                    <TextField
                      placeholder={t(
                        "inner_pages.detail_page.review-description"
                      )}
                      required
                      fullWidth
                      name="description"
                      value={formData.description}
                      onChange={handleChange}
                      autoComplete="off"
                      multiline
                      rows={5}
                    />
                  </Grid>
                  <Grid item xs={12} className="input-field-wrap">
                    <label>{t("inner_pages.detail_page.title")}</label>
                    <TextField
                      placeholder={t("inner_pages.detail_page.review-titile")}
                      required
                      fullWidth
                      id="title"
                      name="title"
                      autoComplete="off"
                      value={formData.title}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} className="input-field-wrap">
                    <label>{t("inner_pages.detail_page.name")}</label>
                    <TextField
                      placeholder={t("inner_pages.detail_page.name")}
                      required
                      fullWidth
                      id="name"
                      name="name"
                      autoComplete="off"
                      value={formData.name}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item lg={3} md={4} xs={12} className="input-field-wrap">
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "#00B4D7",
                        width: "100%",
                        boxShadow: "unset",
                      }}
                      onClick={handleSubmit}
                      disabled={
                        !formData.description ||
                        !formData.title ||
                        !formData.name
                      }
                    >
                      <Typography
                        color="white"
                        style={{ textTransform: "capitalize" }}
                      >
                        {t("inner_pages.detail_page.submit")}
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
}

export default AddReviewModal