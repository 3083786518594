import React, { useEffect, useState, useContext, useMemo } from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Box from "@mui/material/Box";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CountryContext } from "../../container/buyer/MainComponent";
// import ReactStars from "react-rating-star-with-type";
import Skeleton from "@mui/material/Skeleton";
import CartModal from "./cartmodal/CartModal";
import ProductCarouselCard from "../productCarosuelCard/ProductCarouselCard";

const top_seller_responsive: any = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 1200 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 1200, min: 900 },
    items: 4,
    slidesToSlide: 1,
  },
  laptop: {
    breakpoint: { max: 900, min: 600 },
    items: 3,
    slidesToSlide: 1,
  },
  tablet: {
    breakpoint: { max: 600, min: 0 },
    items: 2,
    slidesToSlide: 2,
  },
  mobile: {
    breakpoint: { max: 600, min: 0 },
    items: 2,
    slidesToSlide: 1,
  },
};

interface carouselProps {
  title: any;
  iscountry: any;
  // isLoadingCarousel: (data: boolean) => void;
  productData: any;
}

function CarouselSlider(props: carouselProps) {
  const { country, store } = useContext(CountryContext);
  const [selectedStore, setSelectedStore] = useState("");
  const [isCartModalOpen, setCartModalOpen] = useState(false);
  const [modalUrlKey, setModalUrlKey] = useState("");
  const [productDataIs, setProductDataIs] = useState("");
  const skeletonArr = Array.from({ length: 10 });
  let productData: any;
  const productdata = props.productData?.data;

  // productData = productdata?.products?.items;
  productData = productdata?.products?.items;

  const updatedProductData = useMemo(() => {
    return productData?.map((product: any) => {
      const reviewsArr = product?.reviews?.items || [];
      const ratingsOutOf5 = reviewsArr.map(
        (review: any) => (review?.average_rating ?? 0) / 20
      );
      const averageRating =
        ratingsOutOf5.length > 0
          ? ratingsOutOf5.reduce(
              (total: any, rating: any) => total + rating,
              0
            ) / ratingsOutOf5.length
          : 0;
      return {
        ...product,
        finalAverageRating: Math.round(averageRating * 10) / 10,
      };
    });
  }, [productData]);

  const { t } = useTranslation();
  useEffect(() => {
    if (store && store !== selectedStore) {
      setSelectedStore(store);
      // refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store, country, props?.iscountry]);

  return (
    <>
      {updatedProductData === undefined ? (
        <Box sx={{ pt: { lg: 5, md: 4, xs: 3 } }}>
          <Grid container>
            <Grid item xs={12} md={12}>
              <Card
                sx={{ maxWidth: "100%", boxShadow: 0 }}
                className="top-seller-grid jeb-cust-slide"
              >
                <CardContent sx={{ backgroundColor: "#fafafa" }}>
                  <Carousel
                    className="top-seller-grid-slider"
                    responsive={top_seller_responsive}
                    swipeable={true}
                    autoPlay={false}
                    centerMode={false}
                    arrows={true}
                    infinite={true}
                    ssr={true}
                    showDots={false}
                    slidesToSlide={1}
                    deviceType={""}
                  >
                    {skeletonArr?.map((ele, index) => {
                      return (
                        <Card
                          key={index}
                          sx={{
                            maxWidth: "100%",
                            boxShadow: 0,
                            border: "1px solid #e1e1e1",
                            margin: {
                              xs: "0px 5px",
                              md: "0px 6px",
                              lg: "0px 8px",
                              xl: "0px 10px",
                            },
                          }}
                        >
                          <div key={`skeleton-${index}`}>
                            <div className="slider-img-box">
                              <Skeleton
                                variant="rectangular"
                                sx={{
                                  width: "100%",
                                  height: {
                                    xs: "230px",
                                    sm: "300px",
                                    md: "300px",
                                    lg: "300px",
                                  },
                                }}
                              />
                            </div>
                          </div>
                        </Card>
                      );
                    })}
                  </Carousel>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      ) : updatedProductData && updatedProductData?.length > 0 ? (
        <Box sx={{ pt: { lg: 5, md: 4, xs: 3 } }}>
          <Grid container>
            <Grid item xs={12} md={12}>
              <Card
                sx={{ maxWidth: "100%", boxShadow: 0 }}
                className="top-seller-grid jeb-cust-slide"
              >
                <div className="section-title">
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="span"
                    color="text.dark"
                  >
                    {productdata?.products.category_name}
                  </Typography>
                  <Link
                    to={`/${store}/category/${productdata?.products.url_key}.html`}
                    rel="preload"
                  >
                    <Button
                      className="view-all-brand-btn"
                      sx={{ mr: 2 }}
                      variant="outlined"
                    >
                      {t("main.view-all-prod")}
                    </Button>
                  </Link>
                </div>
                <CardContent sx={{ backgroundColor: "#fafafa" }}>
                  <Carousel
                    className="top-seller-grid-slider"
                    responsive={top_seller_responsive}
                    swipeable={true}
                    autoPlay={false}
                    centerMode={false}
                    arrows={true}
                    infinite={true}
                    ssr={true}
                    showDots={false}
                    slidesToSlide={1}
                    deviceType={""}
                  >
                    {updatedProductData?.map((productdata: any, index: any) => {
                      return (
                        <ProductCarouselCard
                          productdata={productdata}
                          setCartModalOpen={setCartModalOpen}
                          setModalUrlKey={setModalUrlKey}
                          setProductDataIs={setProductDataIs}
                        />
                      );
                    })}
                  </Carousel>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          {isCartModalOpen && (
            <CartModal
              open={isCartModalOpen}
              setCartModalOpen={setCartModalOpen}
              urlkey={modalUrlKey}
              productdata={productDataIs}
            />
          )}          
        </Box>
      ) : (
        ""
      )}
    </>
  );
}

export default React.memo(CarouselSlider);
