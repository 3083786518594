import React, { useContext, useEffect, useState } from "react";
import "./MyOrderList.scss";
import {
  FormControl,
  OutlinedInput,
  MenuItem,
  InputAdornment,
  Select,
  Pagination,
  Stack,
  Typography,
  Box,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import * as queries from "../../../../operations/queries";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import itemImg from "../../../../assets/images/order-items-list.png";
import MyOrder from "../Orders/emptyOrder/MyOrder";
import { CountryContext } from "../../MainComponent";
import Loader from "../loader/Loader";

const MyOrderList = () => {
  const [dateRange, setDateRange] = useState("3months");
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const { t } = useTranslation();
  const { store } = useContext(CountryContext);
  const loggedInToken = localStorage.getItem("loggedInCust");
  const navigate = useNavigate();

  const array = [
    { value: 10, label: "10" },
    { value: 15, label: "15" },
    { value: 20, label: "20" },
    { value: 30, label: "30" },
  ];

  const formatDate = (date: any) => {
    const year = date?.getFullYear();
    const month = String(date?.getMonth() + 1).padStart(2, "0");
    const day = String(date?.getDate()).padStart(2, "0");
    const hours = String(date?.getHours()).padStart(2, "0");
    const minutes = String(date?.getMinutes()).padStart(2, "0");
    const seconds = String(date?.getSeconds()).padStart(2, "0");
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const handleDateRangeChange = (event: any) => {
    setDateRange(event.target.value);
    setCurrentPage(1);
  };

  const getDateRange = (range: any) => {
    const now = new Date();
    let fromDate = new Date(now);

    switch (range) {
      case "3months":
        fromDate.setMonth(now.getMonth() - 3);
        break;
      case "6months":
        fromDate.setMonth(now.getMonth() - 6);
        break;
      case "all":
        fromDate = new Date("1970-01-01");
        break;
      default:
        fromDate = new Date("2022-01-01");
    }
    return { date_from: formatDate(fromDate), date_to: formatDate(now) };
  };

  const { date_from, date_to } = React.useMemo(
    () => getDateRange(dateRange),
    //eslint-disable-next-line
    [dateRange]
  );

  const {
    data: custOrderListres,
    refetch: refetchOrderList,
    loading,
  } = useQuery(queries.customerQueries.GET_CUSTOMER_ORDERS, {
    variables: {
      date_from,
      date_to,
      pageSize: 300,
      currentPage: 1,
    },
    context: {
      headers: {
        Authorization: loggedInToken
          ? `Bearer ${JSON.parse(loggedInToken)}`
          : "",
        Connection: "keep-alive",
        store: store,
      },
    },
  });

  const totalItems =
    custOrderListres?.customerOrdersList?.[0]?.items?.length || 0;
  const totalPages = Math.ceil(totalItems / pageSize);

  const orderItemsIs = custOrderListres?.customerOrdersList?.[0]?.items;
  const paginatedOrderItems = Array.isArray(orderItemsIs)
    ? [...orderItemsIs]
        .sort((a: any, b: any) => {
          const dateA = a.date ? new Date(a.date).getTime() : 0;
          const dateB = b.date ? new Date(b.date).getTime() : 0;
          return dateB - dateA;
        })
        .filter(
          (item: any) =>
            item?.product_name
              ?.toLowerCase()
              .includes(searchTerm.toLowerCase()) ||
            item?.order_number?.toLowerCase().includes(searchTerm.toLowerCase())
        )
        .slice((currentPage - 1) * pageSize, currentPage * pageSize)
    : [];

  const handlePageChange = (event: any, value: number) => {
    setCurrentPage(value);
  };

  const handlePageSizeChange = (event: any) => {
    setPageSize(event.target.value);
    setCurrentPage(1);
  };

  const handleSearchChange = (event: any) => {
    const { value } = event.target;
    setSearchTerm(value);
  };

  const handleCardClick = (orderNumber: any, id: any) => {
    const orderLink = `tracking/${orderNumber}?id=${id}`;
    navigate(orderLink);
  };

  const handleSummary = (orderNumber: any, id: any) => {
    const orderLink = `order/${orderNumber}?id=${id}`;
    navigate(orderLink);
  };

  // const handlOrderClaim = (orderNumber: any, id: any) => {
  //   const currentPath = window.location.pathname;
  //   const basePath = currentPath.split("/my-order-list")[0];
  //   const orderLink = `${basePath}/claims/${orderNumber}?id=${id}&props=add`;
  //   navigate(orderLink);
  // };

  useEffect(() => {
    refetchOrderList();
  }, [custOrderListres, refetchOrderList]);

  return (
    <>
      <div className="order-list-header directionrtl">
        <div className="order-name">
          <h4>{t("buyer_dashboard.myOrderList.order_title")}</h4>
          <p>{t("buyer_dashboard.myOrderList.Desc")}</p>
        </div>
        <div className="order-search">
          <FormControl fullWidth variant="outlined">
            <OutlinedInput
              value={searchTerm}
              onChange={handleSearchChange}
              endAdornment={
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              }
              placeholder={t("buyer_dashboard.myOrderList.find_item")}
            />
          </FormControl>
        </div>
        <div className="order-dropdown">
          <FormControl sx={{ m: 1, minWidth: 120 }}>
            <Select
              value={dateRange}
              onChange={handleDateRangeChange}
              displayEmpty
            >
              <MenuItem value="3months">
                {t("buyer_dashboard.myOrderList.last_3_months")}
              </MenuItem>
              <MenuItem value="6months">
                {t("buyer_dashboard.myOrderList.last_6_months")}
              </MenuItem>
              <MenuItem value="all">
                {t("buyer_dashboard.myOrderList.All_order")}
              </MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>

      <div className="order-list-items">
        {/* <div className="title">
          <h4>{t("buyer_dashboard.myOrderList.complete")}</h4>
        </div> */}
        {loading ? (
          <Loader />
        ) : (
          <>
            {paginatedOrderItems?.length > 0 ? (
              paginatedOrderItems?.map((item: any, index: any) => (
                <div key={index} className="card-items">
                  <div className="card-delivery-time-number">
                    <div className="product-order-id">
                      <p>{item?.order_number || "Unknown"}</p>
                    </div>

                    <div className="card-delivery-time">
                      <p>
                        <span>{item?.status}</span> on{" "}
                        {new Date(item?.date).toLocaleString("en-GB", {
                          weekday: "long",
                          day: "numeric",
                          month: "short",
                        })}
                        ,{" "}
                        {new Date(item?.date).toLocaleString("en-GB", {
                          year: "numeric",
                        })}
                      </p>
                    </div>
                  </div>

                  <div className="product-details">
                    <div className="product-img-name">
                      <div className="product-image">
                        <img
                          src={item?.product_image || itemImg}
                          alt="Product"
                        />
                      </div>
                      <div className="product-name">
                        <h5>{item?.product_name || "Product Name"}</h5>
                      </div>
                    </div>

                    <div className="product-btn-grp">
                      <p>{t("buyer_dashboard.myOrderList.shoping_exp")}</p>
                      <div>
                        <button>
                          {t("buyer_dashboard.myOrderList.seller")}
                        </button>
                        <button
                          onClick={() =>
                            handleSummary(item?.order_number, item?.item_id)
                          }
                        >
                          {t("buyer_dashboard.myOrderList.product")}
                        </button>
                        <button
                          onClick={() =>
                            handleCardClick(item?.order_number, item?.item_id)
                          }
                        >
                          {t("buyer_dashboard.myOrderList.delivery")}
                        </button>

                        {/* <button
                        onClick={() =>
                          handlOrderClaim(item?.order_number, item?.item_id)
                        } 
                        >
                          ADD A CLAIM
                        </button> */}
                      </div>
                    </div>

                    <div className="product-more-details">
                      <button
                        onClick={() =>
                          handleCardClick(item?.order_number, item?.item_id)
                        }
                      >
                        <ArrowForwardIosIcon />
                      </button>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <MyOrder />
            )}
          </>
        )}
      </div>

      {totalItems > 9 && (
        <Box className="deskPagination">
          <Stack className="pagination-controls">
            <Typography className="items-label">
              {t("buyer_dashboard.myOrderList.Item_per_page")}:
            </Typography>
            <Select
              value={pageSize}
              onChange={handlePageSizeChange}
              variant="outlined"
              size="small"
              className="items-select"
            >
              {array.map((size: any) => (
                <MenuItem key={size.value} value={size.value}>
                  {size.label}
                </MenuItem>
              ))}
            </Select>
          </Stack>

          <Pagination
            count={totalPages}
            shape="rounded"
            page={currentPage}
            onChange={handlePageChange}
            className="pagination-controls"
          />
        </Box>
      )}
    </>
  );
};

export default MyOrderList;
