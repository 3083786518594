import React, { useContext } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  Box,
  Skeleton,
} from "@mui/material";
import { useQuery } from "@apollo/client";
import * as queries from "../../../operations/queries";
import { CountryContext } from "../MainComponent";
import { useTranslation } from "react-i18next";

interface Category {
  name: string;
  url_path: string;
  image: string;
  link: string;
  url_key: string;
}

const BottomNavbarAllcategories: React.FC = () => {
  const { store } = useContext(CountryContext);
  const { t } = useTranslation();

  const customHeader = {
    store: store,
    Connection: "keep-alive",
  };

  const { loading: catLoader, data: catRes } = useQuery(
    queries.homeQueries.GET_CATEGORY_SLIDER,
    {
      variables: { categoryId: "2" },
      context: {
        headers: customHeader,
      },
    }
  );

  const catData: Category[] | undefined = catRes?.getSubCategoriesData;

  if (catLoader) {
    return (
      <Box sx={{ padding: 2 }}>
      <h2 style={{ marginLeft: "20px", fontSize: "19px" }}>All Categories</h2>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          gap: 3,
        }}
      >
        {Array.from({ length: 6 })?.map((_, index) => (
          <Card
            key={index}
            sx={{
              width: 135, 
              height: 200, 
              padding: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Skeleton
              variant="circular"
              width={80}
              height={80}
              sx={{ marginBottom: 1 }}
            />
            <Skeleton variant="text" width="70%" height={20} />
            <Skeleton variant="text" width="50%" height={15} />
          </Card>
        ))}
      </Box>
    </Box>
    );
  }

  return (
    <Box sx={{ padding: 2 }}>
      <h2 style={{ marginLeft: "20px", fontSize: "19px" }}>
        {" "}
        {t("main.all-categories")}
      </h2>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          gap: 3,
        }}
      >
        {catData?.map((category) => (
          <Card
            key={category.url_path}
            sx={{
              maxWidth: { xs: 135, sm: 120 },
              flex: "1 1 auto",
              margin: 0.5,
              padding: 1,
              marginTop: "10px",
              maxHeight: "200px", 
            }}
          >
            <Link to={`/${store}/category/${category.url_key}`}>
              <CardMedia
                component="img"
                alt={category.name}
                height="80" 
                width="80" 
                image={category.image}
                sx={{ objectFit: "cover" }}
              />
              <Typography
                variant="subtitle1"
                textAlign={"center"}
                sx={{ color: "black", marginTop: 0.5 }}
              >
                {category.name}
              </Typography>
            </Link>
            <CardContent />
          </Card>
        ))}
      </Box>
    </Box>
  );
};

export default BottomNavbarAllcategories;
