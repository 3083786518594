import React, { useContext, useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import "./ReturnItems.scss";
import NoReturn from "./noReturnRequests/NoReturn";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQuery } from "@apollo/client";
import * as queries from "../../../../operations/queries";
import { CountryContext } from "../../MainComponent";

const ReturnItems = () => {
  //eslint-disable-next-line
  const [loading, setLoading] = useState<boolean>(false);
  const { store } = useContext(CountryContext);
  const [returnRequestList, setReturnRequestList] = useState<any>(null);
  const loggedInToken = localStorage.getItem("loggedInCust");
  const { t } = useTranslation();

   //eslint-disable-next-line
  const { data, refetch: refetchListData } = useQuery(
    queries.returnQueries.GET_RETURN_REQUESTS,
    {
      variables: { type: "list" },
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
          store: store,
        },
      },
      onCompleted: (data) => {
        setReturnRequestList(data?.getReturnRequests || []);
        setLoading(false);
      },
      onError: () => {
        setLoading(false);
      },
    }
  );

  useEffect(() => {
    if (data) {
      refetchListData();
    }
  }, [data, refetchListData]);

  const filteredReturnRequests = returnRequestList?.filter(
    (item: any) => item.status === "0"
  );
  const returnRequestsWithPendingStatus = filteredReturnRequests?.map(
    (item: any) => ({
      ...item,
      status: "Pending",
    })
  );

  return (
    <div className="return-items">
      <div className="heading-sec">
        <div className="name-sec">
          <h2 className="title">{t("buyer_dashboard.return.title")}</h2>
          <p className="subtitle">{t("buyer_dashboard.return.return_desc")}</p>
        </div>

        {returnRequestsWithPendingStatus?.length !== 0 && (
          <div className="btn-sec">
            <Link to={`return-completed`}>
              <button style={{ cursor: "pointer" }}>{t("buyer_dashboard.return.return_completed_btn")}</button>
            </Link>
          </div>
        )}
      </div>

      {returnRequestsWithPendingStatus?.length === 0 ? (
        <NoReturn />
      ) : (
        <>
          <div className="desktop-view">
            <Table sx={{ backgroundColor: "white" }}>
              <TableHead>
                <TableRow>
                  <TableCell className="header-cell">
                    {t("buyer_dashboard.return.product")}
                  </TableCell>
                  <TableCell className="header-cell">
                    {t("buyer_dashboard.return.order")}
                  </TableCell>
                  <TableCell className="header-cell">
                    {t("buyer_dashboard.return.product_name")}
                  </TableCell>
                  <TableCell className="header-cell">
                    {t("buyer_dashboard.return.date")}
                  </TableCell>
                  <TableCell className="header-cell">
                    {t("buyer_dashboard.return.price")}
                  </TableCell>
                  <TableCell className="header-cell">
                    {t("buyer_dashboard.return.status")}
                  </TableCell>
                </TableRow>
              </TableHead>
              {/* {loading ? (
                <div
                  style={{
                    display: "flex",
                    marginTop: "20px",
                    alignItems: "center",
                    textAlign:"center",
                    marginLeft:'50px'
                  }}
                >
                  <CircularProgress />
                </div>
              ) : ( */}
                <TableBody>
                  {returnRequestsWithPendingStatus?.map((item: any) => (
                    <TableRow key={item?.id}>
                      <TableCell className="body-cell">
                        {item?.product_image ? (
                          <img
                            src={item?.product_image}
                            alt="Product"
                            className="product-image"
                          />
                        ) : (
                          <span className="no-image">No Image</span>
                        )}
                      </TableCell>
                      <TableCell className="body-cell">
                        {item.order_number}
                      </TableCell>
                      <TableCell className="body-cell">
                        {item.product_name}
                      </TableCell>
                      <TableCell className="body-cell">
                        {item.refund_date}
                      </TableCell>
                      <TableCell className="body-cell">{(Number(item?.refundable_amount) || 0).toFixed(2)}</TableCell>
                      <TableCell className="body-cell">
                        <span className={`status ${item.status.toLowerCase()}`}>
                          {item.status}
                        </span>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              {/* )} */}
            </Table>
          </div>

          <div className="mobile-view">
            {returnRequestsWithPendingStatus?.map((item: any) => (
              <div className="table-container" key={item.id}>
                <Table sx={{ backgroundColor: "white" }}>
                  <TableBody>
                    <TableRow>
                      <TableCell className="header">Product</TableCell>
                      <TableCell>
                        {item.product_image ? (
                          <img
                            src={item.product_image}
                            alt="Product"
                            className="product-image"
                          />
                        ) : (
                          <span className="no-image">No Image</span>
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="header">
                        {t("buyer_dashboard.return.order")}
                      </TableCell>
                      <TableCell>{item.order_number}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="header">
                        {t("buyer_dashboard.return.product_name")}
                      </TableCell>
                      <TableCell>{item.product_name}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="header">
                        {t("buyer_dashboard.return.date")}
                      </TableCell>
                      <TableCell>{item.refund_date}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="header">
                        {t("buyer_dashboard.return.price")}
                      </TableCell>
                      <TableCell>{item.refundable_amount}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell className="header">
                        {t("buyer_dashboard.return.status")}
                      </TableCell>
                      <TableCell>
                        <span className={`status ${item.status.toLowerCase()}`}>
                          {item.status}
                        </span>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default ReturnItems;
