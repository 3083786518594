import React, { useContext, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { Container } from "@material-ui/core";
import * as mutations from "../../../operations/mutations";
import { useMutation } from "@apollo/client";
import Snackbar from "@mui/material/Snackbar";
import { CircularProgress, IconButton, InputLabel } from "@mui/material";
import AlertTitle from "@mui/material/AlertTitle";
import Alert from "@mui/material/Alert";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CountryContext } from "../MainComponent";
import { Visibility, VisibilityOff } from "@mui/icons-material";

function Register() {
  const location = useLocation();
  const { t } = useTranslation();
  const { store } = useContext(CountryContext);
  const previousRouteParams = location.state;
  const [resError, setResError] = useState("");
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [registrationSuccess, setRegistrationSuccess] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [confPassword, setConfPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [passwordError, setPasswordError] = useState("");
  const [passwordMatchError, setPasswordMatchError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfPassword, setShowConfPassword] = useState(false);
  const storePath = window.location.pathname;
  const selectedStores = storePath?.split("/")?.[1];

  const handleConfPassChange = (event: any) => {
    const value = event.target.value;
    setConfPassword(value);

    if (value.length >= 4) {
      if (value !== formData.password) {
        setPasswordMatchError(t("auth.registration.pwd_match_err"));
      } else {
        setPasswordMatchError("");
      }
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  const handleToggleConfPasswordVisibility = () => {
    setShowConfPassword((prev) => !prev);
  };

  const navigate = useNavigate();
  useEffect(() => {
    window?.scrollTo(0, 0);
  }, []);
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    is_subscribed: false,
  });
  const handleChange = (event: any) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });

    if (name === "password") {
      if (value.length >= 4) {
        if (!isStrongPassword(value)) {
          setPasswordError(
            t("auth.registration.pwd_length_err")
          );
        } else {
          setPasswordError("");
        }
      } else {
        setPasswordError("");
      }
    }
  };
  const [createCustomer] = useMutation(
    mutations.loginMutation.CREATECUSTOMER_MUTATION,
    {
      context: {
        headers: {
          Connection: "keep-alive",
        },
      },
    }
  );

  let [generateCustomerToken, { data: generateCustData }] = useMutation(
    mutations.loginMutation.LOGIN_MUTATION,
    {
      context: {
        headers: {
          Connection: "keep-alive",
          store: store,
        },
      },
    }
  );
  const handleCreateCustomer = async (event: any) => {
    event.preventDefault();
    const data = formData;

    if (formData.password === confPassword) {
      setLoading(true);
      try {
        await createCustomer({
          variables: data,
        });
        setRegistrationSuccess(true);
        const LoginData = { email: data.email, password: data.password };
        const loginRes = await generateCustomerToken({
          variables: LoginData,
        });
        generateCustData = loginRes.data;
        const token = generateCustData?.generateCustomerToken?.token;
        if (token) {
          await localStorage.setItem("loggedInCust", JSON.stringify(token));
          navigate(`/${selectedStores}`);
        }
        setOpenSnack(true);
        setResError("");
        setShowErrorAlert(false);
        setFormData({
          firstname: "",
          lastname: "",
          email: "",
          password: "",
          is_subscribed: false,
        });
        setConfPassword("");
        // navigate("/");
      } catch (error: any) {
        setResError(error.message);
        setShowErrorAlert(true);
        setRegistrationSuccess(false);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleClose = (
    event: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnack(false);
  };
  const isStrongPassword = (password: any) => {
    const strongPasswordRegex =
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@#$%^&+=]).{8,}$/;
    return strongPasswordRegex.test(password);
  };

  return (
    <div className="login-wrapper directionrtl">
      {registrationSuccess ? (
        <Snackbar
          open={openSnack}
          autoHideDuration={6000}
          onClose={handleClose}
          //    message="SuccessFully Registerd"
          //    action={action}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}
          >
            {t("auth.registration.reg_success_msg")}
          </Alert>
        </Snackbar>
      ) : (
        ""
      )}
      <div className="reg-content-wrap">
        <Container component="main" maxWidth="md">
          <Box
            sx={{
              marginTop: 8,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Grid
              container
              spacing={{ xs: 0, md: 8, lg: 8 }}
              justifyContent="center"
            >
              <Grid item lg={10} md={10} xs={12}>
                <Card
                  sx={{
                    maxWidth: "100%",
                    boxShadow: "none",
                    border: "1px solid #E0DEDE",
                    mb: 3,
                    p: 2,
                  }}
                >
                  <CardContent>
                    <Typography
                      gutterBottom
                      component="h3"
                      variant="h5"
                      sx={{ fontWeight: 700, color: "#000" }}
                    >
                      {t("auth.registration.personal_info")}
                    </Typography>
                    {showErrorAlert && (
                      <Alert severity="error">
                        <AlertTitle>Error</AlertTitle>
                        {resError}
                      </Alert>
                    )}

                    <Box
                      component="form"
                      noValidate
                      onSubmit={handleCreateCustomer}
                      sx={{ mt: 3 }}
                      autoComplete="off"
                    >
                      <Grid
                        container
                        spacing={{ xs: 0, md: 2, lg: 2 }}
                        rowSpacing={{ xs: 2 }}
                      >
                        <Grid item xs={12}>
                          <InputLabel htmlFor="input-field" sx={{ mb: 1 }}>
                            {" "}
                            {t("auth.registration.first_name")}
                            <span style={{ color: "#EA0C0C" }}>*</span>
                          </InputLabel>
                          <TextField
                            // error
                            autoComplete="given-name"
                            name="firstname"
                            required
                            fullWidth
                            id="firstname"
                            placeholder="First Name"
                            value={formData.firstname}
                            autoFocus
                            onChange={handleChange}
                            // helperText="Incorrect entry."
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <InputLabel htmlFor="input-field" sx={{ mb: 1 }}>
                            {t("auth.registration.last_name")}
                            <span style={{ color: "#EA0C0C" }}>*</span>
                          </InputLabel>
                          <TextField
                            required
                            fullWidth
                            id="lastname"
                            placeholder="Last Name"
                            name="lastname"
                            autoComplete="off"
                            value={formData.lastname}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <InputLabel htmlFor="input-field" sx={{ mb: 1 }}>
                            {t("auth.registration.email_addr")}
                            <span style={{ color: "#EA0C0C" }}>*</span>
                          </InputLabel>
                          <TextField
                            autoComplete="off"
                            name="email"
                            required
                            fullWidth
                            id="email"
                            placeholder="Email Address"
                            value={formData.email}
                            onChange={handleChange}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <InputLabel>
                            {t("auth.registration.password")}
                          </InputLabel>
                          <TextField
                            required
                            fullWidth
                            name="password"
                            type={showPassword ? "text" : "password"}
                            placeholder="Password"
                            value={formData.password}
                            onChange={handleChange}
                            InputProps={{
                              endAdornment: (
                                <IconButton
                                  onClick={handleTogglePasswordVisibility}
                                >
                                  {showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              ),
                            }}
                          />
                          {passwordError && (
                            <Typography
                              variant="body2"
                              color="error"
                              sx={{ mt: 1 ,fontSize:"14px"}}
                            >
                              {passwordError}
                            </Typography>
                          )}
                        </Grid>

                        {/* <Grid item xs={12}>
                          <div className="pwd_strength">
                            {t("auth.registration.pwd_strength")}&nbsp;:&nbsp;
                            {formData.password.length === 0 ? (
                              <span>{t("auth.registration.no_pwd")}</span>
                            ) : isStrongPassword(formData.password) ? (
                              <span style={{ color: "green" }}>
                                {t("auth.registration.strong_pwd")}
                              </span>
                            ) : (
                              <span style={{ color: "red" }}>
                                {t("auth.registration.weak_pwd")}
                              </span>
                            )}
                          </div>
                        </Grid> */}
                        <Grid item xs={12}>
                          <InputLabel>
                            {t("auth.registration.conf_pwd")}
                          </InputLabel>
                          <TextField
                            required
                            fullWidth
                            name="cpassword"
                            type={showConfPassword ? "text" : "password"}
                            placeholder="Confirm Password"
                            value={confPassword}
                            onChange={handleConfPassChange}
                            InputProps={{
                              endAdornment: (
                                <IconButton
                                  onClick={handleToggleConfPasswordVisibility}
                                >
                                  {showConfPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              ),
                            }}
                          />
                          {passwordMatchError && (
                            <Typography
                              variant="body2"
                              color="error"
                              sx={{ mt: 1 ,fontSize:"14px"}}
                            >
                              {passwordMatchError}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{
                              my: 2,
                              textTransform: "capitalize",
                              color: "#fff",
                              fontSize: "18px",
                              fontWeight: "500",
                              padding: "6px 34px",
                            }}
                            onClick={handleCreateCustomer}
                            disabled={loading}
                          >
                            {loading ? (
                              <CircularProgress
                                size={30}
                                sx={{ color: "#fff" }}
                              />
                            ) : previousRouteParams ? (
                              previousRouteParams.dataToPass
                            ) : (
                              t("auth.registration.create_account")
                            )}
                          </Button>
                        </Grid>
                        <Grid item xs={12}>
                          <span>
                            {t("auth.registration.account_exist")}&nbsp;
                            <Link
                              to={`/${store}/login`}
                              style={{ color: "#1082C2" }}
                            >
                              {t("auth.registration.sign_in")}
                            </Link>
                          </span>
                        </Grid>
                        <Grid item xs={12}>
                          <span>
                            {t("auth.registration.creating_account")}{" "}
                            <Link
                              to={`/${store}/cms/terms-of-use`}
                              style={{ color: "#1082C2" }}
                            >
                              {t("auth.registration.cond_of_use")}
                            </Link>{" "}
                            and{" "}
                            <Link
                              to={`/${store}/cms/privacy`}
                              style={{ color: "#1082C2" }}
                            >
                              {t("auth.registration.privacy_policy")}
                            </Link>
                            .
                          </span>
                        </Grid>
                      </Grid>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </div>
    </div>
  );
}
export default Register;
