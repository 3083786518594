import React, { useState, useEffect } from "react";
import { Box, MenuItem, TextField } from "@mui/material";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import "./PaymentAccount.scss";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as queries from "../../../../../operations/queries";
import * as mutations from "../../../../../operations/mutations";
import { useMutation, useQuery } from "@apollo/client";

// Define form field types
interface PaymentFormValues {
  cardType: string;
  cardHolderName: string;
  cardNumber: string;
  cvv: string;
  month: number;
  year: number;
}

const PaymentAccount: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const props = searchParams.get("props");
  const editCardId = searchParams?.get("id") ? searchParams?.get("id") : "";
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const { t } = useTranslation();
  const loggedInToken = localStorage.getItem("loggedInCust");

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const { control, handleSubmit, watch, setValue, reset } =
    useForm<PaymentFormValues>({
      defaultValues: {
        cardType: "Visa",
        cardHolderName: "",
        cardNumber: "",
        cvv: "",
        month: currentMonth,
        year: currentYear,
      },
    });

  const selectedYear = watch("year", currentYear);
  const [filteredMonths, setFilteredMonths] = useState<
    { value: number; label: string; disabled: boolean }[]
  >([]);

  useEffect(() => {
    if (selectedYear === currentYear) {
      setFilteredMonths(
        months.map((month, index) => ({
          value: index,
          label: month,
          disabled: index < currentMonth,
        }))
      );
      setValue("month", Math.max(currentMonth, watch("month")));
    } else {
      setFilteredMonths(
        months.map((month, index) => ({
          value: index,
          label: month,
          disabled: false,
        }))
      );
    }
    // eslint-disable-next-line
  }, [selectedYear]);

  const { data: cardData } = useQuery(
    queries.paymentCardQueries.PAYMENT_CARDS,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
        },
      },
    }
  );

  const { data: cardTypes } = useQuery(
    queries.paymentCardQueries.GET_CARD_TYPES,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
        },
      },
    }
  );

  useEffect(() => {
    if (cardData && editCardId) {
      const selectedCard = cardData?.paymentCardList?.find(
        (card: any) => card?.entity_id === parseInt(editCardId)
      );
      if (selectedCard) {
        setValue("cardType", selectedCard.card_type);
        setValue("cardHolderName", selectedCard.card_holder_name);
        setValue("cardNumber", selectedCard.card_number);
        setValue("cvv", selectedCard.cvv_number);
        setValue("month", parseInt(selectedCard.expiry_month) - 1);
        setValue("year", parseInt(selectedCard.expiry_year));
      }
    }
  }, [cardData, editCardId, setValue]);

  const [addPaymentCard] = useMutation(
    mutations.addPaymentCardMutation.ADD_PAYMENT_CARD,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
        },
      },
    }
  );

  const [updatePaymentCard] = useMutation(
    mutations.addPaymentCardMutation.UPDATE_PAYMENT_CARD,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
        },
      },
    }
  );

  const onSubmit: SubmitHandler<PaymentFormValues> = async (data) => {
    if (props === "add") {
      try {
        await addPaymentCard({
          variables: {
            card_type: data?.cardType,
            card_holder_name: data?.cardHolderName,
            card_number: data?.cardNumber,
            cvv_number: data?.cvv,
            expiry_month: String(data.month + 1).padStart(2, "0"),
            expiry_year: String(data.year),
          },
        });
        const currentPath = window.location.pathname;
        const basePath = currentPath.split("/new")[0];
        navigate(basePath);
        reset();
      } catch (error) {
        console.error("Error adding card:", error);
      }
    } else if (props === "edit") {
      try {
        await updatePaymentCard({
          variables: {
            entity_id: editCardId,
            card_type: data.cardType,
            card_holder_name: data.cardHolderName,
            card_number: data.cardNumber,
            cvv_number: data.cvv,
            expiry_month: String(data.month + 1).padStart(2, "0"),
            expiry_year: String(data.year),
          },
        });
        const currentPath = window.location.pathname;
        const basePath = currentPath.split("/new")[0];
        navigate(basePath);
        reset();
      } catch (error) {
        console.error("Error adding card:", error);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box className="payment-box">
        <Box className="payment-title">
          <Box>
            <h4>{t("buyer_dashboard.add_card.title")}</h4>
            <p>{t("buyer_dashboard.add_card.desc")}</p>
          </Box>
        </Box>

        <Box className="general-info-box">
          <h4>{t("buyer_dashboard.add_card.small_desc")}</h4>
          <Box className="input-grp">
            <Box className="input-box">
              <p>{t("buyer_dashboard.add_card.card_title")}</p>
              <Controller
                name="cardType"
                control={control}
                render={({ field }) => (
                  <TextField {...field} select fullWidth>
                    {cardTypes?.cardTypes?.map(
                      (type: { id: string; name: string }) => (
                        <MenuItem
                          key={type.id}
                          value={type.name}
                          sx={{ fontSize: "13px" }}
                        >
                          {type?.name}
                        </MenuItem>
                      )
                    )}
                  </TextField>
                )}
              />
            </Box>
            <Box className="input-box">
              <p>{t("buyer_dashboard.add_card.holder_name")}</p>
              <Controller
                name="cardHolderName"
                control={control}
                rules={{
                  required: t("buyer_dashboard.add_card.holder_name_err"),
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    FormHelperTextProps={{
                      sx: {
                        color: "red !important",
                        fontSize: "0.75rem !important",
                      },
                    }}
                  />
                )}
              />
            </Box>
          </Box>

          <Box className="input-grp another-box">
            <Box className="input-box card_number">
              <p>{t("buyer_dashboard.add_card.card_number")}</p>
              <Controller
                name="cardNumber"
                control={control}
                rules={{
                  required: t("buyer_dashboard.add_card.card_number_err"),
                  pattern: {
                    value: /^\d{16}$/,
                    message: t(
                      "buyer_dashboard.add_card.card_number_digit_err"
                    ),
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    FormHelperTextProps={{
                      sx: {
                        color: "red !important",
                        fontSize: "0.75rem !important",
                      },
                    }}
                  />
                )}
              />
            </Box>
            <Box className="input-box cvv">
              <p>{t("buyer_dashboard.add_card.cvv")}</p>
              <Controller
                name="cvv"
                control={control}
                rules={{
                  required: t("buyer_dashboard.add_card.cvv_err"),
                  pattern: {
                    value: /^\d{3,4}$/,
                    message: t("buyer_dashboard.add_card.cvv_digit_err"),
                  },
                }}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    fullWidth
                    type="password"
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                    FormHelperTextProps={{
                      sx: {
                        color: "red !important",
                        fontSize: "0.75rem !important",
                      },
                    }}
                  />
                )}
              />
            </Box>
            <Box className="input-box">
              <p>{t("buyer_dashboard.add_card.month")}</p>
              <Controller
                name="month"
                control={control}
                render={({ field }) => (
                  <TextField {...field} select fullWidth>
                    {filteredMonths.map(({ value, label, disabled }) => (
                      <MenuItem
                        key={value}
                        value={value}
                        disabled={disabled}
                        sx={{ fontSize: "13px" }}
                      >
                        {label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Box>
            <Box className="input-box">
              <p>{t("buyer_dashboard.add_card.year")}</p>
              <Controller
                name="year"
                control={control}
                render={({ field }) => (
                  <TextField {...field} select fullWidth>
                    {Array.from({ length: 20 }, (_, i) => currentYear + i).map(
                      (year) => (
                        <MenuItem
                          key={year}
                          value={year}
                          sx={{ fontSize: "13px" }}
                        >
                          {year}
                        </MenuItem>
                      )
                    )}
                  </TextField>
                )}
              />
            </Box>
          </Box>

          <Box className="update-btn">
            <button type="submit" className="active">
              {t("buyer_dashboard.add_card.save_card_btn")}
            </button>
          </Box>
        </Box>
      </Box>
    </form>
  );
};

export default PaymentAccount;
