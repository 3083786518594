import { gql } from "@apollo/client";
export const GET_LOGO = gql`
  query getLogo {
    storeConfig {
      header_logo_src
      secure_base_media_url
    }
  }
`;
export const GET_STORES = gql`
  query getstores {
    getStores(filter: { storeId: { eq: "1" } }) {
      country
      flag
      websiteId
      store_code
      base_url
      countryName
      countryCode
    }
  }
`;
export const GET_MENUS = gql`
  query getMenus($parentId: String!) {
    categories(
      filters: { parent_id: { in: [$parentId] }, include_in_menu: { eq: "1" } }
    ) {
      total_count
      items {
        id
        include_in_menu
        level
        name
        path
        url_path
        url_key
        children_count
        is_visible_top
        category_icon
        category_brands {
          name
          image
          image_mobile
          link
        }
        children {
          id
          include_in_menu
          level
          name
          path
          url_path
          url_key
          children_count
          children {
            id
            include_in_menu
            level
            name
            path
            url_path
            url_key
          }
        }
      }
      page_info {
        current_page
        page_size
        total_pages
      }
    }
  }
`;

export const SEARCH_QUERY = gql`
  query getSearch($searchText: String!, $currentPage: Int!) {
    searchTerms(search: $searchText) {
      title
      num_results
      type
    }
    products(search: $searchText, pageSize: 48, currentPage: $currentPage) {
      total_count
      items {
        name
        delivery_estimation
        top_badge_left
        top_badge_left_title
        top_badge_right
        top_badge_right_title
        wishlist_item_id
        is_in_wishlist
        sellerData {
          qty
          price
          currency
          seller_id
          seller_name
          delivery_note
          warranty
        }
        files {
          name
          type
          url
        }
        sku
        manufacturer
        brand_logo
        brand_logo_img
        brand_logo_link
        url_key
        meta_title
        meta_keyword
        meta_description
        stock_status
        options_container
        short_description {
          html
        }
        attributes {
          label
          value
        }
        review_count
        breadcrumb
        rating_summary
        reviews {
          items {
            average_rating
            summary
            text
            created_at
            nickname
            ratings_breakdown {
              value
              name
            }
          }
        }
        small_image {
          disabled
          label
          position
          url
        }
        description {
          html
        }
        ... on ConfigurableProduct {
          media_gallery {
            url
            label
            position
          }
          variants {
            attributes {
              uid
              label
              code
            }
            product {
              sku
              name
              price_range {
                minimum_price {
                  regular_price {
                    value
                    currency
                  }
                  discount {
                    amount_off
                    percent_off
                  }
                  final_price {
                    value
                    currency
                  }
                }
              }
              media_gallery {
                thumbnail_url
                url
                position
              }
              small_image {
                label
                url
              }
              image {
                label
                url
              }
            }
          }
          configurable_options {
            values {
              label
              swatch_data {
                value
              }
            }
          }
        }
        price_range {
          minimum_price {
            regular_price {
              value
              currency
            }
            discount {
              amount_off
              percent_off
            }
            final_price {
              value
              currency
            }
          }
        }
        image {
          url
          small_url
          label
        }
        small_image {
          url
          label
        }
        media_gallery {
          url
          thumbnail_url
          label
          disabled
          ... on ProductVideo {
            video_content {
              media_type
              video_provider
              video_url
              video_title
              video_description
              video_metadata
            }
          }
        }
        related_products {
          uid
          name
          sku
          url_key
          top_badge_left
          top_badge_left_title
          top_badge_right
          top_badge_right_title
          small_image {
            url
            small_url
            label
          }
          price_range {
            minimum_price {
              final_price {
                value
                currency
              }
            }
          }
          small_image {
            label
            url
          }
        }
        upsell_products {
          uid
          name
        }
        crosssell_products {
          uid
          name
        }
      }
      aggregations {
        attribute_code
        count
        currency
        label
        options {
          label
          value
          count
        }
      }
      page_info {
        page_size
        current_page
      }
    }
  }
`;
