import { gql } from "@apollo/client";

export const PAYMENT_CARDS = gql`
query {
      paymentCardList {
        entity_id
        card_type
        card_holder_name
        card_number
        cvv_number
        expiry_month
        expiry_year
      }
    }
`;

export const GET_CUSTOMER_ID_BY_EMAIL = gql`
  query getCustomerIdByEmail($email: String!) {
    getCustomerIdByEmail(email: $email) {
      customer_id
    }
  }
`;

export const GET_CARD_TYPES = gql`
query {
  cardTypes {
    id
    name
  }
}
`;
