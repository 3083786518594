import { gql } from "@apollo/client";

export const ADD_PAYMENT_CARD = gql`
 mutation addPaymentCard(
  $card_type: String!, 
  $card_holder_name: String!, 
  $card_number: String!, 
  $cvv_number: String!, 
  $expiry_month: String!, 
  $expiry_year: String!) {
  
  addPaymentCard(
    card_type: $card_type
    card_holder_name: $card_holder_name
    card_number: $card_number
    cvv_number: $cvv_number
    expiry_month: $expiry_month
    expiry_year: $expiry_year
  ) {
    entity_id
    customer_id
    card_type
    card_holder_name
    card_number
    cvv_number
    expiry_month
    expiry_year
  }
}
`;


export const UPDATE_PAYMENT_CARD = gql`
 mutation updatePaymentCard(
      $entity_id: Int!,
      $card_type: String!, 
      $card_holder_name: String!, 
      $card_number: String!, 
      $cvv_number: String!, 
      $expiry_month: String!, 
      $expiry_year: String!) {
      
      updatePaymentCard(
        entity_id: $entity_id
        card_type: $card_type
        card_holder_name: $card_holder_name
        card_number: $card_number
        cvv_number: $cvv_number
        expiry_month: $expiry_month
        expiry_year: $expiry_year
      ) {
        entity_id
        customer_id
        card_type
        card_holder_name
        card_number
        cvv_number
        expiry_month
        expiry_year
      }
    }
`;

export const DELETE_PAYMENT_CARD = gql`
  mutation deletePaymentCard($entity_id: Int!) {
    deletePaymentCard(entity_id: $entity_id)
  }
`;
