import React, { useEffect, useState } from "react";
import { Box, Radio, Button } from "@mui/material";
import "./PaymentHomePage.scss";
import { Link } from "react-router-dom";
import EmptyCards from "./emptyCards/EmptyCards";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import * as mutations from "../../../../operations/mutations";
import * as queries from "../../../../operations/queries";
import { toast } from "react-toastify";
import Loader from "../loader/Loader";

const PaymentHomePage = () => {
  const [selectedCard, setSelectedCard] = useState(0);
  const { t } = useTranslation();
  const loggedInToken = localStorage.getItem("loggedInCust");

  const {
    data: cardDataAll,
    refetch: refetchCardData,
    loading: cardLoading,
  } = useQuery(queries.paymentCardQueries.PAYMENT_CARDS, {
    context: {
      headers: {
        Authorization: loggedInToken
          ? `Bearer ${JSON.parse(loggedInToken)}`
          : "",
        Connection: "keep-alive",
      },
    },
  });

  const [deletePaymentCard] = useMutation(
    mutations.addPaymentCardMutation.DELETE_PAYMENT_CARD,
    {
      context: {
        headers: {
          Authorization: loggedInToken
            ? `Bearer ${JSON.parse(loggedInToken)}`
            : "",
          Connection: "keep-alive",
        },
      },
    }
  );

  useEffect(() => {
    refetchCardData();
  }, [refetchCardData, deletePaymentCard]);

  const handleDelete = async (entityId: any) => {
    if (entityId) {
      try {
        await deletePaymentCard({
          variables: { entity_id: parseInt(entityId) },
        });
        toast.success(t("buyer_dashboard.payment.delete_toast"));
        refetchCardData();
      } catch (error) {
        toast.error(t("buyer_dashboard.payment.delete_toast_err"));
      }
    }
  };

  const handleCardClick = (index: any) => {
    setSelectedCard(index);
  };

  const cardData: any = cardDataAll?.paymentCardList;

  const sortedCardData = cardData
    ? [...cardData].sort((a: any, b: any) => b.entity_id - a.entity_id)
    : [];

  return (
    <Box className="card-box">
      <Box className="card-title">
        <Box>
          <h4 className="title">{t("buyer_dashboard.payment.title")}</h4>
          <p className="subtitle">{t("buyer_dashboard.payment.desc")}</p>
        </Box>
        <Box>
          <Link to={`new?props=add`}>
            <button color="primary">
              {t("buyer_dashboard.payment.new_card_btn")}
            </button>
          </Link>
        </Box>
      </Box>
      {cardLoading ? (
        <Loader />
      ) : sortedCardData.length === 0 ? (
        <Box className="empty-card-message">
          <EmptyCards />
        </Box>
      ) : (
        sortedCardData.map((card: any, index: any) => (
          <Box className="general-card-box" key={card.entity_id}>
            <Box
              className={`card-details ${
                selectedCard === index ? "selected" : ""
              }`}
              onClick={() => handleCardClick(index)}
            >
              <Box>
                <Radio
                  checked={selectedCard === index}
                  onChange={() => handleCardClick(index)}
                  className="radio-btn"
                />
              </Box>

              <Box className="payment-card">
                <Box className="card-info">
                  <p className="card-type">
                    <span className="card-type-actual">{card.card_type}</span>{" "}
                    {t("buyer_dashboard.payment.ending")}{" "}
                    {card.card_number.slice(-4)}
                  </p>
                  <p className="card-expiry">
                    {t("buyer_dashboard.payment.expire")} {card.expiry_month}/
                    {card.expiry_year}
                  </p>
                </Box>

                <Box className="action-buttons">
                  <Link to={`new?props=edit&id=${card.entity_id}`}>
                    <Button size="small" className="edit-button">
                      {t("buyer_dashboard.payment.edit_btn")}
                    </Button>
                  </Link>
                  <Button
                    size="small"
                    className="delete-button"
                    onClick={() => handleDelete(card.entity_id)}
                  >
                    {t("buyer_dashboard.payment.dlt_btn")}
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        ))
      )}
    </Box>
  );
};

export default PaymentHomePage;
