import React, { useState } from "react";
import { Box, Button, Checkbox, Modal, TextField, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface CreateWishlistModalProps {
  open: boolean;
  handleClose: () => void;
  mode: string;
  // initialData?: { name: string; isDefault: boolean };
  // onSubmit: (data: { name: string; isDefault: boolean }) => void;
}

const CreateWishlistModal: React.FC<CreateWishlistModalProps> = ({
  open,
  handleClose,
  mode,
  // initialData = { name: "", isDefault: false },
  // onSubmit,
}) => {
  const [name, setName] = useState("jemini");
  const [isDefault, setIsDefault] = useState(true);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 550,
    bgcolor: "background.paper",
  };

  // const handleSave = () => {
  //   onSubmit({ name, isDefault });
  //   handleClose();
  // };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      className="add-wish-list-modal"
    >
      <Box sx={style} className="box-size">
        <Box className="Title-head">
          <Typography id="modal-title" variant="h6" component="h2" className="head-txt">
            {mode === "create" ? "Create New Wishlist" : "Edit Wishlist"}
          </Typography>
          <Button>
            <CloseIcon onClick={handleClose} />
          </Button>
        </Box>

        <Box className="content-box">
          <TextField
            autoComplete="off"
            name="wishlist-name"
            required
            fullWidth
            id="wishlist-name"
            placeholder="Enter Wishlist Name..."
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

          <Box className="check-box-text">
            <Checkbox
              checked={isDefault}
              onChange={(e) => setIsDefault(e.target.checked)}
              inputProps={{ "aria-label": "Checkbox demo" }}
            />
            <p>Use this as default wishlist</p>
          </Box>

          <Box className="Upate-btn">
            <button className="update-btn">
              {mode === "create" ? "Create" : "Save Changes"}
            </button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default CreateWishlistModal;
