import { gql } from "@apollo/client";

export const SIMPLE_CATEGORY_PRODUCTLIST = gql`
  query getCategoryProductsList(
    $categoryId: String!
    $currentPage: Int!
    $sortOrder: SortEnum
  ) {
    products(
      filter: { category_id: { eq: $categoryId } }
      pageSize: 48
      currentPage: $currentPage
      sort: { price: $sortOrder }
    ) {
      items {
        name
        delivery_estimation
        top_badge_left
        top_badge_left_title
        top_badge_right
        top_badge_right_title
        brand_logo
        brand_logo_img
        brand_logo_link
        wishlist_item_id
        is_in_wishlist
        sellerData {
          qty
          price
          currency
          seller_id
          seller_name
          delivery_note
          warranty
        }
        files {
          name
          type
          url
        }
        sku
        url_key
        meta_title
        meta_keyword
        meta_description
        stock_status
        short_description {
          html
        }
        attributes {
          label
          value
        }
        review_count
        breadcrumb
        rating_summary
        reviews {
          items {
            average_rating
            summary
            text
            created_at
            nickname
            ratings_breakdown {
              value
              name
            }
          }
        }
        small_image {
          disabled
          label
          position
          url
        }
        description {
          html
        }
        ... on ConfigurableProduct {
          media_gallery {
            url
            label
            position
          }
          variants {
            attributes {
              uid
              label
              code
            }
            product {
              sku
              name
              price_range {
                minimum_price {
                  regular_price {
                    value
                    currency
                  }
                  discount {
                    amount_off
                    percent_off
                  }
                  final_price {
                    value
                    currency
                  }
                }
              }
              media_gallery {
                thumbnail_url
                url
                position
              }
              small_image {
                label
                url
              }
              image {
                label
                url
              }
            }
          }
          configurable_options {
            values {
              label
              swatch_data {
                value
              }
            }
          }
        }
        price_range {
          minimum_price {
            regular_price {
              value
              currency
            }
            discount {
              amount_off
              percent_off
            }
            final_price {
              value
              currency
            }
          }
        }
        image {
          url
          small_url
          label
        }
        small_image {
          url
          label
        }
        media_gallery {
          url
          thumbnail_url
          label
          disabled
          ... on ProductVideo {
            video_content {
              media_type
              video_provider
              video_url
              video_title
              video_description
              video_metadata
            }
          }
        }
        related_products {
          uid
          name
          sku
          url_key
          top_badge_left
          top_badge_left_title
          top_badge_right
          top_badge_right_title
          small_image {
            url
            small_url
            label
          }
          price_range {
            minimum_price {
              final_price {
                value
                currency
              }
            }
          }
          small_image {
            label
            url
          }
        }
        upsell_products {
          uid
          name
        }
        crosssell_products {
          uid
          name
        }
      }
      aggregations {
        attribute_code
        count
        currency
        label
        options {
          label
          value
          count
        }
      }
      page_info {
        page_size
        current_page
        total_pages
      }
    }
  }
`;
export const SIMPLE_BRANDS_PRODUCTLIST = gql`
  query getCategoryProductsList(
    $categoryId: String!
    $currentPage: Int!
    $manufacturer: String!
  ) {
    products(
      filter: {
        category_id: { eq: $categoryId }
        manufacturer: { eq: $manufacturer }
      }
      pageSize: 48
      currentPage: $currentPage
    ) {
      items {
        name
        delivery_estimation
        top_badge_left
        top_badge_left_title
        top_badge_right
        top_badge_right_title
        brand_logo
        brand_logo_img
        brand_logo_link
        wishlist_item_id
        is_in_wishlist
        sellerData {
          qty
          price
          currency
          seller_id
          seller_name
          delivery_note
          warranty
        }
        files {
          name
          type
          url
        }
        sku
        url_key
        meta_title
        meta_keyword
        meta_description
        stock_status
        short_description {
          html
        }
        attributes {
          label
          value
        }
        review_count
        breadcrumb
        rating_summary
        reviews {
          items {
            average_rating
            summary
            text
            created_at
            nickname
            ratings_breakdown {
              value
              name
            }
          }
        }
        small_image {
          disabled
          label
          position
          url
        }
        description {
          html
        }
        ... on ConfigurableProduct {
          media_gallery {
            url
            label
            position
          }
          variants {
            attributes {
              uid
              label
              code
            }
            product {
              sku
              name
              price_range {
                minimum_price {
                  regular_price {
                    value
                    currency
                  }
                  discount {
                    amount_off
                    percent_off
                  }
                  final_price {
                    value
                    currency
                  }
                }
              }
              media_gallery {
                thumbnail_url
                url
                position
              }
              small_image {
                label
                url
              }
              image {
                label
                url
              }
            }
          }
          configurable_options {
            values {
              label
              swatch_data {
                value
              }
            }
          }
        }
        price_range {
          minimum_price {
            regular_price {
              value
              currency
            }
            discount {
              amount_off
              percent_off
            }
            final_price {
              value
              currency
            }
          }
        }
        image {
          url
          small_url
          label
        }
        small_image {
          url
          label
        }
        media_gallery {
          url
          thumbnail_url
          label
          disabled
          ... on ProductVideo {
            video_content {
              media_type
              video_provider
              video_url
              video_title
              video_description
              video_metadata
            }
          }
        }
        related_products {
          uid
          name
          sku
          url_key
          small_image {
            url
            small_url
            label
          }
          price_range {
            minimum_price {
              final_price {
                value
                currency
              }
            }
          }
        }
        upsell_products {
          uid
          name
        }
        crosssell_products {
          uid
          name
        }
      }
      aggregations {
        attribute_code
        count
        currency
        label
        options {
          label
          value
          count
        }
      }
      page_info {
        page_size
        current_page
        total_pages
      }
    }
  }
`;

export const GET_BRANDS_LIST = gql`
  query {
    customAttributeMetadata(
      attributes: [
        { attribute_code: "manufacturer", entity_type: "catalog_product" }
      ]
    ) {
      items {
        attribute_code
        attribute_type
        entity_type
        input_type
        attribute_options {
          option_label_en
          value
          label
          swatch_image
        }
        storefront_properties {
          use_in_product_listing
          use_in_layered_navigation
          use_in_search_results_layered_navigation
          visible_on_catalog_pages
          position
        }
      }
    }
  }
`;
export const GET_BRANDS_LIST_DETAIL = gql`
  query {
    customAttributeMetadata(
      attributes: [
        { attribute_code: "manufacturer", entity_type: "catalog_product" }
      ]
    ) {
      items {
        attribute_code
        attribute_type
        entity_type
        input_type
        attribute_options {
          option_label_en
          option_label_ar
          meta_title
          meta_description
          value
          label
          swatch_image
          brand_description
          brand_description_ar
        }
        storefront_properties {
          use_in_product_listing
          use_in_layered_navigation
          use_in_search_results_layered_navigation
          visible_on_catalog_pages
          position
        }
      }
    }
  }
`;
export const GET_PRODUCTS_FILTER = gql`
  query GetFilterProducts(
    $categoryId: String!
    $pageSize: Int!
    $manufacturerName: [String!]
    $from: String!
    $to: String
  ) {
    products(
      filter: {
        category_id: { eq: $categoryId }
        manufacturer: { in: $manufacturerName }
        price: { from: $from, to: $to }
      }
      pageSize: $pageSize
    ) {
      items {
        name
        delivery_estimation
        top_badge_left
        top_badge_left_title
        top_badge_right
        top_badge_right_title
        brand_logo
        brand_logo_img
        brand_logo_link
        wishlist_item_id
        is_in_wishlist
        sellerData {
          qty
          price
          currency
          seller_id
          seller_name
          delivery_note
          warranty
        }
        files {
          name
          type
          url
        }
        sku
        url_key
        meta_title
        meta_keyword
        meta_description
        stock_status
        short_description {
          html
        }
        attributes {
          label
          value
        }
        review_count
        breadcrumb
        rating_summary
        reviews {
          items {
            average_rating
            summary
            text
            created_at
            nickname
            ratings_breakdown {
              value
              name
            }
          }
        }
        small_image {
          disabled
          label
          position
          url
        }
        description {
          html
        }
        ... on ConfigurableProduct {
          media_gallery {
            url
            label
            position
          }
          variants {
            attributes {
              uid
              label
              code
            }
            product {
              sku
              name
              price_range {
                minimum_price {
                  regular_price {
                    value
                    currency
                  }
                  discount {
                    amount_off
                    percent_off
                  }
                  final_price {
                    value
                    currency
                  }
                }
              }
              media_gallery {
                thumbnail_url
                url
                position
              }
              small_image {
                label
                url
              }
              image {
                label
                url
              }
            }
          }
          configurable_options {
            values {
              label
              swatch_data {
                value
              }
            }
          }
        }
        price_range {
          minimum_price {
            regular_price {
              value
              currency
            }
            discount {
              amount_off
              percent_off
            }
            final_price {
              value
              currency
            }
          }
        }
        image {
          url
          small_url
          label
        }
        small_image {
          url
          label
        }
        media_gallery {
          url
          thumbnail_url
          label
          disabled
          ... on ProductVideo {
            video_content {
              media_type
              video_provider
              video_url
              video_title
              video_description
              video_metadata
            }
          }
        }
        related_products {
          uid
          name
          sku
          url_key
          top_badge_left
          top_badge_left_title
          top_badge_right
          top_badge_right_title
          small_image {
            url
            small_url
            label
          }
          price_range {
            minimum_price {
              final_price {
                value
                currency
              }
            }
          }
          small_image {
            label
            url
          }
        }
        upsell_products {
          uid
          name
        }
        crosssell_products {
          uid
          name
        }
      }
      aggregations {
        attribute_code
        count
        currency
        label
        options {
          label
          value
          count
        }
      }
      page_info {
        page_size
        current_page
        total_pages
      }
    }
  }
`;

export const GET_CATEGORY_DETAILS = gql`
  query GetCategoryDetails($id: Int!) {
    category(id: $id) {
      id
      include_in_menu
      description
      level
      name
      path
      url_path
      url_key
      meta_title
      meta_keywords
      meta_description
      children_count
      children_count
      enable_campaign
      category_banners {
        name
        image
        image_mobile
        link
      }
      children {
        id
        level
        name
        path
        children {
          id
          level
          name
          path
          children {
            id
            level
            name
            path
            children {
              id
              level
              name
              path
            }
          }
        }
      }
      breadcrumbs {
        category_level
        category_name
        category_url_path
      }
    }
  }
`;

export const BLOG_LIST = gql`
  query {
    getAllPosts {
      banner
      banner_url
      items {
        thumbnail
        title
        short_description
        link
      }
    }
  }
`;

export const BLOG_DETAIL = gql`
  query ($url_key: String!) {
    getSingleBlog(url_key: $url_key) {
      banner
      title
      description
      link
      recentPosts {
        title
        link
      }
    }
  }
`;
