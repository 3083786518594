import React, { useContext } from "react";
import "./Wishlist.scss";
import NoWishList from "../../../../../assets/images/no-wishlist.png";
// import CreateWishlistModal from "../createWishlistModal/CreateWishlistModal";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { CountryContext } from "../../../MainComponent";
import { useTranslation } from "react-i18next";

const Wishlist = () => {
  const navigate = useNavigate()
  const {store} = useContext(CountryContext)
  const {t} = useTranslation();
  // const [open, setOpen] = React.useState(false);
  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  return (
    <>
      <Box className="no-wishlist">
        {/* eslint-disable-next-line */}
        <img src={NoWishList} alt="Image" />

        <h4>{t("buyer_dashboard.empty_wishlist.title")}</h4>

        <p>
        {t("buyer_dashboard.empty_wishlist.desc")}
        </p>

        <button
          onClick={() => {
            navigate(`/${store}`);
          }}
        >
          {t("buyer_dashboard.empty_wishlist.link_btn")}
        </button>
      </Box>
      {/* <CreateWishlistModal open={open} handleClose={handleClose} mode="create"/> */}
    </>
  );
};

export default Wishlist;
