import React, { useContext,  useEffect,  useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import "./ReturnCompleted.scss";
import NoReturnCompleted from "../noreturnCompleted/NoReturnCompleted";
import { useNavigate } from "react-router-dom";
import {  useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import * as queries from "../../../../../operations/queries";
import { CountryContext } from "../../../MainComponent";

const ReturnCompleted = () => {
   //eslint-disable-next-line
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const [returnRequestList, setReturnRequestList] = useState<any>(null);
  const loggedInToken = localStorage.getItem("loggedInCust");
  const  navigate  = useNavigate();
  const { store } = useContext(CountryContext);

   //eslint-disable-next-line
  const { data , refetch: refetchListData } = useQuery(queries.returnQueries.GET_RETURN_REQUESTS, {
    variables: { type: "list" },
    context: {
      headers: {
        Authorization: loggedInToken
          ? `Bearer ${JSON.parse(loggedInToken)}`
          : "",
        Connection: "keep-alive",
        store: store,
      },
    },
    onCompleted: (data) => {
      setLoading(false);
      setReturnRequestList(data?.getReturnRequests || []);
    },
    onError: () => {
      setLoading(false);
    },
  });

  useEffect(() => {
    if (data) {
      refetchListData();
    }
  }, [data, refetchListData]);

  const filteredReturnRequests = returnRequestList?.filter((item: any) => item.status === "1");
  const returnRequestsWithPendingStatus = filteredReturnRequests?.map((item: any) => ({
    ...item,
    status: "Completed", 
  }));

  const closePage = () => {
    const currentPath = window.location.pathname;
      const basePath = currentPath.split("/return-completed")[0];
      navigate(basePath);
  }

  return (
    <div className="return-items">
      <div className="heading-sec">
        <div className="name-sec">
          <h2 className="title">{t("buyer_dashboard.return.title")}</h2>
          <p className="subtitle">
          {t("buyer_dashboard.return.return_desc")}
          </p>
        </div>

        {returnRequestsWithPendingStatus?.length !== 0 && (
          <div className="btn-sec">
            <button onClick={() => closePage()} style={{cursor:'pointer'}}>{t("buyer_dashboard.return.back_return_btn")}</button>
          </div>
        )}
      </div>

      {returnRequestsWithPendingStatus?.length === 0 ? (
        <NoReturnCompleted />
      ) : (
        <>
      <div className="desktop-view">
        <Table sx={{ backgroundColor: "white" }}>
          <TableHead>
            <TableRow>
              <TableCell className="header-cell">{t("buyer_dashboard.return.product")}</TableCell>
              <TableCell className="header-cell">{t("buyer_dashboard.return.order")}</TableCell>
              <TableCell className="header-cell">{t("buyer_dashboard.return.product_name")}</TableCell>
              <TableCell className="header-cell"> {t("buyer_dashboard.return.date")}</TableCell>
              <TableCell className="header-cell"> {t("buyer_dashboard.return.price")}</TableCell>
              <TableCell className="header-cell">{t("buyer_dashboard.return.status")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {returnRequestsWithPendingStatus?.map((item:any) => (
              <TableRow key={item?.id}>
                <TableCell className="body-cell">
                  {item?.productImg ? (
                    <img
                      src={item?.productImg}
                      alt="Product"
                      className="product-image"
                    />
                  ) : (
                    <span className="no-image">No Image</span>
                  )}
                </TableCell>
                <TableCell className="body-cell">{item.order_number}</TableCell>
                <TableCell className="body-cell">{item.productName}</TableCell>
                <TableCell className="body-cell">{item.refund_date}</TableCell>
                <TableCell className="body-cell">{item.price}</TableCell>
                <TableCell className="body-cell">
                  <span className={`status ${item.status.toLowerCase()}`}>
                    {item.status}
                  </span>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>

      <div className="mobile-view">
        {returnRequestsWithPendingStatus?.map((item:any) => (
          <div className="table-container" key={item.id}>
            <Table sx={{ backgroundColor: "white" }}>
              <TableBody>
                <TableRow>
                  <TableCell className="header">Product</TableCell>
                  <TableCell>
                    {item.productImg ? (
                      <img
                        src={item.productImg}
                        alt="Product"
                        className="product-image"
                      />
                    ) : (
                      <span className="no-image">No Image</span>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="header">{t("buyer_dashboard.return.order")}</TableCell>
                  <TableCell>{item.order_number}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="header">{t("buyer_dashboard.return.product_name")}</TableCell>
                  <TableCell>{item.productName}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="header"> {t("buyer_dashboard.return.date")}</TableCell>
                  <TableCell>{item.refund_date}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="header"> {t("buyer_dashboard.return.price")}</TableCell>
                  <TableCell>{item.price}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="header">{t("buyer_dashboard.return.status")}</TableCell>
                  <TableCell>
                    <span className={`status ${item.status.toLowerCase()}`}>
                      {item.status}
                    </span>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        ))}
      </div>

      </>
      )}
    </div>
  );
};

export default ReturnCompleted;
