import { Box, Skeleton, useMediaQuery } from "@mui/material";
import React from "react";
import theme from "../../theme";
import GridUn from "@mui/material/Unstable_Grid2";

const MainCardSkelton = () => {
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const count = isMobileScreen ? 6 : 10;

  return (
    <GridUn
      container
      rowSpacing={3}
      columnSpacing={{ xs: 2, md: 1.5 }}
      columns={{ xs: 12 }}
    >
      {Array.from(Array(count)).map((_, index) => (
        <GridUn xs={6} sm={4} md={3} lg={2.4} xl={2.4} key={index}>
          <Box sx={{ padding: 2, border: "1px solid #ddd", borderRadius: 2 }}>
            <Skeleton variant="rectangular" width="100%" height={220} />
            <Skeleton variant="text" sx={{ mt: 0.5, width: "80%" }} />
            <Skeleton variant="text" sx={{ mt: 0.5, width: "50%" }} />
            <Skeleton variant="text" sx={{ mt: 0.5, width: "40%" }} />
            <Box sx={{display: "flex", justifyContent: "space-between",mt: 0.5}}>
            <Skeleton
              variant="circular"
              width="11%"
              height={30}
            />
            <Skeleton
              variant="rectangular"
              width="13%"
              height={30}
            />
            </Box>
            
          </Box>
        </GridUn>
      ))}
    </GridUn>
  );
};

export default MainCardSkelton;
