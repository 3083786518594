import React, { useState } from 'react';
import { Box, Button, Card, Container, Divider, Grid, IconButton, List, ListItem, ListItemText, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface sellerDataObjProp {
  __typename: string; 
  currency: string;
  delivery_note: string;
  qty: number;
  price: number;
  seller_name: string;
  seller_id: number;
  warranty: string;
}

interface ProductOffersPopUpProps {
  onClose: () => void; 
  sellerDatas: sellerDataObjProp[]; 
  handleAddToCart: (seller_id: number) => Promise<void>;
  onSellerIdChange : (seller_id: number) => void;
}

const ProductOffersPopUp: React.FC<ProductOffersPopUpProps> = ({ onClose, sellerDatas, handleAddToCart, onSellerIdChange }) => {
// const {setDemo} = demoProps
  const [addedSellerId, setAddSellerId] = useState<number | null>(null);

  const handleAddToCartClicked = async (seller_id: number) => {
    
    await handleAddToCart(seller_id);
    setAddSellerId(seller_id);
    onSellerIdChange(seller_id)
    // demoProps((prevState:any) => [...prevState, seller_id]);
  }

  return (
    <>
      <Container className='offers-popup-container' sx={{ margin: "10px 0", width: "100%"}}>
        <Box className="offers-popup-block">
          <Grid item xl={3} lg={3} md={12} sm={12} xs={12}>
            <Card variant="outlined" sx={{
              borderRadius: '3px',
              width: '100%',
              maxWidth: { xl: '100%', lg: '100%', md: '100%', sm: '100%', xs: '100%' },
            }}>
              <div className="Main-div">
                <List component="nav" aria-label="main mailbox folders" sx={{ pb: 0 }}>
                  <div className='Header-section'>
                    <ListItem>
                      <ListItemText>
                        <Typography sx={{ fontWeight: "600" }}>
                          {`${sellerDatas.length} Offers available`}
                        </Typography>
                      </ListItemText>
                      <IconButton onClick={onClose}>
                        <CloseIcon />
                      </IconButton>
                    </ListItem>
                  </div>
                  <Divider />
                  <div className='card-display offers-card-display'>
                    <Box sx={{
                      margin: "20px",
                      direction: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      columnGap: "20px"
                    }}>
                      {sellerDatas.map((seller, index) => (
                        <Card key={index}  variant="outlined" sx={{ margin: "10px 0 " }}>
                          <List sx={{ padding: "0 0" }}>
                            <ListItem alignItems="flex-start" className='price-section'>
                              <ListItemText
                                primary={
                                  <div>
                                  <Typography
                                    sx={{ display: 'inline', fontWeight: "600 " }}
                                    component="span"
                                    variant="body1"
                                    color="#00B4D7"
                                  >
                                   {seller.currency} {seller.price} 
                                  </Typography>
                                  <div className='order-time'>{seller.delivery_note}</div>
                                  </div>
                                  
                                }
                              />
                            </ListItem>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "baseline"
                              }}
                              className='seller-data'
                            >
                              <ListItem sx={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                                <ListItemText>
                                  <Typography sx={{ fontWeight: "500" }} color="#353535">
                                    Warranty
                                  </Typography>
                                </ListItemText>
                                <ListItemText
                                  primary={
                                    <Typography
                                      sx={{ display: 'inline', fontSize: '12px', fontWeight: '400', }}
                                      component="span"
                                      variant="body2"
                                      color="text.primary"
                                    >
                                      {seller.warranty}
                                    </Typography>
                                  }
                                />
                              </ListItem>
                              <ListItem sx={{ display: 'flex', flexDirection: 'column', textAlign: 'center' }}>
                                <ListItemText>
                                  <Typography sx={{ fontWeight: "500" }} color="#353535">
                                    Sold By
                                  </Typography>
                                </ListItemText>
                                <ListItemText
                                  primary={
                                    <React.Fragment>
                                      <Typography
                                        sx={{ display: 'inline', fontSize: '12px', fontWeight: '400', color: '#5B5858' }}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                      >
                                        {seller.seller_name}
                                      </Typography>
                                    </React.Fragment>
                                  }
                                />
                              </ListItem>
                            </Box>
                            <Button variant="contained" disabled={addedSellerId !== null && addedSellerId !== seller.seller_id} sx={{ backgroundColor: "#00B4D7", width: "100%" }} className='add-btn' onClick={() => handleAddToCartClicked(seller?.seller_id)}>
                              <Typography color="white" style={{ textTransform: "capitalize" }}>
                                {addedSellerId === seller.seller_id ? "Added" : "Add to Cart"}
                              </Typography>
                            </Button>
                          </List>
                        </Card>
                       ))} 
                    </Box>
                  </div>
                </List>
              </div>
            </Card>
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default ProductOffersPopUp;

